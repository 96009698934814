import React, { useState, useEffect } from 'react';
import { FaCheck } from 'react-icons/fa';
import Loader from '../loader';
import { useTranslation } from 'react-i18next';
import {
    capitalizeEveryWordFirstLetter,
    capitalizeFirstLetter,
} from '../../helpers/textFormatters';
import showNotification from '../../helpers/showNotification';
import { changeSubscription } from '../../api/subscription/SubscriptionAPI';
import { calculatePrice } from '../../helpers/priceCalculator';
import { PRICING } from '../../constants';

function BillingPlanConfigurationForm({
    planData,
    isYearly,
    selectedPlan,
    setConfiguredPlan,
    orgData,
    users,
}) {
    const { t } = useTranslation();
    const { name, value, featuresPlain, featuresListItems } = planData;

    const [retention, setRetention] = useState(
        +featuresPlain.find((feature) => feature.type === 'retention')
            ?.amount[0]
    );
    const [currentContributorsAmount, setCurrentContributorsAmount] =
        useState(1);
    const [configuredContributorsAmount, setConfiguredContributorsAmount] =
        useState(1);
    const [calculatedPrice, setCalculatedPrice] = useState(0);
    const [discount, setDiscount] = useState(0);
    // for handling payment
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const contributors = users.filter(
            (user) => user.role === 'write' || user.role === 'admin'
        );
        setCurrentContributorsAmount(contributors?.length ?? 1);
        setConfiguredContributorsAmount(contributors?.length ?? 1);
    }, [users]);

    useEffect(() => {
        const updatedPrice = calculatePrice(
            isYearly,
            value,
            configuredContributorsAmount,
            retention
        );
        const updatedDiscount =
            calculatePrice(
                false,
                value,
                configuredContributorsAmount,
                retention
            ) *
                12 -
            calculatePrice(
                true,
                value,
                configuredContributorsAmount,
                retention
            );

        setCalculatedPrice(updatedPrice);
        setDiscount(updatedDiscount);
    }, [isYearly, users, retention, configuredContributorsAmount, value]);

    function handleContributorsChange(value) {
        if (validateContributorsAmount(currentContributorsAmount, value)) {
            setConfiguredContributorsAmount(value);
        }
    }

    function handleInputsToggle(inputType, toggleType) {
        switch (inputType) {
            case 'retention':
                handleRetentionToggle(toggleType);
                return;
            case 'contributors':
                handleContributorsToggle(toggleType);
                return;
            case 'default':
                return;
        }
    }

    function handleContributorsToggle(type) {
        if (type === '-') {
            if (
                validateContributorsAmount(
                    currentContributorsAmount,
                    configuredContributorsAmount - 1,
                    'admins'
                )
            ) {
                setConfiguredContributorsAmount((prev) => +prev - 1);
            }
        } else {
            setConfiguredContributorsAmount((prev) => +prev + 1);
        }
    }

    function validateContributorsAmount(baseValue, newValue) {
        if (newValue < baseValue) {
            showNotification(
                `You can't set a value less then your number of contibutors`,
                'warning'
            );
            return false;
        } else {
            return true;
        }
    }

    function handleRetentionToggle(type) {
        if (type === '-') {
            if (
                retention - 1 >=
                +featuresPlain.find((feature) => feature.type === 'retention')
                    ?.amount[0]
            ) {
                setRetention((prev) => +prev - 1);
            }
        } else {
            setRetention((prev) => +prev + 1);
        }
    }

    async function handleCheckout() {
        // showNotification(
        //     'Upgrading is not available at the moment. Please contact support to upgrade',
        //     'warning'
        // );
        // const isDowngrade = checkIfDowngrade(value);
        // if (isDowngrade) {
        //     showNotification('Downgrade is not available', 'warning');
        //     return;
        // }
        setIsLoading(true);

        try {
            const res = await changeSubscription({
                monthly: !isYearly,
                recurring: true,
                redirectUrl: `${process.env.REACT_APP_BASE_URL}/settings/organisation`,
                license: {
                    tier: value.toUpperCase(),
                    contributors: configuredContributorsAmount,
                    contributors_price_per_unit: isYearly
                        ? PRICING.contributorPrice[value].annual
                        : PRICING.contributorPrice[value].monthly,
                    retention_period: retention,
                    retention_period_price_per_unit: isYearly
                        ? PRICING.retentionPrice.annual
                        : PRICING.retentionPrice.monthly,
                    total_price: calculatedPrice,
                },
            });
            if (res.status === 200) {
                window.location.replace(res.data);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showNotification(
                error.response?.data || error.message || error.data.message
            );
        }
    }

    function checkIfDowngrade(value) {
        const prevPlan = billingPlansData.find(
            (item) => item.value === selectedPlan
        );
        const newPlan = billingPlansData.find((item) => item.value === value);

        if (prevPlan.rating > newPlan.rating) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <>
            {' '}
            <div className="py-3 px-6 border-b border-b-1 border-custom-gray-400  w-full bg-white rounded-t-lg">
                <div className="flex gap-x-4 items-center">
                    <h3
                        className={`mb-0 font-display font-extrabold text-28 text-gray-700`}
                    >
                        <span className={`font-display`}>
                            Configure the plan:
                        </span>{' '}
                        {name}
                    </h3>

                    <button
                        type="button"
                        className="btn-white-hover-blue"
                        onClick={() => setConfiguredPlan(null)}
                    >
                        Back to plans
                    </button>
                </div>
            </div>
            <div className="flex-1 py-3 px-6 bg-bg-gray-400 rounded-b-lg flex flex-col justify-between">
                <div className="flex-1">
                    <p className="uppercase font-semibold text-gray-700 mb-2">
                        {t('settings_page.org_tab.included').toUpperCase()}:
                    </p>

                    <ul>
                        {/* li with admins amount */}
                        <li className="mb-2 text-left flex gap-x-2 no-arrows">
                            <p className="flex-none md:w-80">Contributors:</p>
                            <button
                                className={`w-6 h-6 p-1 rouned border border-solid border-gray-300 flex items-center justify-center ${
                                    configuredContributorsAmount ===
                                    currentContributorsAmount
                                        ? 'greyscale opacity-40'
                                        : ''
                                }`}
                                type="button"
                                onClick={() =>
                                    handleInputsToggle('contributors', '-')
                                }
                            >
                                -
                            </button>
                            <input
                                className=" w-12 h-6 p-1 rouned border border-solid border-gray-300  text-center "
                                type="number"
                                value={configuredContributorsAmount}
                                onChange={(e) =>
                                    handleContributorsChange(e.target.value)
                                }
                            />
                            <button
                                className="w-6 h-6 p-1 rouned border border-solid border-gray-300 flex items-center justify-center"
                                type="button"
                                onClick={() =>
                                    handleInputsToggle('contributors', '+')
                                }
                            >
                                +
                            </button>
                            <span className="">
                                users with admin or write permissions
                            </span>{' '}
                        </li>
                        {/* li with retention */}
                        <li className="mb-2 text-left flex gap-x-2 no-arrows">
                            <span className="flex-none md:w-80">
                                {capitalizeFirstLetter(
                                    t('settings_page.org_tab.retention')
                                )}
                                {': '}
                            </span>
                            <button
                                className={`w-6 h-6 p-1 rouned border border-solid border-gray-300 flex items-center justify-center ${
                                    retention ===
                                    +featuresPlain.find(
                                        (feature) =>
                                            feature.type === 'retention'
                                    )?.amount[0]
                                        ? 'greyscale opacity-40'
                                        : ''
                                }`}
                                type="button"
                                onClick={() =>
                                    handleInputsToggle('retention', '-')
                                }
                            >
                                -
                            </button>
                            <input
                                className=" w-12 h-6 p-1 rouned border border-solid border-gray-300  text-center "
                                type="number"
                                value={retention}
                                onChange={(e) =>
                                    +e.target.value > 0 &&
                                    setRetention(+e.target.value)
                                }
                            />
                            <button
                                className="w-6 h-6 p-1 rouned border border-solid border-gray-300 flex items-center justify-center"
                                type="button"
                                onClick={() =>
                                    handleInputsToggle('retention', '+')
                                }
                            >
                                +
                            </button>
                            <span className="">
                                {t('settings_page.org_tab.months')}
                            </span>{' '}
                        </li>

                        {/* li with department size */}
                        <li className="mb-2 text-left flex gap-x-2 no-arrows">
                            <p className="flex-none md:w-80">User access:</p>
                            <p>Unlimited</p>
                        </li>

                        {featuresListItems.map((feature, index) => (
                            <li className="mt-4" key={index}>
                                <div className="flex justify-between gap-x-2 items-center">
                                    <div className="flex gap-x-2 items-center">
                                        <div className="w-5">
                                            <FaCheck
                                                fill={'#81BC42'}
                                                size={18}
                                            />
                                        </div>

                                        <p>
                                            {feature.translationKey
                                                ? capitalizeEveryWordFirstLetter(
                                                      t(feature.translationKey)
                                                  )
                                                : feature.name}
                                        </p>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="flex flex-col items-end w-fit ml-auto">
                    <p className="font-display text-sm">
                        Monthly cost:{' '}
                        <span>
                            {isYearly ? calculatedPrice / 12 : calculatedPrice}{' '}
                            EUR
                        </span>
                    </p>
                    <p className="font-display text-sm">
                        {isYearly ? '(12 months access)' : '(monthly)'}
                    </p>
                    {isYearly ? (
                        <p className="font-display text-sm font-semibold text-success-green-300">
                            You're saving {discount} EUR
                        </p>
                    ) : (
                        <p className="font-display text-sm font-semibold text-red-text">
                            You can save {discount} EUR a year if pay annually
                        </p>
                    )}
                    <div className="flex gap-x-2 mb-0 font-display text-28 text-gray-700">
                        <p className="font-display font-extrabold leading-none">
                            Total:{' '}
                        </p>
                        <div className="font-display leading-none">
                            {calculatedPrice} EUR /{' '}
                            {isYearly ? 'year' : 'month'}
                        </div>
                    </div>

                    <button
                        className="mt-2 py-1.5 px-3 border border-solid border-theme-tertiary rounded font-display  font-semibold bg-theme-tertiary text-white cursor-pointer w-24 "
                        onClick={() => handleCheckout()}
                    >
                        {isLoading ? (
                            <Loader size={24} color="#fff" />
                        ) : (
                            'Checkout'
                        )}
                    </button>
                </div>
            </div>
        </>
    );
}

export default BillingPlanConfigurationForm;
