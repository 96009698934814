import React from 'react';
import CardHeader from './header';

function Card({ title, children, width, renderActionButtons }) {
    return (
        <div className={`${width}  my-3 bg-white rounded-lg shadow-lg`}>
            <CardHeader
                title={title}
                renderActionButtons={renderActionButtons}
            />
            <div className="px-4 py-2">{children}</div>
        </div>
    );
}

Card.defaultProps = {
    width: 'w-full lg:w-1/2',
    renderActionButtons: undefined,
};

export default Card;
