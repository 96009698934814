import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useTour } from '@reactour/tour';
import { useTranslation } from 'react-i18next';
import useParamsQuery from '../hooks/useParamsQuery';
import { OrganisationsContext } from '../context/OrganisationsContext';
import KudosLeaderboardWrapper from '../components/kudos-leaderboard';
import KudosSankeyChart from '../components/kudos-sankey-chart';
import Loader from '../components/loader';
import ErrorIndicator from '../components/error-indicator';
import ErrorBoundary from '../components/error-boundary';
import { withUserAndOragnisations } from '../helpers/withUser';
import { useDefaultStateWithReducer } from '../helpers/useDefaultStateWithReducer';
import { getTeams } from '../api/teams/TeamsAPI';
import { getOrgUsers } from '../api/settings/UserAPI';
import PanelTitle from '../components/panel-title';
import { getSlackWorkspaces } from '../api/settings/SlackAPI';
import { useFetchDataAndSetState } from '../helpers/useFetchDataAndSetState';
import SettingsMessage from '../components/settings-message';
import { NAVIGATION_PATHS } from '../constants/navigationPaths';

const KudosPage = () => {
    const { t } = useTranslation();

    const [teamsState, teamsDispatch] = useDefaultStateWithReducer();

    const [usersState, usersDispatch] = useDefaultStateWithReducer();

    const { organisationsState } = useContext(OrganisationsContext);

    const selectedOrganisationHash = organisationsState.data
        ? organisationsState.data.find((org) => org.active).org_hash
        : null;

    const workspacesRequest = useCallback(() => getSlackWorkspaces(), []);

    const [workspacesState] = useFetchDataAndSetState(workspacesRequest, [
        selectedOrganisationHash,
    ]);

    const [localTeamsState, setLocalTeamsState] = useState(() => {
        if (teamsState.data) {
            return {
                ...teamsState,
                data: [
                    ...(teamsState.data || null),
                    {
                        team_hash: 'all',
                        team_name: t('kudos_page.overall'),
                        users_it_team: [],
                    },
                ],
            };
        } else {
            return {
                ...teamsState,
                data: [
                    {
                        team_hash: 'all',
                        team_name: t('kudos_page.overall'),
                        users_in_team: [],
                    },
                ],
            };
        }
    });

    const [activeTeam, setActiveTeam] = useState(null);

    useEffect(() => {
        let canceled = false;

        teamsDispatch({ type: 'SET_LOADING' });
        usersDispatch({ type: 'SET_LOADING' });

        if (selectedOrganisationHash) {
            getTeams()
                .then((response) => {
                    if (!canceled) {
                        if (response.status === 204) {
                            setActiveTeam('all');
                            teamsDispatch({ type: 'SET_DATA', payload: [] });

                            setLocalTeamsState((prevState) => {
                                return {
                                    ...prevState,
                                    data: [
                                        {
                                            team_hash: 'all',
                                            team_name: t('kudos_page.overall'),
                                            users_in_team: [],
                                        },
                                        ...response.data,
                                    ],
                                };
                            });

                            getOrgUsers()
                                .then((response) => {
                                    if (response.status === 204) {
                                        if (!canceled) {
                                            usersDispatch({
                                                type: 'SET_DATA',
                                                payload: [],
                                            });
                                        }
                                    } else {
                                        if (!canceled) {
                                            usersDispatch({
                                                type: 'SET_DATA',
                                                payload: response.data,
                                            });
                                        }
                                    }
                                })
                                .catch((error) => {
                                    if (!canceled)
                                        usersDispatch({
                                            type: 'SET_ERROR',
                                            payload: error,
                                        });
                                });
                        } else {
                            setActiveTeam('all');
                            teamsDispatch({
                                type: 'SET_DATA',
                                payload: response.data,
                            });

                            setLocalTeamsState((prevState) => {
                                return {
                                    ...prevState,
                                    data: [
                                        {
                                            team_hash: 'all',
                                            team_name: t('kudos_page.overall'),
                                            users_in_team: [],
                                        },
                                        ...response.data,
                                    ],
                                };
                            });

                            getOrgUsers()
                                .then((response) => {
                                    if (response.status === 204) {
                                        if (!canceled) {
                                            usersDispatch({
                                                type: 'SET_DATA',
                                                payload: [],
                                            });
                                        }
                                    } else {
                                        if (!canceled) {
                                            usersDispatch({
                                                type: 'SET_DATA',
                                                payload: response.data,
                                            });
                                        }
                                    }
                                })
                                .catch((error) => {
                                    if (!canceled)
                                        usersDispatch({
                                            type: 'SET_ERROR',
                                            payload: error,
                                        });
                                });
                        }
                    }
                })
                .catch((error) => {
                    if (!canceled)
                        teamsDispatch({ type: 'SET_ERROR', payload: error });
                });
        }

        return () => (canceled = true);
    }, [selectedOrganisationHash, teamsDispatch, usersDispatch]);

    const renderContent = () => {
        if (teamsState.loading)
            return (
                <div className="flex justify-center items-center">
                    <Loader color={'#C2C7D7'} size={35} speedMultiplier={0.8} />
                </div>
            );
        if (teamsState.error)
            return (
                <div className="flex justify-center items-center">
                    <ErrorIndicator error={teamsState.error} />
                </div>
            );
        return (
            <KudosPageContent
                activeTeam={activeTeam}
                setActiveTeam={setActiveTeam}
                localTeamsState={localTeamsState}
                teamsState={teamsState}
                usersState={usersState}
                isSlackConnected={Boolean(
                    workspacesState?.data && workspacesState?.data?.length
                )}
            />
        );
    };

    return <div className="container mx-auto">{renderContent()}</div>;
};

const KudosPageContent = ({
    activeTeam,
    setActiveTeam,
    localTeamsState,
    teamsState,
    usersState,
    isSlackConnected,
}) => {
    const { setIsOpen } = useTour();
    const { t } = useTranslation();
    const query = useParamsQuery();

    useEffect(() => {
        query.get('tour') === 'true' && setIsOpen(true);
        return () => setIsOpen(false);
    }, []);

    if (localTeamsState.data.length === 0) {
        return (
            <section className="">
                <p className="text-theme-button font-medium text-center ">
                    Sorry, no teams have been created. Please create commands on
                    the settings page.
                </p>
            </section>
        );
    }

    return (
        <>
            {!isSlackConnected ? (
                <SettingsMessage
                    messageText={t('settingsMessages.no_slack')}
                    link={NAVIGATION_PATHS.settingsKudos}
                    linkText={t('settingsMessages.go_to_kudos_settings')}
                />
            ) : null}
            <section className="second-step" data-tour="r-step-page-2">
                <ErrorBoundary>
                    <KudosLeaderboardWrapper
                        selectedTeam={activeTeam}
                        setActiveTeam={setActiveTeam}
                        localTeamsState={localTeamsState}
                        teamsState={teamsState}
                        usersState={usersState}
                    />
                </ErrorBoundary>
            </section>
            <section
                className="w-full bg-white py-4 px-4 rounded-lg shadow-md mb-8"
                data-tour="r-step-page-3"
            >
                <PanelTitle
                    title={t('kudos_page.leaderboard.sankey.title')}
                    underlineColorType={'tertiary'}
                />
                <ErrorBoundary>
                    <KudosSankeyChart
                        selectedTeam={activeTeam}
                        usersState={usersState}
                    />
                </ErrorBoundary>
            </section>
        </>
    );
};

export default withUserAndOragnisations(KudosPage);
