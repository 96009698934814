import {
    customInstances,
    formDataInstances,
    instances,
} from '../default-setup';

//Users
export const getUsers = () => customInstances.get(`admin/users/`);
export const getUser = (userHash) =>
    customInstances.get(`admin/users/${userHash}`);

export const addUserFromAdminPanel = (data) =>
    customInstances.post('admin/users/', data);

export const changeUser = (userHash, data) =>
    customInstances.put(`admin/users/${userHash}/`, data);

export const deleteUser = (userHash) =>
    customInstances.delete(`admin/users/${userHash}/`);

export const getOrgUsers = () => instances.get(`settings/users/`);

export const deleteOrgUser = (data) =>
    instances.delete(`settings/users/`, { data: data });

export const inviteUser = (data) =>
    instances.post('settings/users/invite/', data);

export const setOrgUserHoursAndPermission = (data, userHash) =>
    instances.put(`settings/users/${userHash}/`, data);

// TODO: add actual route
export const setUserJira = (data, userHash) =>
    instances.put(`jira/user_hash/${userHash}/?email=${data}`);

export const updateUserData = (data) => instances.put(`settings/me/`, data);

export const resetUserPassword = () =>
    instances.get(`settings/me/reset_password/`);

export const setNewUserPhoto = (data) =>
    formDataInstances.put(
        `${window.localStorage.getItem('orgHash')}/settings/me/`,
        data
    );
