import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import FormField from './sre-form-field';
import { getUniqueListBy } from '../../../helpers/getUniqueListBy';
import {
    createFeature,
    updateFeature,
    deleteFeature,
} from '../../../api/settings-slo/slo';
import InputText from '../../../components/form/parts/InputText';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { replaceUnderscoresToSpaces } from '../../../helpers/replaceSpacesToUnderscores';
import googleThresholdBucketList from '../../../helpers/googleThresholdBucketsList';
import { NAVIGATION_PATHS } from '../../../constants/navigationPaths';
import { capitalizeFirstLetter } from '../../../helpers/textFormatters';
import ModalWrapper from '../../../components/modal-wrapper';
import ModalDeletingContent from './sre-modal-delete-feature-content';
import {
    getFeaturesData,
    getFeaturesDataAWS,
    handleBackendChange,
    handlerChangeMethod,
    methodOptions,
    validationSchema,
} from '../../../helpers/addSloHelpers';
import SreFormBackendSelector from './sre-form-backend-selector';
import SreFormFilters from './sre-form-filters';
import SreFormFilterTry from './sre-form-filters-try';
import { Flags } from 'react-feature-flags';

const mockProjectsIds = [
    ['id1', 'id2', 'id3'],
    ['id11', 'id22', 'id33'],
];

const AddingFeatureForm = ({
    featuresState,
    featuresDispatch,
    serviceName,
    feature,
    backendsList,
    onGoBack,
}) => {
    const successNotify = (message) => toast.success(message);
    const errorNotify = (message) => toast.error(message);

    const { t } = useTranslation();

    const [deleteFeatureModalState, setDeleteFeatureModalState] =
        useState(false);

    const validBackendsList = backendsList?.filter(
        (backend) => backend.enabled
    );
    const invalidBackendsList = backendsList?.filter(
        (backend) => !backend.enabled
    );
    const orderedBackendsList = [...validBackendsList, ...invalidBackendsList];

    const [backendOptions, setBackendOptions] = useState(
        orderedBackendsList?.map((backend) => {
            return {
                value: backend.name,
                label: `${replaceUnderscoresToSpaces(backend.name)} ${
                    !backend.enabled ? '(invalid)' : ''
                }`,
            };
        })
    );

    const thresholdBucketOptions = googleThresholdBucketList.map((bucket) => {
        return {
            value: bucket.number,
            label: `${bucket.number} (${bucket.lowerBound} to ${bucket.upperBound} ms)`,
        };
    });

    const initValue = {
        feature_name: '',
        slo_name: '',
        env: '',
        goal: '',
        backend: '',
        project_id: '',
        method: '',
        ratio_options: [],
        distribution_options: [],
        ratio_options_aws: [],
        distribution_options_aws: [],
        threshold_bucket: '',
        threshold_value: '',
        good_below_threshold: true,
    };

    const thresholdOptions = [
        { value: 'true', label: t('sre.below') },
        { value: 'false', label: t('sre.above') },
    ];

    let modifiedFeatureOptions = null;

    const isInitialFeatureAWS =
        backendsList.find((backend) => backend.name === feature?.backend)
            ?.type === 'cloud_watch';

    if (feature && feature.ratio_options && !isInitialFeatureAWS) {
        modifiedFeatureOptions = {
            ...feature,
            ratio_options_aws: [],
            ratio_options: [feature.ratio_options],
        };
    }

    if (feature && feature.ratio_options && isInitialFeatureAWS) {
        modifiedFeatureOptions = {
            ...feature,
            ratio_options: [],
            ratio_options_aws: [feature.ratio_options],
        };
    }

    if (feature && feature.distribution_options && !isInitialFeatureAWS) {
        modifiedFeatureOptions = {
            ...feature,
            distribution_options_aws: [],
            distribution_options: [feature.distribution_options],
        };
    }

    if (feature && feature.distribution_options && isInitialFeatureAWS) {
        modifiedFeatureOptions = {
            ...feature,
            distribution_options: [],
            distribution_options_aws: [feature.distribution_options],
        };
    }

    const sendDeleteFeatureRequest = useCallback(() => {
        deleteFeature(serviceName, feature?.feature_name)
            .then((response) => {
                if (response.status === 200) {
                    const features = featuresState.data?.features || [];

                    const updatedFeatures = features?.filter(
                        (item) => item.feature_name !== feature?.feature_name
                    );

                    featuresDispatch({
                        type: 'SET_DATA',
                        payload: {
                            ...featuresState.data,
                            features: [...updatedFeatures],
                        },
                    });

                    successNotify(
                        `Feature "${feature?.feature_name}" has been deleted`
                    );
                }
            })
            .catch((error) => {
                featuresDispatch({ type: 'SET_ERROR', payload: error });
                errorNotify(
                    error.response?.data ||
                        `Feature "${feature?.feature_name}" has not been deleted`
                );
            });
    }, [serviceName, feature?.feature_name]);

    const handleDeleteFeature = () => {
        setDeleteFeatureModalState(true);
    };

    const createNewFeature = (values, resetForm) => {
        const isAWS =
            backendsList.find((backend) => backend.name === values.backend)
                ?.type === 'cloud_watch';

        let formData = {};
        if (isAWS) {
            formData = getFeaturesDataAWS(values);
        } else {
            formData = getFeaturesData(values);
        }

        if (formData) {
            const { data, options } = formData;

            let isFeatureNameExist = false;

            isFeatureNameExist =
                featuresState.data.features &&
                featuresState.data.features.find(
                    (feature) =>
                        feature.feature_name.toLowerCase() ===
                        data.feature_name.toLowerCase()
                );

            if (isFeatureNameExist) {
                errorNotify(`${data.feature_name} already exists`);
                return;
            }

            createFeature(serviceName, data)
                .then((response) => {
                    if (response.status === 200) {
                        const features = featuresState.data?.features || [];

                        featuresDispatch({
                            type: 'SET_DATA',
                            payload: {
                                ...featuresState.data,
                                features: [
                                    ...features,
                                    {
                                        feature_name: values.feature_name,
                                        slo_name: values.slo_name,
                                        env: values.env,
                                        goal: values.goal,
                                        backend: values.backend,
                                        project_id: values.project_id,
                                        method: values.method,
                                        ...options,
                                    },
                                ],
                            },
                        });

                        resetForm({});
                        successNotify(
                            `Feature "${values.feature_name}" has been added`
                        );
                    }
                })
                .catch((error) => {
                    featuresDispatch({ type: 'SET_ERROR', payload: error });
                    errorNotify(
                        error?.response?.data ||
                            `Feature "${values.feature_name}" has not been added`
                    );
                });
        }
    };

    const saveFeatureChanges = (values) => {
        const isAWS =
            backendsList.find((backend) => backend.name === values.backend)
                ?.type === 'cloud_watch';

        let formData = {};
        if (isAWS) {
            formData = getFeaturesDataAWS(values);
        } else {
            formData = getFeaturesData(values);
        }

        if (formData) {
            const { data, options } = formData;
            updateFeature(serviceName, values.feature_name, data)
                .then((response) => {
                    if (response.status === 200) {
                        const features = featuresState.data?.features || [];

                        const changedFeature = {
                            feature_name: values.feature_name,
                            slo_name: values.slo_name,
                            env: values.env,
                            goal: values.goal,
                            backend: values.backend,
                            project_id: values.project_id,
                            method: values.method,
                            ...options,
                        };

                        const updatedFeature = [...features, changedFeature];
                        const uniqFeatures = getUniqueListBy(
                            updatedFeature,
                            'feature_name'
                        );

                        featuresDispatch({
                            type: 'SET_DATA',
                            payload: {
                                ...featuresState.data,
                                features: [...uniqFeatures],
                            },
                        });

                        successNotify(
                            `Feature "${values.feature_name}" has been changed`
                        );
                    }
                })
                .catch((error) => {
                    featuresDispatch({ type: 'SET_ERROR', payload: error });
                    errorNotify(
                        error?.response?.data ||
                            `Feature "${values.feature_name}" has not been changed`
                    );
                });
        }
    };

    const onSubmit = (values, { resetForm, setSubmitting }) => {
        const isAWS =
            backendsList.find((backend) => backend.name === values.backend)
                ?.type === 'cloud_watch';

        setSubmitting(false);

        let filteredValues = values;
        // Ckeck what to send: threshold bucket or threshold value

        switch (values.method) {
            case 'distribution_cut':
                if (isAWS) {
                    delete filteredValues.destribution_options;
                    delete filteredValues.threshold_bucket;
                } else {
                    delete filteredValues.destribution_options_aws;
                    delete filteredValues.threshold_value;
                }
                delete filteredValues.ratio_options;
                delete filteredValues.ratio_options_aws;
                break;
            case 'good_bad_ratio':
                if (isAWS) {
                    delete filteredValues.ratio_options;
                } else {
                    delete filteredValues.ratio_options_aws;
                }
                delete filteredValues.destribution_options;
                delete filteredValues.destribution_options_aws;
                delete filteredValues.threshold_bucket;
                delete filteredValues.threshold_value;
                delete filteredValues.good_below_threshold;
                break;
            default:
                break;
        }

        const goodBelowThreshold =
            values.good_below_threshold === true ||
            values.good_below_threshold === 'true'
                ? true
                : false;

        const formattedValues = {
            ...filteredValues,
            good_below_threshold: goodBelowThreshold,
        };

        if (document.activeElement.dataset.btnName === 'create') {
            createNewFeature(formattedValues, resetForm);
            return;
        }

        saveFeatureChanges(formattedValues);
    };

    return (
        <>
            <Formik
                initialValues={modifiedFeatureOptions || initValue}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                t={t}
            >
                {({
                    handleSubmit,
                    errors,
                    touched,
                    values,
                    setValues,
                    isValid,
                    dirty,
                    resetForm,
                }) => {
                    const isAWS =
                        backendsList.find(
                            (backend) => backend.name === values.backend
                        )?.type === 'cloud_watch';

                    function handleReset() {
                        resetForm();
                    }

                    return (
                        <Form
                            className="flex flex-col h-full"
                            onSubmit={handleSubmit}
                        >
                            <fieldset className="mb-1 grid grid-cols-3 w-full gap-x-8 gap-y-2">
                                <div className="">
                                    <FormField
                                        name={`${capitalizeFirstLetter(
                                            t('si_page.feature')
                                        )} ${capitalizeFirstLetter(
                                            t('common.name')
                                        )}`}
                                        attributeName={'feature_name'}
                                        fieldType={'text'}
                                        placeholderText={`${capitalizeFirstLetter(
                                            t('button.enter')
                                        )} ${t('si_page.feature')} ${t(
                                            'common.name'
                                        )}`}
                                        error={errors.feature_name}
                                        touched={touched.feature_name}
                                        value={values.feature_name}
                                        disabled={Boolean(
                                            modifiedFeatureOptions?.feature_name
                                        )}
                                    />
                                </div>
                                <div className="">
                                    <FormField
                                        name={`${t(
                                            'sre.slo'
                                        ).toUpperCase()} ${capitalizeFirstLetter(
                                            t('common.name')
                                        )}`}
                                        attributeName={'slo_name'}
                                        fieldType={'text'}
                                        placeholderText={`${capitalizeFirstLetter(
                                            t('button.enter')
                                        )} ${t('sre.slo').toUpperCase()} ${t(
                                            'common.name'
                                        )}`}
                                        error={errors.slo_name}
                                        touched={touched.slo_name}
                                        value={values.slo_name}
                                    />
                                </div>
                                <div className="">
                                    <label
                                        className="text-gray-400 mb-1 text-m"
                                        htmlFor="goal"
                                    >
                                        Goal
                                    </label>
                                    <div className="w-2/5">
                                        <InputText
                                            id="goal"
                                            type="number"
                                            min="0"
                                            max="1"
                                            step="0.0001"
                                            name={t('sre.goal')}
                                            placeholder={'0.9999'}
                                            value={values.goal}
                                        />
                                    </div>
                                    {errors.goal && touched.goal ? (
                                        <div className="text-red-500 mt-1 text-xs">
                                            {errors.goal}
                                        </div>
                                    ) : (
                                        <div className="text-theme-tertiary mt-1 text-xs ">
                                            The Service Level Objective is{' '}
                                            {(values.goal * 1000) % 1 !== 0
                                                ? (values.goal * 100).toFixed(2)
                                                : values.goal * 100}
                                            % for the last 24 hours
                                        </div>
                                    )}
                                </div>

                                <div className="">
                                    <FormField
                                        name={capitalizeFirstLetter(
                                            t('sre.environment')
                                        )}
                                        attributeName={'env'}
                                        fieldType={'text'}
                                        placeholderText={`${capitalizeFirstLetter(
                                            t('button.enter')
                                        )} ${t('sre.environment')} ${t(
                                            'common.name'
                                        )}`}
                                        error={errors.env}
                                        touched={touched.env}
                                        value={values.env}
                                    />
                                </div>

                                {backendOptions && backendOptions.length > 0 ? (
                                    <SreFormBackendSelector
                                        t={t}
                                        backendOptions={backendOptions}
                                        values={values}
                                        setValues={setValues}
                                        invalidBackendsList={
                                            invalidBackendsList
                                        }
                                        handleBackendChange={
                                            handleBackendChange
                                        }
                                        errors={errors}
                                        touched={touched}
                                    />
                                ) : (
                                    <div className="">
                                        <p className="mb-2 text-gray-400 mb-1 text-m">
                                            {capitalizeFirstLetter(
                                                t('sre.backend')
                                            )}
                                        </p>
                                        <p className="text-red-text mb-1 text-m">
                                            {capitalizeFirstLetter(
                                                t('sre.no_backends_start')
                                            )}{' '}
                                            <Link
                                                to={
                                                    NAVIGATION_PATHS.settingsErrorBudgets
                                                }
                                                className="font-medium underline"
                                            >
                                                {t('sre.no_backends_link')}
                                            </Link>{' '}
                                            {t('sre.no_backends_end')}
                                        </p>
                                    </div>
                                )}

                                {values.backend && (
                                    <>
                                        {/* TODO: dont forget to update validationSchema */}
                                        <Flags
                                            authorizedFlags={[
                                                'sloProjectIdSelectFeature',
                                            ]}
                                        >
                                            <div className="">
                                                <label
                                                    className="text-gray-400 mb-1 text-m"
                                                    htmlFor="project_id"
                                                >
                                                    {'Project ID'}
                                                </label>
                                                <Field name="project_id">
                                                    {({ field }) => {
                                                        return (
                                                            <select
                                                                {...field}
                                                                className="w-full p-2 placeholder-gray-300 text-gray-700 relative bg-white rounded text-md border border-gray-300"
                                                                value={
                                                                    values?.project_id
                                                                }
                                                                required
                                                            >
                                                                <option
                                                                    disabled
                                                                    value=""
                                                                    className="text-gray-300"
                                                                >
                                                                    {capitalizeFirstLetter(
                                                                        t(
                                                                            'button.select'
                                                                        )
                                                                    )}{' '}
                                                                    {
                                                                        'project ID'
                                                                    }
                                                                </option>
                                                                {/* TODO: remove mock */}
                                                                {backendsList.find(
                                                                    (backend) =>
                                                                        backend.name ===
                                                                        values.backend
                                                                )?.projects
                                                                    ? backendsList
                                                                          .find(
                                                                              (
                                                                                  backend
                                                                              ) =>
                                                                                  backend.name ===
                                                                                  values.backend
                                                                          )
                                                                          ?.projects.map(
                                                                              (
                                                                                  project
                                                                              ) => (
                                                                                  <option
                                                                                      key={
                                                                                          project
                                                                                      }
                                                                                      value={
                                                                                          project
                                                                                      }
                                                                                  >
                                                                                      {
                                                                                          project
                                                                                      }
                                                                                  </option>
                                                                              )
                                                                          )
                                                                    : mockProjectsIds[
                                                                          values?.backend ===
                                                                          'google_monitoring_backend'
                                                                              ? 0
                                                                              : 1
                                                                      ]?.map(
                                                                          (
                                                                              project
                                                                          ) => (
                                                                              <option
                                                                                  key={
                                                                                      project
                                                                                  }
                                                                                  value={
                                                                                      project
                                                                                  }
                                                                              >
                                                                                  {
                                                                                      project
                                                                                  }
                                                                              </option>
                                                                          )
                                                                      )}
                                                            </select>
                                                        );
                                                    }}
                                                </Field>
                                                {errors.project_id &&
                                                touched.project_id ? (
                                                    <div className="text-red-500 mt-1 text-xs">
                                                        {errors.project_id}
                                                    </div>
                                                ) : (
                                                    <div className="text-red-500 mt-1 text-xs invisible">
                                                        hidden
                                                    </div>
                                                )}
                                            </div>
                                        </Flags>
                                        <div className="">
                                            <label
                                                className="text-gray-400 mb-1 text-m"
                                                htmlFor="method"
                                            >
                                                {capitalizeFirstLetter(
                                                    t('sre.method')
                                                )}
                                            </label>
                                            <Field name="method">
                                                {({ field }) => {
                                                    return (
                                                        <select
                                                            {...field}
                                                            className="w-full p-2 placeholder-gray-300 text-gray-700 relative bg-white rounded text-md border border-gray-300"
                                                            value={
                                                                values?.method
                                                            }
                                                            onChange={(e) =>
                                                                handlerChangeMethod(
                                                                    e,
                                                                    field,
                                                                    values,
                                                                    setValues,
                                                                    backendsList
                                                                )
                                                            }
                                                            required
                                                        >
                                                            <option
                                                                disabled
                                                                value=""
                                                                className="text-gray-300"
                                                            >
                                                                {capitalizeFirstLetter(
                                                                    t(
                                                                        'button.select'
                                                                    )
                                                                )}{' '}
                                                                {t(
                                                                    'sre.method'
                                                                )}
                                                            </option>
                                                            {methodOptions.map(
                                                                (method, i) => (
                                                                    <option
                                                                        key={i}
                                                                        value={
                                                                            method.value
                                                                        }
                                                                    >
                                                                        {
                                                                            method.label
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    );
                                                }}
                                            </Field>
                                            {errors.method && touched.method ? (
                                                <div className="text-red-500 mt-1 text-xs">
                                                    {errors.method}
                                                </div>
                                            ) : (
                                                <div className="text-red-500 mt-1 text-xs invisible">
                                                    hidden
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}
                            </fieldset>
                            <SreFormFilters
                                isAws={isAWS}
                                values={values}
                                errors={errors}
                                touched={touched}
                                thresholdOptions={thresholdOptions}
                                backendsList={backendsList}
                                thresholdBucketOptions={thresholdBucketOptions}
                            />

                            <SreFormFilterTry
                                serviceName={serviceName}
                                sloSettings={values}
                                isAws={isAWS}
                            />

                            <div
                                className={`flex ${
                                    feature ? 'justify-between' : 'justify-end'
                                } mt-auto font-display`}
                            >
                                {feature ? (
                                    <>
                                        <button
                                            type="button"
                                            className={`underline text-red-text font-medium text-xs leading-5 disabled:cursor-not-allowed disabled:text-opacity-50`}
                                            style={{ minWidth: '110px' }}
                                            onClick={handleDeleteFeature}
                                        >
                                            {t('sre.remove_feature')}
                                        </button>
                                        <div className="flex gap-2">
                                            {onGoBack ? (
                                                <button
                                                    data-btn-name="back"
                                                    className={`inline-flex justify-center px-6 py-2 text-lg  text-theme-tertiary hover:underline  rounded-md disabled:cursor-not-allowed`}
                                                    style={{
                                                        minWidth: '110px',
                                                    }}
                                                    onClick={onGoBack}
                                                >
                                                    Go back
                                                </button>
                                            ) : null}
                                            <button
                                                data-btn-name="update"
                                                type="submit"
                                                className={`inline-flex justify-center px-6 py-2 text-lg font-normal text-white  bg-theme-tertiary-dark border border-transparent rounded-md hover:bg-theme-button disabled:cursor-not-allowed disabled:opacity-50`}
                                                style={{ minWidth: '110px' }}
                                                disabled={!isValid || !dirty}
                                            >
                                                {t('button.update')}
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {/* <button
                                            type="button"
                                            className={`underline text-red-text font-medium text-xs leading-5 disabled:cursor-not-allowed disabled:text-opacity-50`}
                                            style={{ minWidth: '110px' }}
                                            onClick={() => handleReset()}
                                        >
                                            {t('button.cancel')}
                                        </button> */}
                                        <div className="flex gap-2">
                                            {onGoBack ? (
                                                <button
                                                    data-btn-name="back"
                                                    className={`inline-flex justify-center px-6 py-2 text-lg  text-theme-tertiary hover:underline  rounded-md disabled:cursor-not-allowed`}
                                                    style={{
                                                        minWidth: '110px',
                                                    }}
                                                    onClick={onGoBack}
                                                >
                                                    Go back
                                                </button>
                                            ) : null}
                                            <button
                                                data-btn-name="create"
                                                type="submit"
                                                className={`inline-flex justify-center px-6 py-2 text-lg font-normal text-white bg-theme-tertiary-dark border border-transparent rounded-md hover:bg-theme-button disabled:cursor-not-allowed disabled:opacity-50`}
                                                style={{ minWidth: '110px' }}
                                            >
                                                {t('button.create')}
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </Form>
                    );
                }}
            </Formik>
            <ModalWrapper
                modalState={deleteFeatureModalState}
                setModalState={setDeleteFeatureModalState}
                title={t('sre.modal_delete')}
            >
                <ModalDeletingContent
                    featureName={feature?.feature_name}
                    sendRequest={sendDeleteFeatureRequest}
                    handleChangeModalState={() =>
                        setDeleteFeatureModalState(!deleteFeatureModalState)
                    }
                />
            </ModalWrapper>
        </>
    );
};

export default AddingFeatureForm;
