import axios from 'axios';
import moment from 'moment';
import showNotification from '../../helpers/showNotification';
import { loginWithRefreshToken } from '../auth/AuthAPI';

const { REACT_APP_API_URL: baseURL } = process.env;

const getAxiosConfig = () => ({
    baseURL,
});

const interceptMainConfig = (config) => {
    const { headers, url, data } = config;

    if (data) config.data = JSON.stringify(data);

    headers['content-type'] = 'application/json;charset=UTF-8';
    headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    config.url = `${baseURL}/${window.localStorage.getItem('orgHash')}/${url}`;
    config.headers = { ...config.headers };
    return config;
};

const interceptCustomConfig = (config) => {
    const { headers, url, data } = config;

    if (data) config.data = JSON.stringify(data);

    headers['content-type'] = 'application/json;charset=UTF-8';
    headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    config.url = `${baseURL}/${url}`;
    config.headers = { ...config.headers };

    return config;
};

const interceptFormDataConfig = (config) => {
    const { headers, url } = config;

    headers['content-type'] = 'multipart/form-data';
    headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    config.url = `${baseURL}/${url}`;
    config.headers = { ...config.headers };

    return config;
};

export const customInstances = {
    _main: null,

    get main() {
        if (!this._main) {
            this._main = axios.create(getAxiosConfig());
            this._main.interceptors.request.use(
                interceptCustomConfig,
                Promise.reject
            );
            this._main.interceptors.response.use(
                (response) => {
                    if (response.status === 202) {
                        showNotification(
                            'You cannot modify Pied Piper`s data',
                            'warning'
                        );
                    }
                    return response;
                },
                (error) => {
                    if (
                        error?.status === 401 ||
                        error?.response?.status === 401
                    ) {
                        handleInvalidToken();
                    }
                    return Promise.reject(error);
                }
            );
        }
        return this._main;
    },

    get(url, options) {
        return this.main.get(url, options).then(this.formatResponse);
    },

    post(url, data, options) {
        return this.main.post(url, data, options).then(this.formatResponse);
    },

    put(url, data, options) {
        return this.main.put(url, data, options).then(this.formatResponse);
    },

    delete(url, options) {
        return this.main.delete(url, options).then(this.formatResponse);
    },

    formatResponse(resp) {
        return resp;
    },
};

export const formDataInstances = {
    _main: null,

    get main() {
        if (!this._main) {
            this._main = axios.create(getAxiosConfig());
            this._main.interceptors.request.use(
                interceptFormDataConfig,
                Promise.reject
            );
            this._main.interceptors.response.use(
                (response) => {
                    return response;
                },
                (error) => {
                    return Promise.reject(error);
                }
            );
        }
        return this._main;
    },

    get(url, options) {
        return this.main.get(url, options).then(this.formatResponse);
    },

    post(url, data, options) {
        return this.main.post(url, data, options).then(this.formatResponse);
    },

    put(url, data, options) {
        return this.main.put(url, data, options).then(this.formatResponse);
    },

    delete(url, options) {
        return this.main.delete(url, options).then(this.formatResponse);
    },

    formatResponse(resp) {
        return resp;
    },
};

export const instances = {
    _main: null,

    get main() {
        if (!this._main) {
            this._main = axios.create(getAxiosConfig());
            this._main.interceptors.request.use(
                interceptMainConfig,
                Promise.reject
            );
            this._main.interceptors.response.use(
                (response) => {
                    if (response.status === 202) {
                        showNotification(
                            'You cannot modify Pied Piper`s data',
                            'warning'
                        );
                    }

                    return response;
                },
                (error) => {
                    if (
                        error?.status === 401 ||
                        error?.response?.status === 401
                    ) {
                        handleInvalidToken();
                    }
                    return Promise.reject(error);
                }
            );
        }
        return this._main;
    },

    get(url, options) {
        return this.main.get(url, options).then(this.formatResponse);
    },

    post(url, data, options) {
        return this.main.post(url, data, options).then(this.formatResponse);
    },

    put(url, data, options) {
        return this.main.put(url, data, options).then(this.formatResponse);
    },

    delete(url, params) {
        return this.main.delete(url, params).then(this.formatResponse);
    },

    formatResponse(resp) {
        return resp;
    },

    // formatResponse(resp) {
    //     return resp && resp.data;
    // },
};

function handleInvalidToken() {
    const provider = localStorage.getItem('login_option');
    const refreshToken = localStorage.getItem('refreshToken');
    location.pathname !== '/login' &&
        localStorage.setItem('path', location.pathname);
    //TODO: check refresh token
    if (refreshToken) {
        loginWithRefreshToken(provider, refreshToken)
            .then((response) => {
                localStorage.setItem('token', response.data.id_token);
                localStorage.setItem(
                    'refreshToken',
                    response.data.refresh_token
                );
                location.reload();
            })

            .catch((error) => {
                localStorage.removeItem('refreshToken');
                location.reload();
            });
    } else {
        localStorage.removeItem('token');
        location.reload();
    }
}
