import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import Loader from '../loader';
import renderLoginOptions from '../../helpers/auth/renderLoginOptions';
import { signupWithPassword } from '../../api/auth/AuthAPI';

function AuthInviteForm({ errorMessageFromParent }) {
    const { t } = useTranslation();
    const [loader, setLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    if (window.localStorage.getItem('token')) {
        return <Navigate to="/" />;
    }

    function handlePasswordSignup(values, setSubmittingFinished) {
        setLoader(true);
        const params = {
            ...values,
            endpoint: 'invite',
            role: localStorage.getItem('inviteRole'),
            org: localStorage.getItem('inviteOrg'),
        };
        signupWithPassword(params)
            .then((response) => {
                localStorage.setItem('token', response.data.idToken);
                localStorage.setItem(
                    'refreshToken',
                    response.data.refreshToken
                );
                setSubmittingFinished(true);
                window.location.replace(response.data?.reset_password_link);
            })
            .catch((error) => {
                console.log(error);
                if (error?.response?.status == 400) {
                    setErrorMessage(
                        error?.response?.data
                            ? error?.response?.data
                            : 'Failed to signup'
                    );
                } else {
                    setErrorMessage('Oops! Something went wrong!');
                }

                setSubmittingFinished(true);
            });
        setLoader(false);
    }

    return (
        <div>
            <p className="text-center mb-20 font-display text-6xl text-gray-700 font-bold ">
                Sign up
            </p>
            {/* <p className="font-display text-2xl text-gray-t00 font-light">
                {' '}
                Join your team in Agile Analytics
            </p> */}
            {loader ? (
                <div className="w-full h-full flex flex-col gap-8 justify-center items-center bg-gray-50">
                    <div className="loader-wrapper pt-8 flex flex-col items-center justify-center gap-4">
                        <Loader
                            color={'#C2C7D7'}
                            size={35}
                            speedMultiplier={0.8}
                        />
                    </div>
                </div>
            ) : (
                <>
                    {errorMessageFromParent ? (
                        <>
                            <div className="rounded bg-red-200 text-red-600 px-2 py-2 mb-8">
                                {errorMessageFromParent}
                            </div>
                            <div className="-z-50 opacity-0">
                                {renderLoginOptions(
                                    'invite',
                                    'all',
                                    null,
                                    null,
                                    null,
                                    setErrorMessage,
                                    handlePasswordSignup,
                                    true
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            {errorMessage && (
                                <div className="rounded bg-red-200 text-red-600 px-2 py-2 mb-8">
                                    {errorMessage}
                                </div>
                            )}

                            {renderLoginOptions(
                                'invite',
                                'all',
                                null,
                                null,
                                null,
                                setErrorMessage,
                                handlePasswordSignup
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
}

export default AuthInviteForm;
