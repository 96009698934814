import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Select from 'react-select';
import { BiInfoCircle } from 'react-icons/bi';
import Loader from '../loader';
import { TimePeriodContext } from '../../context/TimePeriodContext';
import { capitalizeFirstLetter } from '../../helpers/textFormatters';

// charts = [{
//     title: { value: 'chart-name', label: 'Chart Name' },
//     description: 'description on the info box'
//     averageMeasure: '/day',
//     averageValue: averageCycleTime.leadTimeForChange,
//     series: {name: '', data: []}] Full series options: https://api.highcharts.com/highcharts/series
// }]

const DoraMetricsChart = ({
    charts,
    chartColor = null,
    customOptions = null,
    customPointFormatter = null,
    yAxisType = 'linear',
    yAxisFormat = '{value}',
    yAxisFormatter = null,
    chartHeight = 240,
    loading = false,
    className = '',
    setUpdate,
    update = 0,
    cardTimeperiod,
}) => {
    const { t } = useTranslation();

    Highcharts.setOptions({
        global: {
            useUTC: false,
        },
        lang: {
            months: [
                capitalizeFirstLetter(t('highcharts.month.1')),
                capitalizeFirstLetter(t('highcharts.month.2')),
                capitalizeFirstLetter(t('highcharts.month.3')),
                capitalizeFirstLetter(t('highcharts.month.4')),
                capitalizeFirstLetter(t('highcharts.month.5')),
                capitalizeFirstLetter(t('highcharts.month.6')),
                capitalizeFirstLetter(t('highcharts.month.7')),
                capitalizeFirstLetter(t('highcharts.month.8')),
                capitalizeFirstLetter(t('highcharts.month.9')),
                capitalizeFirstLetter(t('highcharts.month.10')),
                capitalizeFirstLetter(t('highcharts.month.11')),
                capitalizeFirstLetter(t('highcharts.month.12')),
            ],
            shortMonths: [
                capitalizeFirstLetter(t('highcharts.short_month.1')),
                capitalizeFirstLetter(t('highcharts.short_month.2')),
                capitalizeFirstLetter(t('highcharts.short_month.3')),
                capitalizeFirstLetter(t('highcharts.short_month.4')),
                capitalizeFirstLetter(t('highcharts.short_month.5')),
                capitalizeFirstLetter(t('highcharts.short_month.6')),
                capitalizeFirstLetter(t('highcharts.short_month.7')),
                capitalizeFirstLetter(t('highcharts.short_month.8')),
                capitalizeFirstLetter(t('highcharts.short_month.9')),
                capitalizeFirstLetter(t('highcharts.short_month.10')),
                capitalizeFirstLetter(t('highcharts.short_month.11')),
                capitalizeFirstLetter(t('highcharts.short_month.12')),
            ],
            weekdays: [
                capitalizeFirstLetter(t('highcharts.weekday.1')),
                capitalizeFirstLetter(t('highcharts.weekday.2')),
                capitalizeFirstLetter(t('highcharts.weekday.3')),
                capitalizeFirstLetter(t('highcharts.weekday.4')),
                capitalizeFirstLetter(t('highcharts.weekday.5')),
                capitalizeFirstLetter(t('highcharts.weekday.6')),
                capitalizeFirstLetter(t('highcharts.weekday.7')),
            ],
        },
    });
    // console.log('charts from chart start', charts[0].series[0].data[0]);
    const [timePeriodState] = useContext(TimePeriodContext);
    const { date_end, date_start } = timePeriodState;
    const [yAxisCustomLabels, setYAxisCustomLabels] = useState({
        format: yAxisFormat,
    });
    const [chartsToRender, setChartsToRender] = useState(charts);

    const [tooltip, setTooltip] = useState({
        shared: false,
        headerFormat:
            '<span style="font-size:12px"><b>{point.key}</b></span><br>',
    }); // shared: false -> tooltip will be hidden on mouseout; currently to have shared:false, providing customPointFormatter is required (otherwise it`ll be changed to true (line 69))
    const [selectedChart, setSelectedChart] = useState(null);
    const [infoHoverStatus, setInfoHoverStatus] = useState(false);

    useEffect(() => {
        setUpdate && setUpdate(0);
    }, [date_end, date_start, cardTimeperiod]);

    useEffect(() => {
        if ((charts?.length, update === 0)) {
            const formatted = {
                ...charts[0],
                series: formatSeries(charts[0]?.series),
            };
            setSelectedChart(formatted);
        }
        setChartsToRender(charts);
    }, [charts]);

    useEffect(() => {
        yAxisFormatter
            ? setYAxisCustomLabels({ formatter: yAxisFormatter })
            : setYAxisCustomLabels({
                  format: yAxisFormat,
              });
    }, [yAxisFormatter]);

    useEffect(() => {
        customPointFormatter
            ? setTooltip({ ...tooltip, pointFormatter: customPointFormatter })
            : setTooltip({
                  shared: true,
                  headerFormat:
                      '<span style="font-size:12px"><b>{point.key:%A, %B %d }</b></span><br>',
              });
    }, [customPointFormatter]);

    const selectOptions = chartsToRender?.map((chart) => chart.title);

    function updateOptions() {
        return {
            colors: chartColor ?? [('#7902D7', '#F8C238', '#15A2BB')],
            chart: {
                height: chartHeight,
            },
            title: {
                text: '',
            },
            yAxis: {
                labels: yAxisCustomLabels,
                type: yAxisType,
                title: {
                    enabled: false,
                },
                min: 0,
            },
            xAxis: {
                type: 'datetime',
                labels: {
                    format: '{value:%d %b}',
                    align: 'right',
                },
                gridLineWidth: 1,
                min: cardTimeperiod
                    ? cardTimeperiod.start * 1000
                    : date_start * 1000,
                max: cardTimeperiod
                    ? cardTimeperiod.end * 1000
                    : date_end * 1000,
            },
            credits: {
                enabled: false,
            },
            tooltip: tooltip,
            plotOptions: {
                series: {
                    opacity: 0.8,
                    stickyTracking: false,
                    events: {
                        mouseout: function () {
                            this.chart.tooltip.hide();
                        },
                    },
                },
                area: {
                    stacking: 'normal',
                },
                line: {
                    marker: {
                        enabled: false,
                        states: {
                            hover: {
                                enabled: false,
                            },
                        },
                    },
                    zIndex: '-10',
                    lineWidth: 1,
                },
                scatter: {
                    marker: {
                        states: {
                            radius: 6,
                        },
                    },
                },
            },
            legend: {
                enabled: true,
            },
            series: selectedChart?.series ?? [{ data: [null, null] }],
        };
    }

    const setSelectorStyle = () => ({
        control: (provided) => ({
            ...provided,
            outline: 'none',
            boxShadow: 'none',
            backgroundColor: 'transparent',
            opacity: '1',
            borderColor: 'transparent',
            '&:hover': {
                opacity: '1',
            },
            minWidth: '100px',
            minHeight: '20px',
            height: '20px',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#484a53',
            fontFamily: '"Exo 2", sans-serif',
            fontSize: '18px',
            fontWeight: '600',
            position: 'relative',
            marginTop: '9px',
            overflow: 'visible',
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            color: '#484a53',
            opacity: '1',
            padding: '0px',
            alignItems: 'start',
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: '#484a53',
            opacity: '1',
            cursor: 'pointer',
            '&:hover': {
                color: '#484a53',
            },
            padding: '0px',
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            backgroundColor: '#484a53',
            opacity: '0',
        }),
        menu: (provided) => ({
            ...provided,
            marginTop: '0',
            padding: '0',
        }),
        valueContainer: (provided) => {
            return {
                ...provided,
                flexWrap: 'no-wrap',
                padding: '0px',
                flex: 'none',
            };
        },
        option: (provided, state) => ({
            ...provided,
            cursor: 'pointer',
            color: '#484a53',
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: state.isSelected
                    ? '#4C72BD'
                    : 'rgba(76, 114, 189, 0.5)',
            },
        }),
    });

    function formatSeries(series) {
        return series?.map((chart) => {
            return {
                name: chart.name ?? '',
                data: chart.data,
                type: chart.type ?? 'area',
                stickyTracking: false,
            };
        });
    }

    function handleChartChange(option) {
        setUpdate && setUpdate((prevState) => prevState + 1);
        const selected = chartsToRender.find(
            (chart) => chart.title.value === option.value
        );
        selected &&
            setSelectedChart({
                ...selected,
                series: formatSeries(selected?.series),
            });
    }

    return (
        <div className={`w-full ${className}`}>
            <div className="px-4 mb-4 flex justify-between">
                {chartsToRender?.length ? (
                    chartsToRender.length > 1 ? (
                        <div className="flex">
                            <Select
                                options={selectOptions}
                                isSearchable={false}
                                placeholder="Metric"
                                styles={setSelectorStyle()}
                                value={selectedChart?.title}
                                onChange={(option) => handleChartChange(option)}
                            />
                            {selectedChart?.description ? (
                                <>
                                    <div
                                        className="cursor-pointer"
                                        onMouseOver={() =>
                                            setInfoHoverStatus(true)
                                        }
                                        onMouseOut={() =>
                                            setInfoHoverStatus(false)
                                        }
                                    >
                                        <BiInfoCircle
                                            size={16}
                                            color={'#7F8992'}
                                        />
                                    </div>
                                    {infoHoverStatus && (
                                        <div className="absolute -top-12 text-xs  w-full  py-1 px-3  z-50 shadow-md bg-gray-100">
                                            {selectedChart.description}
                                        </div>
                                    )}
                                </>
                            ) : null}
                        </div>
                    ) : (
                        <div>
                            <h5 className="font-display text-lg font-semibold leading-5">
                                {chartsToRender[0].title.label}
                            </h5>
                            {chartsToRender[0]?.description ? (
                                <>
                                    <div
                                        className="cursor-pointer"
                                        onMouseOver={() =>
                                            setInfoHoverStatus(true)
                                        }
                                        onMouseOut={() =>
                                            setInfoHoverStatus(false)
                                        }
                                    >
                                        <BiInfoCircle
                                            size={16}
                                            color={'#7F8992'}
                                        />
                                    </div>
                                    {infoHoverStatus && (
                                        <div className="absolute -top-12 text-xs w-full  py-1 px-3  z-50 shadow-md bg-gray-100">
                                            {chartsToRender[0].description}
                                        </div>
                                    )}
                                </>
                            ) : null}
                        </div>
                    )
                ) : null}
                {selectedChart?.averageValue ? (
                    <p className="  text-gray-500">
                        <span className="font-display text-lg font-semibold leading-5">
                            {selectedChart.averageValue}
                        </span>{' '}
                        <span className="font-display">
                            {selectedChart?.averageMeasure}
                        </span>
                    </p>
                ) : null}
            </div>
            {loading ? (
                <div className="w-full h-full flex justify-center items-center">
                    <Loader />
                </div>
            ) : (
                <HighchartsReact
                    highcharts={Highcharts}
                    options={customOptions ? customOptions : updateOptions()}
                />
            )}
        </div>
    );
};

export default DoraMetricsChart;
