import { useState, useContext, useEffect } from 'react';
import { createRepositoryWebhook } from '../../../api/settings/GitProviderAPI';
import { Gitlab, Github, Bitbucket, Arrow } from '../../../assets/svg';
import showNotification from '../../../helpers/showNotification';
import SelectedRepositoryInfo from './info';
import Loader from '../../../components/loader';
import moment from 'moment';
import {checkRepoWebhook, deleteRepoWebhook} from '../../../api/swarm/SwarmAPI';
import { capitalizeFirstLetter } from '../../../helpers/textFormatters';
import { useTranslation } from 'react-i18next';
import { OrganisationsContext } from '../../../context/OrganisationsContext';
import { getWritePermission } from '../../../helpers/getPermission';
import ModalWrapper from "../../modal-wrapper";
import ConfirmationModelDeleteWebhook from "../../confirmation-model-delete-webhook";

const SelectedRepository = ({
    data,
    provider,
    stockState,
    leaksState,
    jobsState,
    index,
    infoStates,
    setInfoStates,
    selectedRepositoriesDispatch,
    setAddRepositoryLoading,
    allRepositories,
    prevReposLength,
}) => {
    const [webhookLoading, setWebhookLoading] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [localRepoData, setLocalRepoData] = useState(data);
    const { t } = useTranslation();
    const demoOrgHash = process.env.REACT_APP_DEMO_ORG_HASH;
    const { organisationsState } = useContext(OrganisationsContext);

    useEffect(() => {
        setLocalRepoData(data);
    }, [data]);

    const selectedOrganisationHash = organisationsState.data
        ? organisationsState.data.find((org) => org.active).org_hash
        : null;

    const getLogo = (providerType) => {
        switch (true) {
            case providerType.startsWith('gitlab'):
                return <Gitlab />;
            case providerType.startsWith('github'):
                return <Github />;
            case providerType.startsWith('bitbucket'):
                return <Bitbucket />;
            default:
                return null;
        }
    };

    const renderLeaks = () => {
        if (leaksState.loading) return '...';
        if (leaksState.error) return "Error. Can't get";
        if (leaksState.data) {
            return leaksState.data[localRepoData.name]
                ? leaksState.data[localRepoData.name]
                : 0;
        }
    };

    const getCodeLines = () => {
        if (stockState.loading) return '...';
        if (stockState.error) return "Error. Can't get";
        if (stockState.data) {
            return stockState.data[localRepoData.name]?.total_code_lines
                ? stockState.data[localRepoData.name].total_code_lines
                : 0;
        }
    };

    const getBranches = () => {
        if (stockState.loading) return '...';
        if (stockState.error) return "Error. Can't get";
        if (stockState.data) {
            return stockState.data[localRepoData.name]?.total_branches
                ? stockState.data[localRepoData.name].total_branches
                : 0;
        }
    };

    function inSameRow(i) {
        const itemGroupIndex = index - prevReposLength;
        const allItemsIndex = i - prevReposLength;
        if (
            itemGroupIndex % 3 === 0 &&
            (itemGroupIndex + 1 === allItemsIndex ||
                itemGroupIndex + 2 === allItemsIndex)
        ) {
            return true;
        } else if (
            itemGroupIndex % 3 === 1 &&
            (itemGroupIndex - 1 === allItemsIndex ||
                itemGroupIndex + 1 === allItemsIndex)
        ) {
            return true;
        } else if (
            itemGroupIndex % 3 === 2 &&
            (itemGroupIndex - 2 === allItemsIndex ||
                itemGroupIndex - 1 === allItemsIndex)
        ) {
            return true;
        } else {
            return false;
        }

        // Initial Logic (for non-grouped repos list)
        // if (index % 3 === 0 && (index + 1 === i || index + 2 === i)) {
        //     return true;
        // } else if (index % 3 === 1 && (index - 1 === i || index + 1 === i)) {
        //     return true;
        // } else if (index % 3 === 2 && (index - 2 === i || index - 1 === i)) {
        //     return true;
        // } else {
        //     return false;
        // }
    }

    async function handleCreateWebhook() {
        setWebhookLoading(true);

        try {
            const params = {
                provider_id: provider.provider_id,
                repository_url: localRepoData.url,
            };

            const res = await createRepositoryWebhook(
                provider.provider,
                'some data',
                {
                    params: params,
                }
            );

            setWebhookLoading(false);

            if (res.status === 200) {
                setLocalRepoData((prevState) => {
                    return { ...prevState, webhook: true };
                });
            }
        } catch (error) {
            setWebhookLoading(false);
            showNotification(
                `Failed to create webhook. ${
                    error?.response?.data ? error?.response?.data : ''
                }`
            );
        }
    }

    async function handleCheckWebhook() {
        setWebhookLoading(true);

        try {
            const params = {
                provider_id: provider.provider_id,
                repository_url: localRepoData.url,
            };

            const res = await checkRepoWebhook(params);

            setWebhookLoading(false);

            res?.data?.webhook_enabled
                ? showNotification('Webhook enabled', 'success')
                : showNotification(
                      'Webhook is invalid, please create a new one'
                  );

            if (res.status === 200) {
                setLocalRepoData((prevState) => {
                    return {
                        ...prevState,
                        webhook: res?.data?.webhook_enabled,
                        last_checked: res?.data?.last_checked,
                    };
                });
            }
        } catch (error) {
            setWebhookLoading(false);
            showNotification('Failed to check webhook status');
        }
    }

    async function handleDeleteWebhook() {
        setWebhookLoading(true)

        try {
            const params = {
                provider_id: provider.provider_id,
                repository_url: encodeURIComponent(localRepoData.url),
            };

            const res = await deleteRepoWebhook(params)

            setWebhookLoading(false);

            if (res.status === 200) {
                setLocalRepoData((prevState) => {
                    return {
                        ...prevState,
                        webhook: false,
                        last_checked: null,
                    };
                });

                showNotification("Webhook deleted!", 'success')
            }
        } catch (error) {
            console.log(error)
            setWebhookLoading(false);
            showNotification('Failed to delete webhook');
        }
    }

    return (
        <>
            <div className="bg-gray-bg flex justify-between items-center grid grid-cols-12 gap-y-2 px-4 pt-3 pb-4 border-solid border-2 border-gray-400 rounded-lg">
                <div className="w-6 h-6 mb-4">{getLogo(provider.provider)}</div>

                <div className="col-span-10 mb-4 ml-4 font-display">
                    {localRepoData.name}
                </div>

                <div className="mb-4">
                    <button
                        onClick={() => {
                            Object.keys(infoStates)?.forEach((i) => {
                                if (i !== index && inSameRow(parseInt(i))) {
                                    setInfoStates((prevState) => ({
                                        ...prevState,
                                        [i]: false,
                                    }));
                                }
                            });

                            setInfoStates((prevState) => ({
                                ...prevState,
                                [index]: !infoStates[index],
                            }));
                        }}
                        className="float-right"
                    >
                        <Arrow
                            fill="#484a53"
                            width="16px"
                            height="16px"
                            transform={
                                infoStates[index] ? 'rotate(180)' : 'rotate(0)'
                            }
                        />
                    </button>
                </div>

                <div className="col-span-4 text-gray-500 text-center">
                    {capitalizeFirstLetter(t('repo_page.branches'))}
                </div>
                <div className="col-span-4 text-gray-500 text-center">
                    {capitalizeFirstLetter(t('repo_page.code_lines'))}
                </div>
                <div className="col-span-4 text-gray-500 text-center">
                    {capitalizeFirstLetter(t('repo_page.leaks'))}
                </div>

                <div className="col-span-4 text-center">{getBranches()}</div>
                <div className="col-span-4 text-center">{getCodeLines()}</div>
                <div className="col-span-4 text-center">{renderLeaks()}</div>

                <div className="col-span-12 mt-4 ">
                    {webhookLoading ? (
                        <Loader size={16} />
                    ) : localRepoData.webhook ? (
                        <div>
                            <p className="text-sm text-success-green-300">
                                {capitalizeFirstLetter(
                                    t('repo_page.webhook_created')
                                )}
                            </p>
                            {selectedOrganisationHash !== demoOrgHash &&
                            !getWritePermission(
                                organisationsState.data
                            ) ? null : (
                                <>
                                    <div className="flex items-center gap-x-1">
                                        <button
                                            className="add-repository-toggler text-xs  border border-solid border-gray-400 text-gray-400 font-display px-1 py-0.5 rounded hover:bg-theme-tertiary hover:text-white hover:border-theme-tertiary"
                                            onClick={() => handleCheckWebhook()}
                                        >
                                            {capitalizeFirstLetter(
                                                t('repo_page.check')
                                            )}
                                        </button>
                                        <p className="text-xs text-gray-400">
                                            {capitalizeFirstLetter(
                                                t('repo_page.last_checked')
                                            )}
                                            :{' '}
                                            {localRepoData?.last_checked
                                                ? moment
                                                      .unix(
                                                          localRepoData.last_checked
                                                      )
                                                      .format('DD MMMM YYYY')
                                                : 'never'}
                                        </p>
                                    </div>
                                    <div className="flex items-center gap-x-1 mt-1">
                                        <button
                                            className="add-repository-toggler text-xs  border border-solid border-gray-400 text-gray-400 font-display px-1 py-0.5 rounded hover:bg-theme-tertiary hover:text-white hover:border-theme-tertiary"
                                            onClick={() => setIsDeleteModalOpen(true)}
                                        >
                                            {capitalizeFirstLetter(
                                                t('repo_page.delete')
                                            )}
                                        </button>
                                        <p className="text-xs text-gray-400">
                                            {capitalizeFirstLetter(
                                                t('repo_page.delete_webhook')
                                            )}
                                        </p>
                                    </div>
                                </>
                            )}
                        </div>
                    ) : getWritePermission(organisationsState.data) ? (
                        <button
                            className="font-display text-sm underline text-theme-tertiary cursor-pointer"
                            onClick={() => handleCreateWebhook()}
                        >
                            {capitalizeFirstLetter(
                                t('repo_page.create_webhook')
                            )}
                        </button>
                    ) : (
                        <p className="text-sm text-gray-500">No webhook</p>
                    )}
                </div>
            </div>

            {infoStates[index] && (
                <SelectedRepositoryInfo
                    stockState={stockState}
                    jobsState={jobsState}
                    data={localRepoData}
                    provider={provider}
                    index={index}
                    prevReposLength={prevReposLength}
                    selectedRepositoriesDispatch={selectedRepositoriesDispatch}
                    setAddRepositoryLoading={setAddRepositoryLoading}
                    allRepositories={allRepositories}
                />
            )}

            <ModalWrapper
                modalState={isDeleteModalOpen}
                setModalState={setIsDeleteModalOpen}
                title={t("repo_page.delete_webhook")}
                height={200}
            >
                <ConfirmationModelDeleteWebhook
                    setModalState={setIsDeleteModalOpen}
                    data={localRepoData}
                    sendRequest={handleDeleteWebhook}
                />
            </ModalWrapper>
        </>
    );
};

export default SelectedRepository;
