import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import showNotification from '../../helpers/showNotification';
import { getOrgUsers } from '../../api/settings/UserAPI';

function UserTimesheetSelect({
    isPermission = false,
    permission,
    setUserHash,
    viewerData,
    userHash,
    selectedOrgHash,
}) {
    const [userOptions, setUserOptions] = useState([]);

    let user =
        permission === 'superadmin' &&
        !userOptions.find((user) => user.value === userHash)
            ? userOptions[0]
            : userOptions.find((user) => user.value === userHash);

    useEffect(() => {
        user?.value && setUserHash(user.value);
    }, [user]);

    useEffect(() => {
        async function getUsers() {
            try {
                const res = await getOrgUsers();
                if (res.status !== 204) {
                    const options = res.data.map((user) => {
                        if (
                            user.first_name ===
                                'User did not provide last name' &&
                            user.last_name === 'User did not provide last name'
                        ) {
                            if (user?.user_name) {
                                return {
                                    value: user.hash,
                                    label: user.user_name,
                                };
                            } else {
                                return {
                                    value: user.hash,
                                    label: user.email,
                                };
                            }
                        }

                        let label = `${user.first_name} ${user.last_name}`;
                        if (!user.first_name || !user.last_name) {
                            label = user.email;
                        }
                        return {
                            value: user.hash,
                            label: label,
                        };
                    });
                    setUserOptions(options);
                } else {
                    setUserOptions([]);
                }
            } catch (err) {
                isPermission &&
                    showNotification(`${err.response?.data || err.message}`);
            }
        }

        void getUsers();
    }, [selectedOrgHash]);

    useEffect(() => {
        !userOptions.length && setUserHash(null);
    }, [userOptions]);

    function handleSelectChange(value) {
        user = value;
        setUserHash(value.value);
    }

    const setCustomStyle = () => ({
        control: (provided) => ({
            ...provided,
            padding: '2px 0',
            width: '310px',
            outline: 'none',
            boxShadow: 'none',
            backgroundColor: 'transparent',
            opacity: '1',
            height: '42px',
            borderColor: '#dbeafe',
            '&:hover': {
                borderColor: '#dbeafe',
                backgroundColor: '#f3f4f6',
            },
            cursor: 'pointer',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#c2c7d7',
            '&:hover': {
                color: '#86899f',
            },
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            color: '#484a53',
            opacity: '1',
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: '#c2c7d7',
            opacity: '1',
            cursor: 'pointer',
            '&:hover': {
                color: '#86899f',
            },
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            backgroundColor: '#c2c7d7',
            opacity: '1',
        }),
    });

    function composeDisplayedName(userData) {
        if (userData.first_name && userData.last_name) {
            return viewerData.first_name + ' ' + viewerData.last_name;
        } else if (userData.last_name) {
            return userData.last_name;
        } else if (userData.first_name) {
            return userData.first_name;
        } else {
            return userData.email;
        }
    }

    return (
        <div className="">
            {isPermission ? (
                <Select
                    name={'user'}
                    value={user}
                    options={userOptions}
                    onChange={(value) => handleSelectChange(value)}
                    styles={setCustomStyle()}
                />
            ) : (
                <div className="w-310 h-full flex justify-end items-center border border-blue-100 rounded text-gray-700">
                    <p className="text-xl font-light text-gray-700">
                        {composeDisplayedName(viewerData)}
                    </p>
                </div>
            )}
        </div>
    );
}

export default UserTimesheetSelect;
