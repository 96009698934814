import React, { useState, useContext, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import Loader from '../../loader';
import ErrorIndicator from '../../error-indicator';
import { OrganisationsContext } from '../../../context/OrganisationsContext';
import Select from 'react-select';
import moment from 'moment';
import { useFetchDataAndSetState } from '../../../helpers/useFetchDataAndSetState';
import { UserContext } from '../../../context/UserContext';
import userAvatar from '../../../assets/user-avatar.png';
import {
    getOrgUsers,
    setOrgUserHoursAndPermission,
    inviteUser,
    setUserJira,
    deleteUser,
    deleteOrgUser,
} from '../../../api/settings/UserAPI';
import WhitePanelContentWrapper from '../../white-panel-content-wrapper';
import PanelTitle from '../../panel-title';
import { getAdminPermission } from '../../../helpers/getPermission';
import { capitalizeFirstLetter } from '../../../helpers/textFormatters';
import ModalWrapper from '../../modal-wrapper';
import { NAVIGATION_PATHS } from '../../../constants';
import ModalAddUserContent from '../../modal-add-user-content';
import ModalConnectUserJiraContent from '../../modal-connect-user-jira-content';
import showNotification from '../../../helpers/showNotification';
import { ImBin, ImPencil } from 'react-icons/im';
import UsersTabName from '../../users-tab-name';
import ModalDeleteUser from '../../modal-delete-user';

const UsersTab = () => {
    const { t } = useTranslation();
    const [refreshCounter, setRefreshCounter] = useState(0);
    const { organisationsState } = useContext(OrganisationsContext);
    const { userState } = useContext(UserContext);
    const selectedOrganisationHash = organisationsState.data
        ? organisationsState.data.find((org) => org.active).org_hash
        : null;

    const userPermission = organisationsState.data
        ? organisationsState.data.find((org) => org.active).permissions
        : null;

    const request = useCallback(() => getOrgUsers(), [refreshCounter]);

    const [usersState, usersDispatch] = useFetchDataAndSetState(request, [
        selectedOrganisationHash,
    ]);

    const [filter, setFilter] = useState('');
    const [filteredUsersData, setFilteredUsersData] = useState(usersState.data);
    const [modalState, setModalState] = useState(false);
    const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);

    useEffect(() => {
        const filteredData = filterUsers(filter);
        setFilteredUsersData(filteredData);
    }, [usersState.data, filter]);

    function filterUsers(filterValue) {
        if (!filterValue) {
            return usersState.data;
        }

        const formattedFilter = filterValue.toLowerCase();
        const keys = Object.keys(usersState.data[0]);
        const filtered = usersState.data.filter((user) => {
            const isInObject = keys.find((key) =>
                user[key]?.toString().toLowerCase().includes(formattedFilter)
            );

            if (isInObject) {
                return true;
            } else {
                return false;
            }
        });

        return filtered;
    }

    async function handleInviteUser(values) {
        const payload = {
            role: values.role,
            email_receiver: values.email,
            name_receiver: values?.name,
            user_hash: userState?.data?.user_hash,
        };

        const responce = await inviteUser(payload);
        responce.status === 200 && setRefreshCounter(refreshCounter + 1);

        return responce;
    }

    if (usersState.error) {
        return (
            <div className="settings-tab-content users-tab">
                <ErrorIndicator error={usersState.error} />
            </div>
        );
    }

    if (usersState.loading) {
        return (
            <div className="settings-tab-content users-tab flex justify-center">
                <Loader />
            </div>
        );
    }

    return (
        <WhitePanelContentWrapper className="settings-tab-content users-tab mb-8 overflow-visible">
            <PanelTitle
                title={capitalizeFirstLetter(
                    t('settings_page.users_tab.users')
                )}
            />
            <div className="mb-2 flex justify-between">
                <input
                    className="p-2 placeholder-gray-300 text-gray-700 relative bg-white
             rounded text-md border border-gray-300 outline-none focus:outline-none focus:ring w-1/2 disabled:bg-gray-300"
                    id={filter}
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    placeholder="Filter..."
                />
                <button
                    type="button"
                    className="py-1.5 px-3 border border-solid border-theme-tertiary rounded font-display text-theme-tertiary font-semibold hover:bg-theme-tertiary hover:text-white cursor-pointer"
                    onClick={() => setModalState(true)}
                >
                    {capitalizeFirstLetter(
                        t('settings_page.users_tab.add_user')
                    )}
                </button>
            </div>
            <div className="users-list">
                <div className="users-list__header">
                    <div className="user-avatar">
                        <p>
                            {capitalizeFirstLetter(
                                t('settings_page.users_tab.photo')
                            )}
                        </p>
                    </div>
                    <div className="user-name">
                        <p className="value">
                            {capitalizeFirstLetter(
                                t('settings_page.users_tab.name')
                            )}
                        </p>
                    </div>
                    <div className="user-email">
                        <p className="value">
                            {capitalizeFirstLetter(
                                t('settings_page.users_tab.email')
                            )}
                        </p>
                    </div>
                    <div className="user-login">
                        <p className="value">
                            {capitalizeFirstLetter(
                                t('settings_page.users_tab.login')
                            )}
                        </p>
                    </div>
                    <div className="user-permission">
                        <p>
                            {capitalizeFirstLetter(
                                t('settings_page.users_tab.permission')
                            )}
                        </p>
                    </div>
                    <div className="user-hours">
                        <p>
                            {capitalizeFirstLetter(
                                t('settings_page.users_tab.hours')
                            )}
                        </p>
                    </div>
                    <div className="user-jira">
                        <p>
                            {capitalizeFirstLetter(
                                t('settings_page.users_tab.jira')
                            )}
                        </p>
                    </div>
                </div>
                <div className="users-list__body">
                    {filteredUsersData?.length > 0 ? (
                        filteredUsersData.map((user) => (
                            <User
                                data={user}
                                usersData={filteredUsersData}
                                key={user.email}
                                userPermission={userPermission}
                                usersDispatch={usersDispatch}
                                setRefreshCounter={setRefreshCounter}
                                refreshCounter={refreshCounter}
                                isUpgradeModalOpen={isUpgradeModalOpen}
                                setIsUpgradeModalOpen={setIsUpgradeModalOpen}
                            />
                        ))
                    ) : (
                        <p className="no-users">
                            {capitalizeFirstLetter(
                                t('settings_page.users_tab.no_users')
                            )}
                        </p>
                    )}
                </div>
            </div>
            <ModalWrapper
                modalState={modalState}
                setModalState={setModalState}
                title={capitalizeFirstLetter(
                    t('settings_page.users_tab.add_user_modal_title')
                )}
                isFit={true}
            >
                <ModalAddUserContent
                    sendRequest={handleInviteUser}
                    setModalState={setModalState}
                    setIsUpgradeModalOpen={setIsUpgradeModalOpen}
                />
            </ModalWrapper>
            <ModalWrapper
                modalState={isUpgradeModalOpen}
                setModalState={setIsUpgradeModalOpen}
                title="You've reached your plan limits"
                height={200}
            >
                <p className="text-gray-700 pt-4 ">
                    {"Please extend your plan's number of contributors."}
                </p>
                <div className="flex-grow flex justify-center items-center">
                    <HashLink
                        to={{
                            pathname: NAVIGATION_PATHS.settingsOrganisation,
                            hash: '#manage-plan',
                        }}
                        className="btn-dark-blue"
                    >
                        Go to plan configuration
                    </HashLink>
                </div>
            </ModalWrapper>
        </WhitePanelContentWrapper>
    );
};

const User = ({
    data,
    userPermission,
    usersData,
    usersDispatch,
    setRefreshCounter,
    refreshCounter,
}) => {
    const { t } = useTranslation();
    const { organisationsState } = useContext(OrganisationsContext);
    const [localUserPermission, setLocalUserPermission] = useState({
        permission: { value: data.role, label: data.role },
        loading: false,
        error: false,
    });

    const [localUserHours, setLocalUserHours] = useState({
        hours: { value: data.contract_hours, label: data.contract_hours },
        loading: false,
        error: false,
    });

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const translatedRoles = {
        read: t('settings_page.users_tab.roles.read'),
        write: t('settings_page.users_tab.roles.write'),
        admin: t('settings_page.users_tab.roles.admin'),
        superadmin: t('settings_page.users_tab.roles.superadmin'),
    };

    const saveUserPermission = (option) => {
        setOrgUserHoursAndPermission({ role: option.value }, data.hash)
            .then((response) => {
                if (response.status === 200) {
                    const newUsersData = usersData.map((user) => {
                        if (user.hash === data.hash) {
                            user.conract_hours =
                                localUserPermission.permission.value;
                        }

                        return user;
                    });
                    usersDispatch({ type: 'SET_DATA', payload: newUsersData });
                    setLocalUserPermission((prevState) => {
                        return {
                            ...prevState,
                            permission: option,
                            loading: true,
                        };
                    });
                }
            })
            .catch((error) => {
                if (error?.response?.status === 409) {
                    setIsUpgradeModalOpen(true);
                }
                showNotification(
                    error.response?.data || error.message || error.data.message
                );

                setLocalUserPermission((prevState) => {
                    return {
                        ...prevState,
                        loading: false,
                        error: error,
                    };
                });
            });
    };

    const saveUserHours = (option) => {
        setLocalUserHours((prevState) => {
            return {
                ...prevState,
                hours: option,
                loading: true,
            };
        });

        setOrgUserHoursAndPermission({ hours: option.value }, data.hash)
            .then((response) => {
                if (response.status === 200) {
                    const newUsersData = usersData.map((user) => {
                        if (user.hash === data.hash) {
                            user.contract_hours = localUserHours.hours.value;
                        }

                        return user;
                    });
                    usersDispatch({ type: 'SET_DATA', payload: newUsersData });
                    setLocalUserHours((prevState) => {
                        return {
                            ...prevState,
                            loading: false,
                        };
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                showNotification(
                    error.response?.data || error.message || error.data.message
                );
                setLocalUserHours((prevState) => {
                    return {
                        ...prevState,
                        loading: false,
                        error: error,
                    };
                });
            });
    };

    const renderPermission = (translatedRoles) => {
        switch (userPermission) {
            case 'superadmin':
            case 'admin':
                let permission = localUserPermission?.permission?.value;
                if (
                    permission === 'superadmin' ||
                    permission === 'demo user' ||
                    permission === 'admin'
                ) {
                    return (
                        <p>
                            {translatedRoles[
                                localUserPermission.permission.value
                            ]
                                ? translatedRoles[
                                      localUserPermission.permission.value
                                  ]
                                : localUserPermission.permission.value}
                        </p>
                    );
                } else {
                    return (
                        <Select
                            isSearchable={false}
                            options={[
                                {
                                    value: 'admin',
                                    label: translatedRoles.admin,
                                },
                                {
                                    value: 'write',
                                    label: translatedRoles.write,
                                },
                                { value: 'read', label: translatedRoles.read },
                            ]}
                            styles={setCustomStyle()}
                            value={{
                                value: localUserPermission.permission.value,
                                label: translatedRoles[
                                    localUserPermission.permission.value
                                ]
                                    ? translatedRoles[
                                          localUserPermission.permission.value
                                      ]
                                    : localUserPermission.permission.label,
                            }}
                            onChange={(option) => saveUserPermission(option)}
                        />
                    );
                }

            case 'write':
                return (
                    <Select
                        isSearchable={false}
                        options={[
                            { value: 'write', label: translatedRoles.write },
                            { value: 'read', label: translatedRoles.read },
                        ]}
                        styles={setCustomStyle()}
                        value={localUserPermission.permission}
                        onChange={(option) => saveUserPermission(option)}
                    />
                );
            case 'read':
            default:
                return (
                    <p>
                        {translatedRoles[localUserPermission.permission.value]
                            ? translatedRoles[
                                  localUserPermission.permission.value
                              ]
                            : localUserPermission.permission.value}
                    </p>
                );
        }
    };

    const renderHours = () => {
        if (getAdminPermission(organisationsState.data)) {
            return (
                <Select
                    isSearchable={false}
                    options={[
                        { value: '40', label: '40' },
                        { value: '36', label: '36' },
                        { value: '32', label: '32' },
                        { value: '30', label: '30' },
                        { value: '<30', label: '<30' },
                    ]}
                    styles={setCustomStyle()}
                    value={localUserHours.hours}
                    placeholder={
                        localUserHours.hours ? localUserHours.hours : ''
                    }
                    onChange={(option) => saveUserHours(option)}
                />
            );
        } else {
            return <p>{localUserHours.hours.label ?? '-'}</p>;
        }
    };

    async function handleConnectJira(email) {
        const responce = await setUserJira(email, data.hash);
        responce.status === 200 && setRefreshCounter(refreshCounter + 1);
        return responce;
    }

    async function updateUserName(values) {
        const formattedUserData = {
            username: values.name,
            // role: localUserPermission?.permission.value,
        };

        try {
            const res = await setOrgUserHoursAndPermission(
                formattedUserData,
                data.hash
            );

            if (res.status === 200) {
                const newUsersData = usersData.map((user) => {
                    if (user.hash === data.hash) {
                        user.user_name = values.name;
                    }

                    return user;
                });
                usersDispatch({ type: 'SET_DATA', payload: newUsersData });
            }
            return true;
        } catch (error) {
            showNotification(
                error.response?.data || error.message || error.data.message
            );
            return false;
        }
    }

    async function handleDeleteUser(userData) {
        try {
            // TODO: this is admin panel endpoint. replace with the one which will be created for orgs
            const res = await deleteOrgUser({
                email: userData?.email,
                role: userData?.role,
            });
            // const res = { status: 200 };

            if (res.status === 200) {
                const newUsersData = usersData.reduce((acc, user) => {
                    if (user.hash === data.hash) {
                        return acc;
                    }
                    return [...acc, user];
                }, []);
                usersDispatch({ type: 'SET_DATA', payload: newUsersData });
            }
        } catch (error) {
            showNotification(
                error.response?.data || error.message || error.data.message
            );
        }
    }

    return (
        <div className="user">
            <div className="user-avatar">
                <img
                    src={data.photo ? data.photo : userAvatar}
                    alt="User Avatar"
                    title="User Avatar"
                />
            </div>
            <div className="user-name flex">
                <UsersTabName
                    userName={data.user_name}
                    orgsState={organisationsState}
                    handleUpdate={updateUserName}
                />
            </div>
            <div className="user-email">
                <p className="value" title={data.email}>
                    {data.email}
                </p>
            </div>
            <div className="user-login">
                <p className="value" title={data.last_login}>
                    {data.last_login
                        ? moment.unix(data.last_login).format('DD MMM YYYY')
                        : '-'}
                </p>
            </div>
            <div className="user-permission">
                {renderPermission(translatedRoles)}
            </div>
            <div className="user-hours">{renderHours()}</div>
            <div className="user-jira">
                {data?.user_profiles?.length &&
                data.user_profiles[0]?.jira_hash ? (
                    <p className="">
                        {capitalizeFirstLetter(
                            t('settings_page.users_tab.connected')
                        )}
                    </p>
                ) : (
                    <div className="flex gap-2 items-center ">
                        <p>
                            {capitalizeFirstLetter(
                                t('settings_page.users_tab.not_connected')
                            )}
                        </p>
                        {getAdminPermission(organisationsState.data) ? (
                            <button
                                type="button"
                                className="py-1.5 px-3 border border-solid border-theme-tertiary rounded font-display text-theme-tertiary font-semibold hover:bg-theme-tertiary hover:text-white cursor-pointer"
                                onClick={() => setIsModalOpen(true)}
                            >
                                {capitalizeFirstLetter(
                                    t('settings_page.users_tab.connect')
                                )}
                            </button>
                        ) : null}
                    </div>
                )}
            </div>
            {getAdminPermission(organisationsState.data) ? (
                <button
                    className={` p-2 text-red-600 transform opacity-50 hover:opacity-100`}
                    type="button"
                    onClick={() => setIsDeleteModalOpen(true)}
                >
                    <ImBin />
                </button>
            ) : null}

            <ModalWrapper
                modalState={isDeleteModalOpen}
                setModalState={setIsDeleteModalOpen}
                title={`Remove ${data.user_name ? data.user_name : 'user'}`}
                height={200}
            >
                <ModalDeleteUser
                    setModalState={setIsDeleteModalOpen}
                    userData={data}
                    sendRequest={handleDeleteUser}
                />
            </ModalWrapper>

            <ModalWrapper
                modalState={isModalOpen}
                setModalState={setIsModalOpen}
                height={220}
                title={capitalizeFirstLetter(
                    t('settings_page.users_tab.connect_jira')
                )}
            >
                <ModalConnectUserJiraContent
                    sendRequest={handleConnectJira}
                    handleChangeModalState={() => setIsModalOpen(false)}
                />
            </ModalWrapper>
            <ModalWrapper
                modalState={isUpgradeModalOpen}
                setModalState={setIsUpgradeModalOpen}
                title="You've reached your plan limits"
                height={200}
            >
                <p className="text-gray-700 pt-4 ">
                    {localUserPermission.error?.response?.message ??
                        'Extend your plan contributors number to give more users write/admin permission.'}
                </p>
                <div className="flex-grow flex justify-center items-center">
                    <HashLink
                        to={{
                            pathname: NAVIGATION_PATHS.settingsOrganisation,
                            hash: '#manage-plan',
                        }}
                        className="btn-dark-blue"
                    >
                        Go to plan configuration
                    </HashLink>
                </div>
            </ModalWrapper>
        </div>
    );
};

const setCustomStyle = () => ({
    control: (provided) => ({
        ...provided,
        padding: '2px 0',
        width: '125px',
        outline: 'none',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        opacity: '1',
        height: '42px',
        borderColor: '#484a53',
        '&:hover': {
            opacity: '1',
        },
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#484a53',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        color: '#484a53',
        opacity: '1',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: '#484a53',
        opacity: '1',
        cursor: 'pointer',
        '&:hover': {
            color: '#484a53',
        },
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        backgroundColor: '#484a53',
        opacity: '1',
    }),
    menu: (provided) => ({
        ...provided,
        marginTop: '0',
        padding: '0',
    }),
    valueContainer: (provided) => ({
        ...provided,
        height: '100%',
        flexWrap: 'no-wrap',
    }),
    option: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
        color: '#484a53',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: state.isSelected
                ? '#4C72BD'
                : 'rgba(76, 114, 189, 0.5)',
        },
    }),
});

export default UsersTab;
