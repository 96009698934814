import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { capitalizeFirstLetter } from '../../../helpers/textFormatters';

const chartManager = [];

Highcharts.Pointer.prototype.reset = function () {
    return undefined;
};

const onMouseMove = (event, target) => {
    const hoverPoint = target.hoverPoint;
    if (hoverPoint && chartManager.length > 1) {
        chartManager?.forEach((chart) => {
            const { series = [], xAxis = [] } = chart;
            if (!series || series.length === 0) {
                return;
            }
            const visibleSeries = series.filter((serie) => serie.visible);
            const points = visibleSeries.reduce((memo, serie) => {
                memo.push(...serie.points);
                return memo;
            }, []);
            const { min = Infinity, max = -Infinity } = xAxis[0] || {};
            const point = points.find((item) => item.x === hoverPoint.x);

            if (point) {
                if (
                    point.series &&
                    hoverPoint.x >= min &&
                    hoverPoint.x <= max
                ) {
                    point.onMouseOver();
                } else {
                    chart.tooltip.hide();
                }
            }
        });
    }
};

const registerChart = (target) => {
    const index = chartManager.indexOf(target);
    if (index < 0) {
        chartManager.push(target);
    }
};

const unregisterChart = (target) => {
    const index = chartManager.indexOf(target);

    if (index >= 0) {
        chartManager.splice(index, 1);
    }
};

const syncTooltip = (target = {}) => {
    if (!target || !target.container) {
        return false;
    }
    registerChart(target);

    const mouseMoveHandler = (event) => onMouseMove(event, target);
    target.container.addEventListener('mousemove', mouseMoveHandler);

    return () => {
        unregisterChart(target);
        target.container.removeEventListener('mousemove', mouseMoveHandler);
    };
};

const SyncChart = ({ options }) => {
    const chartComponentRef = useRef(null);

    const { t } = useTranslation();

    Highcharts.setOptions({
        global: {
            useUTC: false,
        },
        lang: {
            months: [
                capitalizeFirstLetter(t('highcharts.month.1')),
                capitalizeFirstLetter(t('highcharts.month.2')),
                capitalizeFirstLetter(t('highcharts.month.3')),
                capitalizeFirstLetter(t('highcharts.month.4')),
                capitalizeFirstLetter(t('highcharts.month.5')),
                capitalizeFirstLetter(t('highcharts.month.6')),
                capitalizeFirstLetter(t('highcharts.month.7')),
                capitalizeFirstLetter(t('highcharts.month.8')),
                capitalizeFirstLetter(t('highcharts.month.9')),
                capitalizeFirstLetter(t('highcharts.month.10')),
                capitalizeFirstLetter(t('highcharts.month.11')),
                capitalizeFirstLetter(t('highcharts.month.12')),
            ],
            shortMonths: [
                capitalizeFirstLetter(t('highcharts.short_month.1')),
                capitalizeFirstLetter(t('highcharts.short_month.2')),
                capitalizeFirstLetter(t('highcharts.short_month.3')),
                capitalizeFirstLetter(t('highcharts.short_month.4')),
                capitalizeFirstLetter(t('highcharts.short_month.5')),
                capitalizeFirstLetter(t('highcharts.short_month.6')),
                capitalizeFirstLetter(t('highcharts.short_month.7')),
                capitalizeFirstLetter(t('highcharts.short_month.8')),
                capitalizeFirstLetter(t('highcharts.short_month.9')),
                capitalizeFirstLetter(t('highcharts.short_month.10')),
                capitalizeFirstLetter(t('highcharts.short_month.11')),
                capitalizeFirstLetter(t('highcharts.short_month.12')),
            ],
            weekdays: [
                capitalizeFirstLetter(t('highcharts.weekday.1')),
                capitalizeFirstLetter(t('highcharts.weekday.2')),
                capitalizeFirstLetter(t('highcharts.weekday.3')),
                capitalizeFirstLetter(t('highcharts.weekday.4')),
                capitalizeFirstLetter(t('highcharts.weekday.5')),
                capitalizeFirstLetter(t('highcharts.weekday.6')),
                capitalizeFirstLetter(t('highcharts.weekday.7')),
            ],
        },
    });

    useEffect(() => {
        const chart = chartComponentRef.current.chart;
        syncTooltip(chart);
    }, []);

    return (
        <HighchartsReact
            ref={chartComponentRef}
            highcharts={Highcharts}
            options={options}
        />
    );
};

export default SyncChart;
