import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CrossInCircle, ArrowLeft } from '../../assets/svg.js';
import _ from 'lodash';
import Loader from '../loader';
import '../settings/settings-tabs/styles.scss';
import ErrorIndicator from '../error-indicator/index.js';
import { capitalizeFirstLetter } from '../../helpers/textFormatters.js';
import { AiOutlinePlus } from 'react-icons/ai';
import SettingsMessage from '../settings-message/index.js';
import { NAVIGATION_PATHS } from '../../constants/navigationPaths.js';

const TeamSelectorForm = ({
    selectedItems,
    allItemsState,
    setFieldTouched,
    setFieldValue,
    fieldName,
    uniqueItemPropertyName,
    sortingItemPropertyName,
    formatValueFunc,
    allItemsColTitle,
    selectedItemsColTitle,
}) => {
    const { t } = useTranslation();

    const [selectedItemsData, setSelectedItemsData] = useState([]);
    const [filter, setFilter] = useState('');
    const [sortedItemsData, setSortedItemsData] = useState([]);
    const [filteredItemsData, setFilteredItemsData] = useState([]);

    useEffect(() => {
        let canceled = false;
        if (allItemsState.data && allItemsState.data.length !== 0) {
            if (!canceled)
                setSelectedItemsData(
                    allItemsState.data.map((item) => {
                        let isOnTeam =
                            selectedItems.findIndex(
                                (itemInTeam) =>
                                    itemInTeam[uniqueItemPropertyName]
                                        .split(' ')
                                        .join('') ===
                                    item[uniqueItemPropertyName]
                            ) + 1;

                        if (isOnTeam) {
                            return {
                                ...item,
                                inTeam: true,
                            };
                        } else {
                            return {
                                ...item,
                                inTeam: false,
                            };
                        }
                    })
                );
        }

        return () => (canceled = true);
    }, [allItemsState.data]);

    useEffect(() => {
        const sortedChecked = selectedItemsData
            .filter((item) => item.inTeam)
            .sort((firstItem, secondItem) => {
                if (
                    firstItem[sortingItemPropertyName].toLowerCase() <
                    secondItem[sortingItemPropertyName].toLowerCase()
                ) {
                    return -1;
                }
                if (
                    firstItem[sortingItemPropertyName].toLowerCase() >
                    secondItem[sortingItemPropertyName].toLowerCase()
                ) {
                    return 1;
                }

                // names must be equal
                return 0;
            });

        const sortedUnchecked = selectedItemsData
            .filter((user) => !user.inTeam)
            .sort((firstItem, secondItem) => {
                if (
                    firstItem[sortingItemPropertyName].toLowerCase() <
                    secondItem[sortingItemPropertyName].toLowerCase()
                ) {
                    return -1;
                }
                if (
                    firstItem[sortingItemPropertyName].toLowerCase() >
                    secondItem[sortingItemPropertyName].toLowerCase()
                ) {
                    return 1;
                }

                // names must be equal
                return 0;
            });

        const sorted = [...sortedChecked, ...sortedUnchecked];
        setSortedItemsData(sorted);
    }, [selectedItemsData]);

    useEffect(() => {
        if (!filter || !filter.trim().length) {
            setFilteredItemsData(sortedItemsData);
        } else {
            const formattedFilter = filter.trim().toLocaleLowerCase();
            const filtered = sortedItemsData.filter(
                (item) =>
                    item[sortingItemPropertyName]
                        .toLowerCase()
                        ?.includes(formattedFilter) ||
                    item[uniqueItemPropertyName]
                        ?.toLowerCase()
                        ?.includes(formattedFilter)
            );
            setFilteredItemsData(filtered);
        }
    }, [sortedItemsData, filter]);

    const setCheck = (hash) => {
        setSelectedItemsData(
            selectedItemsData.map((item) => {
                if (item[uniqueItemPropertyName] === hash) {
                    if (item.inTeam) {
                        setFieldValue(
                            fieldName,
                            selectedItems.filter(
                                (itemHash) =>
                                    itemHash[uniqueItemPropertyName] !== hash
                            )
                        );
                    } else {
                        setFieldValue(fieldName, [...selectedItems, item]);
                    }
                    item.inTeam = !item.inTeam;
                }

                return item;
            })
        );
    };

    const removeItemFromTeam = (hash) => {
        setSelectedItemsData(
            selectedItemsData.map((item) => {
                if (item[uniqueItemPropertyName] === hash) {
                    item.inTeam = false;
                }
                return item;
            })
        );

        setFieldValue(
            fieldName,
            selectedItems.filter(
                (itemHash) => itemHash[uniqueItemPropertyName] !== hash
            )
        );
    };

    const getItemsInTeam = (data) => {
        let teamData = data
            .filter((item) => item.inTeam)
            .sort((firstItem, secondItem) => {
                if (
                    firstItem[sortingItemPropertyName].toLowerCase() <
                    secondItem[sortingItemPropertyName].toLowerCase()
                ) {
                    return -1;
                }
                if (
                    firstItem[sortingItemPropertyName].toLowerCase() >
                    secondItem[sortingItemPropertyName].toLowerCase()
                ) {
                    return 1;
                }

                // names must be equal
                return 0;
            });
        return teamData;
    };

    const createAllItemsList = () => {
        if (allItemsState.error) {
            return <ErrorIndicator error={allItemsState.error} />;
        }
        if (allItemsState.loading) {
            return <Loader />;
        }

        if (allItemsState?.data?.length === selectedItems?.length) {
            return (
                <li className="h-16 flex justify-center items-center">
                    <p className="p-2 text-gray-400">There is nothing here.</p>
                </li>
            );
        }

        return filteredItemsData
            .filter((item) => !item.inTeam)
            .map((filteredItem) => {
                return (
                    <li
                        className="flex justify-between p-2 items-center cursor-pointer hover:bg-gray-400 hover:bg-opacity-10"
                        key={`${filteredItem[uniqueItemPropertyName]}`}
                        onClick={() =>
                            setCheck(filteredItem[uniqueItemPropertyName])
                        }
                    >
                        <div className="flex gap-4 items-center">
                            <p className="">
                                {filteredItem[sortingItemPropertyName]
                                    ? filteredItem[sortingItemPropertyName]
                                    : filteredItem[uniqueItemPropertyName]}
                            </p>
                        </div>
                        <div className="w-6 h-6 rounded-full border border-solid border-gray-400 flex justify-center items-center">
                            <AiOutlinePlus color="#C2C7D7" />
                        </div>
                    </li>
                );
            });
    };

    const createSelectedItemsList = () => {
        if (allItemsState.loading) return <Loader />;

        if (allItemsState.error) return <ErrorIndicator />;

        const itemsInTeam = getItemsInTeam(selectedItemsData);

        if (itemsInTeam.length === 0) {
            return (
                <li
                    className="h-full w-full flex items-center justify-center"
                    key={`no-user`}
                >
                    <p className="text-center text-gray-400 p-8">
                        No entries in team.
                    </p>
                </li>
            );
        } else {
            return itemsInTeam.map((item) => {
                return (
                    <li
                        className="p-2 flex gap-4 items-center justify-between"
                        key={`${item[uniqueItemPropertyName]}`}
                    >
                        <div className="flex gap-4 items-center">
                            <p className="">
                                {item[sortingItemPropertyName]
                                    ? item[sortingItemPropertyName]
                                    : item[uniqueItemPropertyName]}
                            </p>
                        </div>
                        <div className="cursor-pointer">
                            <button
                                className="remove-user"
                                onClick={() =>
                                    removeItemFromTeam(
                                        item[uniqueItemPropertyName]
                                    )
                                }
                            >
                                <CrossInCircle
                                    fill="#EE8282"
                                    transform=""
                                    width={24}
                                />
                            </button>
                        </div>
                    </li>
                );
            });
        }
    };

    return (
        <>
            <div className="flex gap-4 justify-between items-stretch">
                <div className={'w-5/12 flex flex-col'}>
                    <p className="mb-4 font-medium text-xl ">
                        {capitalizeFirstLetter(selectedItemsColTitle)}
                    </p>
                    <ul className="max-h-[460px] rounded border border-solid flex flex-col border-gray-400 divide-y divide-solid divide-gray-400  overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded-full hover:scrollbar-thumb-gray-400 ">
                        {createSelectedItemsList()}
                    </ul>
                </div>
                <ArrowLeft fill={'#484a53'} />

                <div className="w-5/12 flex flex-col">
                    <p className="mb-4 font-medium text-xl ">
                        {capitalizeFirstLetter(allItemsColTitle)}
                    </p>
                    {allItemsState?.length ||
                    getItemsInTeam(selectedItemsData)?.length ? (
                        <>
                            <input
                                className="p-2 placeholder-gray-300 text-gray-700 relative bg-white
             rounded text-md border border-gray-300 outline-none focus:outline-none focus:ring w-full disabled:bg-gray-300 mb-4"
                                id={filter}
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                                placeholder="Filter..."
                            />
                            <ul className="max-h-[460px] rounded border border-solid flex flex-col border-gray-400 divide-y divide-solid divide-gray-400  overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded-full hover:scrollbar-thumb-gray-400">
                                {createAllItemsList()}
                            </ul>
                        </>
                    ) : (
                        <SettingsMessage
                            messageText={t('settingsMessages.no_ticket_feed')}
                            link={NAVIGATION_PATHS.sre}
                            linkText={capitalizeFirstLetter(
                                t('settingsMessages.go_to_sre_settings')
                            )}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default TeamSelectorForm;
