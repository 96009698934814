import { instances } from '../default-setup';

//Teams
export const getTeams = () => instances.get('teams/');

export const getTeamsList = () => instances.get('teams/list/');

export const getTeamKudosLeaderBoard = (team, params) =>
    instances.get(`teams/${team}/leader_board/`, params);

export const getTeamKudosSankeyData = (team, params) =>
    instances.get(`teams/${team}/sankey_diagram/`, params);

export const createTeam = (data) => instances.post('teams/', data);

export const updateTeam = (data, params) =>
    instances.put('teams/', data, params);

export const deleteTeam = (params) => instances.delete('teams/', params);
