import React, { useState, useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    IoCloseCircleOutline,
    IoCheckmarkCircleOutline,
} from 'react-icons/io5';
import { Link, useNavigate } from 'react-router-dom';
import { useTour } from '@reactour/tour';
import ReactGA from 'react-ga4';
import { UserContext } from '../context/UserContext';
import { TimePeriodContext } from '../context/TimePeriodContext';
import { OrganisationsContext } from '../context/OrganisationsContext';
import Loader from '../components/loader';
import NewsFeedDivider from '../components/newsfeed-divider';
import WhitePanelContentWrapper from '../components/white-panel-content-wrapper';
import OverviewInfoBlock from '../components/overview-info-block';
import ErrorIndicator from '../components/error-indicator';
import moment from 'moment';
import userAvatar from '../assets/user-avatar.png';
import { withUserAndOragnisations } from '../helpers/withUser';
import { getOverview, getHighlights } from '../api/overview/OverviewAPI';
import { useDefaultStateWithReducer } from '../helpers/useDefaultStateWithReducer';

import { getGitProviders } from '../api/settings/GitProviderAPI';
import { useFetchDataAndSetState } from '../helpers/useFetchDataAndSetState';
import useParamsQuery from '../hooks/useParamsQuery';
import { getJiraWorkspaces } from '../api/settings/sprintInsightsAPI';
import ModalCreateOrganisation from '../components/modal-create-organisation';
import { getSREBackends } from '../api/settings/sreAPI';
import { NAVIGATION_PATHS } from '../constants/navigationPaths';
import SettingsMessage from '../components/settings-message';
import { getOrgUsers } from '../api/settings/UserAPI';
import { getAdminPermission } from '../helpers/getPermission';
import { capitalizeFirstLetter } from '../helpers/textFormatters';
import ModalWrapper from '../components/modal-wrapper';
import SuggestTourModalContent from '../components/suggest-tour-modal-content';

const OverviewPage = ({ startTourGuide, setStartTourGuide }) => {
    const { setIsOpen } = useTour();
    const { t } = useTranslation();
    const query = useParamsQuery();
    const navigate = useNavigate();
    const { userState } = useContext(UserContext);
    const { first_name, last_name, photo } = userState.data;
    const [timePeriodState, timePeriodDispatch] = useContext(TimePeriodContext);
    const { organisationsState, organisationsDispatch } =
        useContext(OrganisationsContext);
    const selectedOrganisationHash = organisationsState?.data
        ? organisationsState.data.find((org) => org.active)?.org_hash
        : null;

    const demoOrgHash = process.env.REACT_APP_DEMO_ORG_HASH;
    const isDemoOrgOnly = () => {
        return (
            organisationsState.data.length === 1 &&
            organisationsState.data[0].org_hash === demoOrgHash
        );
    };

    const [newsState, newsDispatch] = useDefaultStateWithReducer();
    const { data, loading, error } = newsState;
    const [highlightsState, highlightsDispatch] = useDefaultStateWithReducer();

    const providersRequest = useCallback(() => getGitProviders(), []);
    const [gitProvidersState] = useFetchDataAndSetState(providersRequest, [
        selectedOrganisationHash,
    ]);
    const workspacesRequest = useCallback(() => getJiraWorkspaces(), []);
    const [workspacesState] = useFetchDataAndSetState(workspacesRequest, [
        selectedOrganisationHash,
    ]);
    const backendsRequest = useCallback(() => getSREBackends(), []);
    const [backendsState] = useFetchDataAndSetState(backendsRequest, [
        selectedOrganisationHash,
    ]);

    const orgUsersRequest = useCallback(() => getOrgUsers(), []);
    const [orgUsersState] = useFetchDataAndSetState(orgUsersRequest, [
        selectedOrganisationHash,
    ]);

    const [greeting, setGreeting] = useState('Welcome back');
    const [isCreateOrgModalOpen, setIsCreateOrgModalOpen] = useState(false);
    const [isDemoModalOpen, setIsDemoModalOpen] = useState(false);

    useEffect(() => {
        difineDaytimeforGreeting();

        if (localStorage.getItem('newOrg') === 'true') {
            setIsDemoModalOpen(true);
            localStorage.removeItem('newOrg');
        }

        if (
            localStorage.getItem('firstDemoSession') === 'true' &&
            selectedOrganisationHash === demoOrgHash
        ) {
            setIsOpen(true);
            setStartTourGuide(true);
            ReactGA.event('start_demo_tour');
        }
        localStorage.removeItem('firstDemoSession');
        localStorage.getItem('firstSignupSession') === 'true' &&
            selectedOrganisationHash === demoOrgHash &&
            setIsCreateOrgModalOpen(true);
        localStorage.removeItem('firstSignupSession');
        // query.get('create') === 'true' && setIsCreateOrgModalOpen(true);
        return () => {
            setIsOpen(false);
            setStartTourGuide(false);
            setIsCreateOrgModalOpen(false);
        };
    }, []);

    useEffect(() => {
        query.get('create') === 'true' &&
            isDemoOrgOnly() &&
            setIsCreateOrgModalOpen(true);

        query.get('create') === 'true' && setIsOpen(false);
    }, [query]);

    useEffect(() => {
        startTourGuide && setIsOpen(true);
    }, [startTourGuide]);

    useEffect(() => {
        let canceled = false;
        if (!canceled) newsDispatch({ type: 'SET_LOADING' });
        if (!canceled) highlightsDispatch({ type: 'SET_LOADING' });
        const { date_start, date_end } = timePeriodState;
        const params = {
            params: { date_start, date_end },
        };
        // fetching Highlights
        getHighlights(params)
            .then((response) => {
                if (!canceled)
                    highlightsDispatch({
                        type: 'SET_DATA',
                        payload: response.data,
                    });
            })
            .catch((error) => {
                if (!canceled)
                    highlightsDispatch({ type: 'SET_ERROR', payload: error });
            });

        // fetching news feed
        getOverview(params)
            .then((response) => {
                if (!canceled)
                    newsDispatch({ type: 'SET_DATA', payload: response.data });
            })
            .catch((error) => {
                if (!canceled)
                    newsDispatch({ type: 'SET_ERROR', payload: error });
            });

        return () => (canceled = true);
    }, [
        selectedOrganisationHash,
        timePeriodState.date_start,
        timePeriodState.date_end,
    ]);

    function renderProfileDataItem(
        condition,
        link,
        addedTextPath,
        notAddedTextPath
    ) {
        if (
            selectedOrganisationHash === demoOrgHash &&
            link !== '/user-settings'
        ) {
            return (
                <li className="flex gap-x-3	items-center mb-2.5">
                    <IoCheckmarkCircleOutline size={18} color={'#1FB8D3'} />
                    <p className={`text-sm text-theme-tertiary`}>
                        {addedTextPath}
                    </p>
                </li>
            );
        }
        return (
            <li className="flex gap-x-3	items-center mb-2.5">
                {condition ? (
                    <IoCheckmarkCircleOutline size={18} color={'#1FB8D3'} />
                ) : (
                    <IoCloseCircleOutline size={18} color={'#919298'} />
                )}
                <Link
                    to={link}
                    className={`text-sm  ${
                        condition ? 'text-theme-tertiary' : 'text-gray-500'
                    } `}
                >
                    {condition ? addedTextPath : notAddedTextPath}
                </Link>
            </li>
        );
    }

    function difineDaytimeforGreeting() {
        const now = moment().hours();
        if (now < 4) {
            setGreeting(t('overview_page.good_night'));
            return;
        }
        if (now < 12) {
            setGreeting(t('overview_page.good_morning'));
            return;
        }
        if (now < 17) {
            setGreeting(t('overview_page.good_afternoon'));
            return;
        }
        if (now <= 23) {
            setGreeting(t('overview_page.good_evening'));
            return;
        }
    }

    async function handleTourStart() {
        setIsDemoModalOpen(false);
        const orgList = organisationsState?.data?.map((org) => {
            if (org.org_hash === demoOrgHash) {
                return {
                    ...org,
                    active: true,
                };
            }
            return { ...org, active: false };
        });
        const timePeriod = {
            date_start: moment()
                .set({ hours: 0, minutes: 0, seconds: 0 })
                .subtract(3, 'months')
                .unix(),
            date_end: moment()
                .set({ hours: 23, minutes: 59, seconds: 59 })
                .unix(),
            buttonValue: t('header.time_period.last_3_months'),
        };
        try {
            await organisationsDispatch({
                type: 'SET_DATA',
                payload: orgList,
            });
            window.localStorage.setItem('orgHash', demoOrgHash);
            await timePeriodDispatch({
                type: 'SET_TIME_RANGE',
                payload: timePeriod,
            });
            setIsOpen(true);
            setStartTourGuide(true);
            ReactGA.event('start_demo_tour');
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="container mx-auto ">
            {!orgUsersState.loading &&
            orgUsersState?.data?.length &&
            orgUsersState.data.length < 2 &&
            getAdminPermission(organisationsState?.data) ? (
                <SettingsMessage
                    messageText={t('settingsMessages.only_user')}
                    link={NAVIGATION_PATHS.settingsUsers}
                    linkText={t('settingsMessages.go_to_users_settings')}
                />
            ) : null}

            <div className="flex gap-x-20">
                <div className="w-3/4">
                    <NewsFeedDivider
                        data-tour="r-step-page"
                        text={`${greeting}${
                            first_name ? ' ' + first_name : ''
                        }${last_name ? ' ' + last_name : ''}, ${t(
                            'overview_page.get_started_right_away'
                        )}`}
                    />
                    <WhitePanelContentWrapper className="mt-5 mb-10">
                        <h3 className="font-display text-gray-700 font-bold text-lg	leading-5">
                            {t('overview_page.latest_highlights.title')} (
                            {moment(timePeriodState.date_start * 1000).format(
                                'D MMM YYYY'
                            )}{' '}
                            -{' '}
                            {moment(timePeriodState.date_end * 1000).format(
                                'D MMM YYYY'
                            )}
                            )
                        </h3>
                        {highlightsState.loading && (
                            <div className="mt-5 mb-10 text-center">
                                <Loader
                                    color={'#C2C7D7'}
                                    size={35}
                                    speedMultiplier={0.8}
                                />
                            </div>
                        )}
                        {highlightsState.error && (
                            <div className="mt-5 mb-10 text-center">
                                <ErrorIndicator error={highlightsState.error} />
                            </div>
                        )}
                        {highlightsState.data &&
                            !highlightsState.loading &&
                            !highlightsState.error && (
                                <ul className="mb-8 list-disc list-inside text-gray-500 text-sm leading-6">
                                    <li className="">
                                        <span className="font-bold">
                                            {
                                                highlightsState.data
                                                    .updated_jira_tickets
                                            }{' '}
                                            {t(
                                                'overview_page.latest_highlights.updated_tickets'
                                            )}
                                        </span>{' '}
                                        in Jira
                                    </li>
                                    <li className="">
                                        <span className="font-bold">
                                            {highlightsState.data.new_kudos}{' '}
                                            {t(
                                                'overview_page.latest_highlights.new_kudos'
                                            )}
                                        </span>{' '}
                                    </li>
                                    <li className="">
                                        <span className="font-bold">
                                            {
                                                highlightsState.data
                                                    .new_security_issues
                                            }{' '}
                                            {t(
                                                'overview_page.latest_highlights.new_issues'
                                            )}
                                        </span>{' '}
                                        {t(
                                            'overview_page.latest_highlights.found_in_leaks'
                                        )}
                                    </li>
                                </ul>
                            )}
                    </WhitePanelContentWrapper>
                    <NewsFeedDivider text={'Updates'} />
                    {newsState && loading && (
                        <div className="mt-5 mb-10 text-center">
                            <Loader
                                color={'#C2C7D7'}
                                size={35}
                                speedMultiplier={0.8}
                            />
                        </div>
                    )}
                    {newsState && error && (
                        <div className="mt-5 mb-10 text-center">
                            <ErrorIndicator error={error} />
                        </div>
                    )}
                    {newsState &&
                        data &&
                        data.map((item, index) => (
                            <WhitePanelContentWrapper
                                key={index}
                                className="mt-5 mb-10"
                            >
                                <OverviewInfoBlock data={item} />
                            </WhitePanelContentWrapper>
                        ))}
                </div>
                <aside className="w-1/4">
                    <WhitePanelContentWrapper className="mt-5 mb-10">
                        <div className="flex gap-x-2.5	items-center mb-3.5">
                            <img
                                src={photo !== '' ? photo : userAvatar}
                                alt="User Avatar"
                                className="w-50 h-50 rounded-full"
                            />
                            <div className="">
                                <h2 className="font-display text-gray-700 font-bold	text-lg	leading-5">
                                    {t('overview_page.profile_box.profile')}
                                </h2>
                                <p className="text-gray-500 leading-6">
                                    {first_name &&
                                    last_name &&
                                    photo &&
                                    workspacesState.data?.length > 0 &&
                                    gitProvidersState.data?.length > 0
                                        ? `${first_name} ${last_name}`
                                        : t(
                                              'overview_page.profile_box.make_complete'
                                          )}
                                </p>
                            </div>
                        </div>
                        {workspacesState.loading ||
                        gitProvidersState.loading ? (
                            <div className=" text-center">
                                <Loader
                                    color={'#C2C7D7'}
                                    size={35}
                                    speedMultiplier={0.8}
                                />
                            </div>
                        ) : (
                            <ul className="">
                                {renderProfileDataItem(
                                    photo,
                                    '/user-settings',
                                    t(
                                        'overview_page.profile_box.profile_photo'
                                    ),
                                    t(
                                        'overview_page.profile_box.no_profile_photo'
                                    )
                                )}
                                {renderProfileDataItem(
                                    first_name && last_name,
                                    '/user-settings',
                                    t(
                                        'overview_page.profile_box.personal_data'
                                    ),
                                    t(
                                        'overview_page.profile_box.no_personal_data'
                                    )
                                )}
                                {renderProfileDataItem(
                                    workspacesState.data?.length > 0,
                                    '/settings/sprint-insights',
                                    t(
                                        'overview_page.profile_box.ticket_feed_added'
                                    ),
                                    t(
                                        'overview_page.profile_box.ticket_feed_not_added'
                                    )
                                )}
                                {renderProfileDataItem(
                                    gitProvidersState.data?.length > 0,
                                    '/settings/vcs',
                                    t(
                                        'overview_page.profile_box.git_provider_added'
                                    ),
                                    t(
                                        'overview_page.profile_box.git_provider_not_added'
                                    )
                                )}
                                {renderProfileDataItem(
                                    gitProvidersState.data?.length > 0,
                                    NAVIGATION_PATHS.settingsErrorBudgets,
                                    t(
                                        'overview_page.profile_box.backend_added'
                                    ),
                                    t(
                                        'overview_page.profile_box.backend_not_added'
                                    )
                                )}
                            </ul>
                        )}
                    </WhitePanelContentWrapper>
                    {/*  Adds create organisation panel (for demo users with no organisation - abandoned) */}
                    {selectedOrganisationHash === demoOrgHash &&
                    isDemoOrgOnly() ? (
                        <WhitePanelContentWrapper className="mb-8 bg-theme-tertiary">
                            <h3 className="text-white font-display font-bold text-xl">
                                Do you want to work with your own organisation
                                data?
                            </h3>
                            <p className="text-white text-base">
                                It's easy to setup and you can start for free.
                                Let's go to the organisation settings.
                            </p>
                            <div className="flex justify-around">
                                <button
                                    className="mx-auto relative  my-8 cursor-pointer text-center flex items-center justify-center py-3 px-3 border border-solid border-white rounded  text-white hover:bg-white hover:text-theme-tertiary"
                                    onClick={() =>
                                        setIsCreateOrgModalOpen(true)
                                    }
                                >
                                    Get started
                                </button>
                            </div>
                        </WhitePanelContentWrapper>
                    ) : null}
                </aside>
            </div>
            <ModalCreateOrganisation
                modalState={isCreateOrgModalOpen}
                setModalState={setIsCreateOrgModalOpen}
                sendRequest={() => {}}
                onClose={() =>
                    navigate({ pathname: NAVIGATION_PATHS.overview })
                }
            />
            <ModalWrapper
                modalState={isDemoModalOpen}
                setModalState={setIsDemoModalOpen}
                title="Welcome to Agile Analytics!"
                height={240}
            >
                <SuggestTourModalContent sendRequest={handleTourStart} />
            </ModalWrapper>
        </div>
    );
};

export default withUserAndOragnisations(OverviewPage);
