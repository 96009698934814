import React, { useContext, useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { BiFilterAlt } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import Loader from '../loader';
import { TimePeriodContext } from '../../context/TimePeriodContext';
import { useFetchDataAndSetState } from '../../helpers/useFetchDataAndSetState';
import DoraMetricsChart from '../dora-metrics-chart';
import PanelTitle from '../panel-title';
import WhitePanelContentWrapper from '../white-panel-content-wrapper';
import {
    getDeploymentFrequencyData,
    getLeadTimeData,
} from '../../api/dora/DoraAPI';
import DoraMetricsFilter from '../dora-metrics-filter';
import { capitalizeFirstLetter } from '../../helpers/textFormatters';

const DoraMetricsPanel = ({
    allRepos,
    reposLoading,
    orgHash,
    isInCard,
    cardTimeperiod,
}) => {
    const { t } = useTranslation();
    const [timePeriodState] = useContext(TimePeriodContext);
    const { date_end, date_start } = timePeriodState;
    const params = cardTimeperiod
        ? {
              params: {
                  date_start: cardTimeperiod.start,
                  date_end: cardTimeperiod.end,
              },
          }
        : {
              params: { date_start, date_end },
          };
    const [timeperiodByDays, setTimeperiodByDays] = useState([]);
    const demoOrgHash = process.env.REACT_APP_DEMO_ORG_HASH;
    const [update, setUpdate] = useState(0);

    useEffect(() => {
        let timestampsByDays = [];
        if (cardTimeperiod) {
            let dayStartTimestamp = cardTimeperiod.start * 1000;
            let dayEndTimestamp = +moment(dayStartTimestamp)
                .add(1, 'days')
                .subtract(1, 'seconds')
                .format('x');

            while (dayEndTimestamp < cardTimeperiod.end * 1000) {
                timestampsByDays.push({
                    start: dayStartTimestamp,
                    end: dayEndTimestamp,
                });
                dayStartTimestamp = +moment(dayStartTimestamp)
                    .add(1, 'days')
                    .format('x');
                dayEndTimestamp = +moment(dayEndTimestamp)
                    .add(1, 'days')
                    .format('x');
            }

            timestampsByDays.push({
                start: dayStartTimestamp,
                end: dayEndTimestamp,
            });

            setTimeperiodByDays(timestampsByDays);
        } else if (date_start && date_end) {
            let dayStartTimestamp = date_start * 1000;
            let dayEndTimestamp = +moment(dayStartTimestamp)
                .add(1, 'days')
                .subtract(1, 'seconds')
                .format('x');

            while (dayEndTimestamp < date_end * 1000) {
                timestampsByDays.push({
                    start: dayStartTimestamp,
                    end: dayEndTimestamp,
                });
                dayStartTimestamp = +moment(dayStartTimestamp)
                    .add(1, 'days')
                    .format('x');
                dayEndTimestamp = +moment(dayEndTimestamp)
                    .add(1, 'days')
                    .format('x');
            }

            timestampsByDays.push({
                start: dayStartTimestamp,
                end: dayEndTimestamp,
            });

            setTimeperiodByDays(timestampsByDays);
        }
    }, [date_start, date_end, cardTimeperiod]);

    // FILTER
    const [areFiltersOpen, setAreFiltersOpen] = useState(!isInCard);
    const [repositoriesFilter, setRepositoriesFilter] = useState([]);

    // DEPLOYMENT FREQUENCY: GET, FORMAT AND FILTER DATA
    const deploymentFreqRequest = useCallback(
        () => getDeploymentFrequencyData(params),
        [date_end, date_start, cardTimeperiod]
    );
    const [deploymentFreqState] = useFetchDataAndSetState(
        deploymentFreqRequest,
        [orgHash, date_end, date_start, cardTimeperiod]
    );
    const [filteredDeploymentFreqData, setFilteredDeploymentFreqData] =
        useState([]);
    const [formattedDeploymentFreqData, setFormattedDeploymentFreqData] =
        useState([]);
    const [
        formattedDeploymentFreqSuccessData,
        setFormattedDeploymentFreqSuccessData,
    ] = useState([]);
    const [
        formattedDeploymentFreqFailedData,
        setFormattedDeploymentFreqFailedData,
    ] = useState([]);
    const [averageDeploymentFreq, setAverageDeploymentFreq] = useState(null);

    const chartsDeploymentFreq = [
        {
            title: {
                label: capitalizeFirstLetter(t('repo_page.dora.df')),
                value: 'deployment-frequency',
            },
            averageMeasure: `p/${t('repo_page.dora.day')}`,
            averageValue: averageDeploymentFreq,
            series: [
                {
                    name: capitalizeFirstLetter(
                        t('repo_page.dora.successful_deployments')
                    ),
                    data: formattedDeploymentFreqSuccessData
                        ? formattedDeploymentFreqSuccessData
                        : [],
                },
                {
                    name: capitalizeFirstLetter(
                        t('repo_page.dora.failed_deployments')
                    ),
                    data: formattedDeploymentFreqFailedData
                        ? formattedDeploymentFreqFailedData
                        : [],
                },
            ],
        },
    ];

    useEffect(() => {
        if (deploymentFreqState?.data?.length) {
            const filteredData = filterDeploymentFreq(deploymentFreqState.data);
            setFilteredDeploymentFreqData(filteredData);
        } else {
            setFilteredDeploymentFreqData([]);
        }
    }, [deploymentFreqState.data, repositoriesFilter]);

    useEffect(() => {
        if (filteredDeploymentFreqData?.length) {
            setFormattedDeploymentFreqSuccessData(
                formatDeploymentFreq('success')
            );
            setFormattedDeploymentFreqFailedData(
                formatDeploymentFreq('failed')
            );
            setFormattedDeploymentFreqData(formatDeploymentFreq());
        } else {
            setFormattedDeploymentFreqSuccessData([]);
            setFormattedDeploymentFreqFailedData([]);
            setFormattedDeploymentFreqData([]);
        }
    }, [filteredDeploymentFreqData]);

    useEffect(() => {
        if (formattedDeploymentFreqData?.length) {
            const totalDeployments = formattedDeploymentFreqData.reduce(
                (acc, item, i) =>
                    formattedDeploymentFreqData.length - 1 !== i
                        ? acc + item[1]
                        : acc,
                0
            );
            const avgDeployments = (
                totalDeployments / timeperiodByDays.length
            ).toFixed(2);
            setAverageDeploymentFreq(avgDeployments);
        } else {
            setAverageDeploymentFreq(null);
        }
    }, [formattedDeploymentFreqData]);

    function filterDeploymentFreq(data) {
        const filteredData = data.filter((deployment) => {
            return repositoriesFilter.find(
                (repo) =>
                    repo.isSelected &&
                    repo.url.includes(
                        deployment?.repository?.replace('git@gitlab.com:', '')
                    )
            );
        });
        return filteredData;
    }

    function formatDeploymentFreq(status) {
        return timeperiodByDays.reduce((acc, day, i) => {
            let deployments = filteredDeploymentFreqData.filter(
                (deployment) =>
                    deployment.timestamp * 1000 >= day.start &&
                    deployment.timestamp * 1000 <= day.end
            );

            if (status) {
                deployments = deployments.filter(
                    (deployment) => deployment.status === status
                );
            }

            if (i === timeperiodByDays?.length - 1) {
                return [
                    ...acc,
                    [day.start, deployments.length],
                    [day.end, deployments.length],
                ];
            } else {
                return [...acc, [day.start, deployments.length]];
            }
        }, []);
    }

    // =========== CYCLE TIME (+ LEAD TIME, + LEADTIME FOR CHANGES): GET, FORMAT AND FILTER DATA ======
    // getLeadTimeData API REQUEST
    const leadTimeRequest = useCallback(
        () => getLeadTimeData(params),
        [date_end, date_start, cardTimeperiod]
    );
    const [leadTimeState] = useFetchDataAndSetState(leadTimeRequest, [
        orgHash,
        cardTimeperiod ? cardTimeperiod.end : date_end,
        cardTimeperiod ? cardTimeperiod.start : date_start,
    ]);

    const [filteredLeadTimeData, setFilteredLeadTimeData] = useState([]);
    const [formattedLeadTimeData, setFormattedLeadTimeData] = useState([]);
    const [ticketKeys, setTicketKeys] = useState([]);
    const [formattedLeadTimeForChangeData, setFormattedLeadTimeForChangeData] =
        useState([]);
    const [formattedCycleTimeData, setFormattedCycleTimeData] = useState([]);
    const [averageCycleTimeChartData, setAverageCycleTimeChartData] = useState(
        []
    );
    const [averageLeadTimeChartData, setAverageLeadTimeChartData] = useState(
        []
    );
    const [
        averageLeadTimeForChangeChartData,
        setAverageLeadTimeForChangeChartData,
    ] = useState([]);
    const [averageCycleTime, setAverageCycleTime] = useState(null);
    const chartsLeadTime = [
        {
            title: {
                value: 'cycle-time',
                label: capitalizeFirstLetter(t('repo_page.dora.ct')),
            },
            description:
                'Measures the time difference between the starting time of implementing a requirement and when the changes are delivered to production.',
            averageMeasure: '',
            averageValue: averageCycleTime?.cycleTime,
            series: [
                {
                    name: `${capitalizeFirstLetter(
                        t('repo_page.dora.deployments')
                    )} ${capitalizeFirstLetter(t('repo_page.dora.ct'))}`,
                    type: 'scatter',
                    data: formattedCycleTimeData?.length
                        ? formattedCycleTimeData
                        : [],
                },

                {
                    name: capitalizeFirstLetter(t('repo_page.dora.avg')),
                    type: 'line',
                    data: averageCycleTimeChartData?.length
                        ? averageCycleTimeChartData
                        : [],
                },
            ],
        },
        {
            title: {
                value: 'lead-time',
                label: capitalizeFirstLetter(t('repo_page.dora.lt')),
            },
            description:
                'Measures the time difference between the time a requirement is created and when the changes are delivered to production.',
            averageMeasure: `/${t('repo_page.dora.day')}`,
            averageValue: averageCycleTime?.leadTime,
            series: [
                {
                    name: `${capitalizeFirstLetter(
                        t('repo_page.dora.deployments')
                    )} ${capitalizeFirstLetter(t('repo_page.dora.lt'))}`,
                    type: 'scatter',
                    data: formattedLeadTimeData.length
                        ? formattedLeadTimeData
                        : [],
                },

                {
                    name: capitalizeFirstLetter(t('repo_page.dora.avg')),
                    type: 'line',
                    data: averageLeadTimeChartData.length
                        ? averageLeadTimeChartData
                        : [],
                },
            ],
        },
        {
            title: {
                value: 'lead-time-for-change',
                label: capitalizeFirstLetter(t('repo_page.dora.ltfc')),
            },
            description:
                'Measures the amount of time it takes a commit to get into production.',
            averageMeasure: `/${t('repo_page.dora.day')}`,
            averageValue: averageCycleTime?.leadTimeForChange,
            series: [
                {
                    name: `${capitalizeFirstLetter(
                        t('repo_page.dora.deployments')
                    )} ${capitalizeFirstLetter(t('repo_page.dora.ltfc'))}`,
                    type: 'scatter',
                    data: formattedLeadTimeForChangeData.length
                        ? formattedLeadTimeForChangeData
                        : [],
                },

                {
                    name: capitalizeFirstLetter(t('repo_page.dora.avg')),
                    type: 'line',
                    data: averageLeadTimeForChangeChartData.length
                        ? averageLeadTimeForChangeChartData
                        : [],
                },
            ],
        },
    ];

    function generateMockData(length = 50) {
        return new Array(length)
            .fill([])
            .map(() => [
                cardTimeperiod
                    ? +Math.floor(
                          Math.random() *
                              (cardTimeperiod.end - cardTimeperiod.start + 1) +
                              cardTimeperiod.start
                      ) * 1000
                    : +Math.floor(
                          Math.random() * (date_end - date_start + 1) +
                              date_start
                      ) * 1000,
                +Math.floor(Math.random() * (1000000 + 1)) * 1000,
            ]);
    }

    function generateMockLeadTimeData(cycleTimeData) {
        return cycleTimeData.map((item) => [
            item[0],
            +Math.floor(item[1] * +Math.floor(Math.random() * 10 + 1)),
        ]);
    }

    function generateMockLeadTimeForChangeData(cycleTimeData) {
        return cycleTimeData.map((item) => [
            item[0],
            +Math.floor(
                item[1] * +(Math.random() * (0.001 - 0.00001 + 1) + 0.00001)
            ),
        ]);
    }

    function generateDemoLeadTimeCharts() {
        return [
            {
                title: {
                    value: 'cycle-time',
                    label: capitalizeFirstLetter(t('repo_page.dora.ct')),
                },
                description:
                    'Measures the time difference between the starting time of implementing a requirement and when the changes are delivered to production.',
                averageMeasure: '',
                averageValue: averageCycleTime?.cycleTime,
                series: [
                    {
                        name: `${capitalizeFirstLetter(
                            t('repo_page.dora.deployments')
                        )} ${capitalizeFirstLetter(t('repo_page.dora.ct'))}`,
                        type: 'scatter',
                        data: generateMockData(),
                    },

                    {
                        name: capitalizeFirstLetter(t('repo_page.dora.avg')),
                        type: 'line',
                        data: generateAverageChart(generateMockData()),
                    },
                ],
            },
            {
                title: {
                    value: 'lead-time',
                    label: capitalizeFirstLetter(t('repo_page.dora.lt')),
                },
                description:
                    'Measures the time difference between the time a requirement is created and when the changes are delivered to production.',
                averageMeasure: `/${t('repo_page.dora.day')}`,
                averageValue: averageCycleTime?.leadTime,
                series: [
                    {
                        name: `${capitalizeFirstLetter(
                            t('repo_page.dora.deployments')
                        )} ${capitalizeFirstLetter(t('repo_page.dora.lt'))}`,
                        type: 'scatter',
                        data: generateMockLeadTimeData(generateMockData()),
                    },

                    {
                        name: capitalizeFirstLetter(t('repo_page.dora.avg')),
                        type: 'line',
                        data: generateAverageChart(
                            generateMockLeadTimeData(generateMockData())
                        ),
                    },
                ],
            },
            {
                title: {
                    value: 'lead-time-for-change',
                    label: capitalizeFirstLetter(t('repo_page.dora.ltfc')),
                },
                description:
                    'Measures the amount of time it takes a commit to get into production.',
                averageMeasure: `/${t('repo_page.dora.day')}`,
                averageValue: averageCycleTime?.leadTimeForChange,
                series: [
                    {
                        name: `${capitalizeFirstLetter(
                            t('repo_page.dora.deployments')
                        )} ${capitalizeFirstLetter(t('repo_page.dora.ltfc'))}`,
                        type: 'scatter',
                        data: generateMockLeadTimeForChangeData(
                            generateMockData()
                        ),
                    },

                    {
                        name: capitalizeFirstLetter(t('repo_page.dora.avg')),
                        type: 'line',
                        data: generateAverageChart(
                            generateMockLeadTimeForChangeData(
                                generateMockData()
                            )
                        ),
                    },
                ],
            },
        ];
    }

    // FILTER API RESPONCE
    useEffect(() => {
        if (leadTimeState?.data?.length) {
            const filteredData = filterDeploymentFreq(leadTimeState.data);
            setFilteredLeadTimeData(filteredData);
        } else {
            setFilteredLeadTimeData([]);
        }
    }, [leadTimeState?.data, repositoriesFilter]);

    // FORMAT FILTERED  API RESPONSE
    useEffect(() => {
        if (filteredLeadTimeData?.length) {
            setFormattedLeadTimeData(formatLeadTimeData('lead_time'));
            setFormattedCycleTimeData(formatLeadTimeData('cycle_time'));
            setFormattedLeadTimeForChangeData(
                formatLeadTimeData('lead_time_for_changes')
            );
            setTicketKeys(filteredLeadTimeData.map((item) => item.key));
        } else {
            setFormattedLeadTimeData([]);
            setFormattedCycleTimeData([]);
            setFormattedLeadTimeForChangeData([]);
            setTicketKeys([]);
        }
    }, [filteredLeadTimeData, update]);

    // SET AVERAGE
    useEffect(() => {
        // calculate average number per timepareiod
        let avgCycleTime = null;
        let avgLeadTime = null;
        let avgLeadTimeForChange = null;

        const totalCycleTime = formattedCycleTimeData?.length
            ? formattedCycleTimeData.reduce((acc, item) => acc + item[1], 0)
            : null;
        const totalLeadTime = formattedLeadTimeData?.length
            ? formattedLeadTimeData.reduce((acc, item) => acc + item[1], 0)
            : null;

        const totalLeadTimeForChange = formattedLeadTimeForChangeData?.length
            ? formattedLeadTimeForChangeData.reduce(
                  (acc, item) => acc + item[1],
                  0
              )
            : null;

        if (totalCycleTime) {
            avgCycleTime = formatChartAxisTime(
                totalCycleTime / formattedCycleTimeData.length
            );
        }
        if (totalLeadTime) {
            avgLeadTime = formatChartAxisTime(
                totalLeadTime / formattedLeadTimeData.length
            );
        }
        if (totalLeadTimeForChange) {
            avgLeadTimeForChange = formatChartAxisTime(
                totalLeadTimeForChange / formattedLeadTimeForChangeData.length
            );
        }

        setAverageCycleTime({
            cycleTime: avgCycleTime,
            leadTime: avgLeadTime,
            leadTimeForChange: avgLeadTimeForChange,
        });

        // calculate average chart data (avarage for every day in the timeperiod)
        setAverageCycleTimeChartData(
            generateAverageChart(formattedCycleTimeData)
        );
        setAverageLeadTimeChartData(
            generateAverageChart(formattedLeadTimeData)
        );
        setAverageLeadTimeForChangeChartData(
            generateAverageChart(formattedLeadTimeForChangeData)
        );
    }, [formattedLeadTimeData]);

    function formatLeadTimeData(propertyKey) {
        return filteredLeadTimeData.map((deployment) => [
            deployment.timestamp * 1000,
            deployment[propertyKey] * 1000, // the value comes in seconds, convert to milliseconds
        ]);
    }

    function generateAverageChart(formattedData) {
        return timeperiodByDays.reduce((acc, day, i) => {
            const dayDeployments = formattedData.filter(
                (deployment) =>
                    deployment[0] >= day.start && deployment[0] <= day.end
            );

            const dayAverage = dayDeployments?.length
                ? dayDeployments.reduce((acc, day) => acc + day[1], 0) /
                  dayDeployments?.length
                : null;

            if (!dayAverage) {
                if (i === timeperiodByDays?.length - 1 && acc?.length) {
                    return [...acc, [day.end, acc[acc.length - 1][1]]];
                } else {
                    return acc;
                }
            } else {
                if (!acc?.length) {
                    return [
                        ...acc,
                        [timeperiodByDays[0].start, dayAverage],
                        [day.end, dayAverage],
                    ];
                } else {
                    return [...acc, [day.end, dayAverage]];
                }
            }
        }, []);
    }

    // =========== MTTR: GET, FORMAT AND FILTER DATA ======
    const mttrMock = [
        [1657836000000, 3],
        [1657922400000, 1],
        [1658008800000, 0.5],
        [1658095200000, 0.2],
        [1658181600000, 0.8],
        [1658268000000, 1],
        [1658354400000, 2],
    ];

    const [mttrData, setMttrData] = useState([]);
    const [filteredMttrData, setFilteredMttrData] = useState([]);
    const [formattedMttrData, setFormattedMttrData] = useState(mttrMock); // should be [] without mock
    const [averageMttr, setAverageMttr] = useState(null);

    useEffect(() => {
        if (formattedDeploymentFreqData?.length) {
            const data = formattedDeploymentFreqData.map((day) => [
                day[0],
                +(Math.random() * (10 - 0 + 1)).toFixed(1),
            ]);

            setFormattedMttrData([...data]);
        } else {
            setFormattedMttrData([]);
        }
    }, [formattedDeploymentFreqData]);

    // SET AVERAGE
    useEffect(() => {
        if (formattedMttrData?.length) {
            const totalMttr = formattedMttrData.reduce((acc, item, i) => {
                if (i === formattedMttrData.length - 1) {
                    return acc;
                } else {
                    return acc + item[1];
                }
            }, 0);
            const avgMttr = (
                totalMttr /
                (formattedMttrData.length - 1)
            ).toFixed(2);
            setAverageMttr(avgMttr);
        } else {
            setAverageMttr(null);
        }
    }, [formattedMttrData]);

    // =========== CHANGE FAILURE RATE: GET, FORMAT AND FILTER DATA ======
    const failureRateMock = [
        [1657836000000, 10],
        [1657922400000, 7],
        [1658008800000, 2],
        [1658095200000, 1],
        [1658181600000, 15],
        [1658268000000, 10],
        [1658354400000, 2],
    ];

    const [failureRateData, setFailureRateData] = useState([]);
    const [filteredFailureRate, setFilteredFailureRaterData] = useState([]);
    const [formattedFailureRateData, setFormattedFailureRaterData] =
        useState(failureRateMock); // should be [] without mock
    const [averageFailureRate, setAverageFailureRate] = useState(null);

    useEffect(() => {
        if (formattedDeploymentFreqData?.length) {
            const data = formattedDeploymentFreqData.map((day) => [
                day[0],
                +(Math.random() * (20 - 0 + 1)).toFixed(1),
            ]);

            setFormattedFailureRaterData([
                ...data,
                [
                    timeperiodByDays[timeperiodByDays.length - 1].end,
                    data[data.length - 1][1],
                ],
            ]);
        } else {
            setFormattedFailureRaterData([]);
        }
    }, [formattedDeploymentFreqData]);

    // SET AVERAGE
    useEffect(() => {
        if (formattedFailureRateData?.length) {
            const totalFailureRate = formattedFailureRateData.reduce(
                (acc, item, i) => {
                    if (i === formattedFailureRateData.length - 1) {
                        return acc;
                    } else {
                        return acc + item[1];
                    }
                },
                0
            );
            const avgFailureRate = (
                totalFailureRate /
                (formattedFailureRateData.length - 1)
            ).toFixed(2);
            setAverageFailureRate(avgFailureRate);
        } else {
            setAverageFailureRate(null);
        }
    }, [formattedFailureRateData]);

    //formatting All repositories for the filter (adding isSelected: true/false)
    useEffect(() => {
        if (allRepos?.length) {
            const formatted = allRepos.reduce((acc, item) => {
                const group = item.provider_id;
                const options = item?.repositories?.map((repository) => {
                    return {
                        ...repository,
                        group: group,
                        isSelected: repository?.webhook ?? false,
                    };
                });
                return [...acc, ...options];
            }, []);

            setRepositoriesFilter(formatted);
        } else {
            setRepositoriesFilter([]);
        }
    }, [allRepos]);

    function toggleFilters() {
        setAreFiltersOpen(!areFiltersOpen);
    }

    function renderFilterButton() {
        return (
            <div
                className="w-6 h-6 rounded bg-tertiary-400 flex justify-center items-center cursor-pointer"
                onClick={() => toggleFilters()}
            >
                {' '}
                <BiFilterAlt size={'20'} color="#fff" />{' '}
            </div>
        );
    }

    function formatChartAxisTime(value) {
        const valueDuration = moment.duration(value);
        let formattedValue = '0';

        if (value !== 0) {
            if (valueDuration._data.months) {
                formattedValue = `${Math.floor(valueDuration.asDays())}${t(
                    'repo_page.dora.d'
                )}`;
            } else if (valueDuration._data.days) {
                formattedValue = `${valueDuration._data.days}${t(
                    'repo_page.dora.d'
                )} ${
                    valueDuration._data.minutes >= 30
                        ? valueDuration._data.hours + 1
                        : valueDuration._data.hours
                }${t('repo_page.dora.h')}`;
            } else if (valueDuration._data.hours) {
                formattedValue = `${valueDuration._data.hours}${t(
                    'repo_page.dora.h'
                )} ${
                    valueDuration._data.seconds >= 30
                        ? valueDuration._data.minutes + 1
                        : valueDuration._data.minutes
                }${t('repo_page.dora.m')}`;
            } else if (valueDuration._data.minutes) {
                formattedValue = `${valueDuration._data.minutes}${t(
                    'repo_page.dora.m'
                )} ${
                    valueDuration._data.milliseconds >= 30
                        ? valueDuration._data.seconds + 1
                        : valueDuration._data.seconds
                }${t('repo_page.dora.s')}`;
            } else if (valueDuration._data.seconds) {
                formattedValue = `${
                    valueDuration._data.milliseconds >= 30
                        ? valueDuration._data.seconds + 1
                        : valueDuration._data.seconds
                }${t('repo_page.dora.s')}`;
            }
        }

        return formattedValue;
    }

    return (
        <section className="mt-4">
            <WhitePanelContentWrapper
                className={isInCard ? 'p-0 shadow-none' : ''}
            >
                {isInCard ? null : (
                    <PanelTitle
                        title="DORA Metrics"
                        className=""
                        underlineColorType="tertiary"
                    />
                )}
                <div className="mt-4 w-full h-full flex gap-x-12">
                    {/* FILTERS block */}
                    {areFiltersOpen && !isInCard ? (
                        <div className="relative w-3/12 bg-gray-bg rounded-lg px-4 py-3">
                            {renderFilterButton()}
                            {reposLoading ? (
                                <div className="w-full h-full flex justify-center items-center">
                                    <Loader />
                                </div>
                            ) : (
                                <DoraMetricsFilter
                                    options={repositoriesFilter}
                                    setOptions={setRepositoriesFilter}
                                    title={capitalizeFirstLetter(
                                        t('repo_page.dora.filter')
                                    )}
                                />
                            )}
                        </div>
                    ) : (
                        <div
                            className={`pl-4 pt-3 flex-none ${
                                isInCard ? 'hidden' : ''
                            }`}
                        >
                            {renderFilterButton()}
                        </div>
                    )}
                    {/* CHARTS BLOCK */}
                    <div
                        className={`${
                            areFiltersOpen ? 'w-9/12' : 'w-full'
                        } grid grid-cols-2 gap-8 `}
                    >
                        {repositoriesFilter.length ? (
                            <>
                                <div
                                    className={`w-full col-span-2 grid grid-cols-2 gap-8 `}
                                    data-tour="r-step-page-12"
                                >
                                    <DoraMetricsChart
                                        charts={chartsDeploymentFreq}
                                        chartColor={['#3090B3', '#FFA1B5']}
                                        chartHeight={areFiltersOpen ? 240 : 360}
                                        loading={deploymentFreqState.loading}
                                        cardTimeperiod={cardTimeperiod}
                                    />
                                    <div className="relative">
                                        <DoraMetricsChart
                                            charts={
                                                orgHash === demoOrgHash
                                                    ? generateDemoLeadTimeCharts()
                                                    : chartsLeadTime
                                            }
                                            chartColor={['#FF6384', '#333333']}
                                            setUpdate={setUpdate}
                                            update={update}
                                            yAxisFormatter={function () {
                                                const formattedValue =
                                                    formatChartAxisTime(
                                                        this.value
                                                    );
                                                return (
                                                    '<span>' +
                                                    formattedValue +
                                                    '</span>'
                                                );
                                            }}
                                            chartHeight={
                                                areFiltersOpen ? 240 : 360
                                            }
                                            customPointFormatter={function () {
                                                const formattedValue =
                                                    formatChartAxisTime(
                                                        this.options.y
                                                    );

                                                const keyIndex =
                                                    formattedCycleTimeData.findIndex(
                                                        (item) =>
                                                            item[0] ===
                                                            this.options.x
                                                    );

                                                return (
                                                    '<span>' +
                                                    this.series.userOptions.name.replace(
                                                        'Deployments ',
                                                        ''
                                                    ) +
                                                    ': ' +
                                                    formattedValue +
                                                    '</span>' +
                                                    '<br/>' +
                                                    '<span>' +
                                                    `${
                                                        this.series
                                                            .initialType ===
                                                        'scatter'
                                                            ? 'Ticket key: ' +
                                                              ticketKeys[
                                                                  keyIndex
                                                              ]
                                                            : ''
                                                    }`
                                                );
                                            }}
                                            loading={leadTimeState.loading}
                                            cardTimeperiod={cardTimeperiod}
                                        />
                                    </div>
                                </div>
                                {isInCard ? null : (
                                    <div
                                        className={`w-full col-span-2 grid grid-cols-2 gap-8 `}
                                        data-tour="r-step-page-13"
                                    >
                                        <div className="relative">
                                            <DoraMetricsChart
                                                charts={[
                                                    {
                                                        title: {
                                                            label: capitalizeFirstLetter(
                                                                t(
                                                                    'repo_page.dora.mttr'
                                                                )
                                                            ),
                                                            value: 'mttr',
                                                        },
                                                        averageMeasure:
                                                            'hours to restore/day',
                                                        averageValue:
                                                            averageMttr,
                                                        series: [
                                                            {
                                                                name: capitalizeFirstLetter(
                                                                    t(
                                                                        'repo_page.dora.mttr'
                                                                    )
                                                                ),
                                                                data: formattedMttrData
                                                                    ? formattedMttrData
                                                                    : [],
                                                            },
                                                        ],
                                                    },
                                                ]}
                                                chartColor={['#F8C238']}
                                                yAxisFormat="{value}h"
                                                chartHeight={
                                                    areFiltersOpen ? 240 : 360
                                                }
                                                className={`${
                                                    demoOrgHash === orgHash
                                                        ? ''
                                                        : 'filter blur'
                                                }`}
                                                cardTimeperiod={cardTimeperiod}
                                            />
                                            {demoOrgHash === orgHash ? null : (
                                                <div className="absolute top-0 w-full h-full flex justify-center items-center ">
                                                    <p className="text-xl text-gray-400 font-display">
                                                        {capitalizeFirstLetter(
                                                            t(
                                                                'repo_page.dora.soon'
                                                            )
                                                        )}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                        <div className="relative">
                                            <DoraMetricsChart
                                                charts={[
                                                    {
                                                        title: {
                                                            label: capitalizeFirstLetter(
                                                                t(
                                                                    'repo_page.dora.cfr'
                                                                )
                                                            ),
                                                            value: 'change-failure-rate',
                                                        },
                                                        averageMeasure: `%/${t(
                                                            'repo_page.dora.day'
                                                        )}`,
                                                        averageValue:
                                                            averageFailureRate,
                                                        series: [
                                                            {
                                                                name: capitalizeFirstLetter(
                                                                    t(
                                                                        'repo_page.dora.cfr'
                                                                    )
                                                                ),
                                                                data: formattedFailureRateData
                                                                    ? formattedFailureRateData
                                                                    : [],
                                                            },
                                                        ],
                                                    },
                                                ]}
                                                chartColor={['#7902D8']}
                                                yAxisFormat="{value}%"
                                                chartHeight={
                                                    areFiltersOpen ? 240 : 360
                                                }
                                                className={`${
                                                    demoOrgHash === orgHash
                                                        ? ''
                                                        : 'filter blur'
                                                }`}
                                                cardTimeperiod={cardTimeperiod}
                                            />
                                            {demoOrgHash === orgHash ? null : (
                                                <div className="absolute top-0 w-full h-full flex justify-center items-center ">
                                                    <p className="text-xl text-gray-400 font-display">
                                                        {capitalizeFirstLetter(
                                                            t(
                                                                'repo_page.dora.soon'
                                                            )
                                                        )}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : null}
                    </div>
                </div>
            </WhitePanelContentWrapper>
        </section>
    );
};

export default DoraMetricsPanel;
