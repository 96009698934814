import React from 'react';
import './styles.scss';

const ErrorIndicator = ({ error, style }) => {
    function breakLines(text) {
        const splitted = text.split('\n');
        if (!splitted?.length) {
            return (
                <p className="text-center error-data" style={{ margin: 0 }}>
                    {text}
                </p>
            );
        }
        return (
            <div className="text-center error-data" style={{ margin: 0 }}>
                {splitted?.map((line) => (
                    <p>{line}</p>
                ))}
            </div>
        );
    }
    if (error?.response) {
        return (
            <div className="error-indicator mx-auto p-3 bg-red-100 rounded border border-solid border-red-200 text-red-500">
                {breakLines(
                    error.response.message ||
                        error.response.data ||
                        `Request rejected with status ${error.response.status}`
                )}
            </div>
        );
    } else if (error?.data) {
        return (
            <div className="error-indicator mx-auto  p-3 bg-red-100 rounded border border-solid border-red-200 text-red-500">
                <p className="text-center mb-2" style={{ margin: 0 }}>
                    Oops! Something went wrong!
                </p>
                {breakLines(error.data)}
                <p className="text-center error-status" style={{ margin: 0 }}>
                    Request rejected with status{' '}
                    {error.status || error.data.status}
                </p>
            </div>
        );
    } else if (error) {
        return (
            <div className="error-indicator  p-3 bg-red-100 rounded border border-solid border-red-200 text-red-500">
                <p className="text-center mb-2" style={{ margin: 0 }}>
                    Oops! Something went wrong!
                </p>
                {breakLines(error.toString())}
            </div>
        );
    } else {
        return (
            <div className="error-indicator  p-3 bg-red-100 rounded border border-solid border-red-200 text-red-500">
                <p className="text-center" style={{ margin: 0 }}>
                    Oops! Something went wrong!
                </p>
            </div>
        );
    }
};

export default ErrorIndicator;
