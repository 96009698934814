import React, { useState, useEffect } from 'react';
import { FaCheck } from 'react-icons/fa';
import { BiInfoCircle } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../helpers/textFormatters';

const DoraMetricsFilter = ({ options, setOptions, title, inTeam }) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState('');
    const [hoverStatus, setHoverStatus] = useState('');
    const [isAllSelected, setIsAllSelected] = useState(false);

    let currentGroup = null;

    useEffect(() => {
        const forHovering = options
            .filter((option) => !option.webhook)
            .reduce((acc, option) => {
                return { ...acc, [option.url]: false };
            }, {});
        const areAllAvailableChecked = !options
            .filter((option) => option?.webhook)
            ?.find((optionWithWebhook) => !optionWithWebhook?.isSelected);
        setIsAllSelected(areAllAvailableChecked);
        setHoverStatus(forHovering);
    }, [options]);

    function toggleOption(option) {
        const updatedOptions = options.map((item) => {
            if (item.name === option.name) {
                return { ...item, isSelected: !item.isSelected };
            } else {
                return item;
            }
        });
        setOptions(updatedOptions);
    }

    function setHover(repoUrl) {
        const updatedHoverStatus = Object.keys(hoverStatus).reduce(
            (acc, option) => {
                if (option === repoUrl) {
                    return { ...acc, [option]: true };
                } else {
                    return { ...acc, [option]: false };
                }
            },
            {}
        );
        setHoverStatus(updatedHoverStatus);
    }

    function handleToggleAll(selectedValue) {
        const updatedOptions = options.map((item) => {
            if (item?.webhook) {
                return { ...item, isSelected: selectedValue };
            } else {
                return item;
            }
        });
        setOptions(updatedOptions);
    }

    return (
        <div
            className={`text-gray-500 mb-5 max-h-full ${inTeam ? 'w-1/2' : ''}`}
        >
            {title ? (
                <p className="mb-1 font-display text-center text-gray-500 font-semibold text-lg">
                    {title}
                </p>
            ) : null}
            {options?.length ? (
                <div className="max-h-480 bg-white rounded border border-solid border-gray-400 p-2 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded-full hover:scrollbar-thumb-gray-400">
                    <input
                        type="text"
                        id="search"
                        name="search"
                        className="mb-2 rounded py-1 px-2 border border-solid border-gray-400 w-full focus:outline-none"
                        placeholder={`${capitalizeFirstLetter(
                            t('repo_page.dora.search')
                        )}...`}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    ></input>
                    <ul>
                        <li className={`flex gap-x-2 pt-1.5 pb-4`} key={'all'}>
                            {isAllSelected ? (
                                <div
                                    className={`w-4 h-4 rounded bg-theme-button cursor-pointer
                                     flex items-center justify-center`}
                                    onClick={() => handleToggleAll(false)}
                                >
                                    <FaCheck color="#fff" size={10} />
                                </div>
                            ) : (
                                <div
                                    className={`w-4 h-4 rounded bg-gray-100 cursor-pointer
                                    `}
                                    onClick={() => handleToggleAll(true)}
                                ></div>
                            )}
                            <p>Select all available</p>
                        </li>
                        {options.map((option) => {
                            if (option.group) {
                                if (option.group !== currentGroup) {
                                    currentGroup = option.group;
                                    const groupOptions = options.filter(
                                        (option) =>
                                            option.group === currentGroup
                                    );
                                    return (
                                        <li
                                            className="mb-2 relative"
                                            key={option.group}
                                        >
                                            <p className="mb-1 font-display text-gray-500 font-semibold">
                                                {option.group}
                                            </p>
                                            <ul className=" divide-solid divide-gray-400 divide-y">
                                                {groupOptions
                                                    .filter(
                                                        (option) =>
                                                            option.webhook
                                                    )
                                                    .map((option) => {
                                                        if (
                                                            option.name
                                                                .toLowerCase()
                                                                .includes(
                                                                    search.toLowerCase()
                                                                )
                                                        ) {
                                                            return (
                                                                <li
                                                                    className={`flex gap-x-2 py-1.5`}
                                                                    key={
                                                                        option.url
                                                                    }
                                                                >
                                                                    {option.isSelected ? (
                                                                        <div
                                                                            className={`w-4 h-4 rounded bg-theme-button ${
                                                                                option.webhook &&
                                                                                'cursor-pointer'
                                                                            } flex items-center justify-center`}
                                                                            onClick={() =>
                                                                                option.webhook &&
                                                                                toggleOption(
                                                                                    option
                                                                                )
                                                                            }
                                                                        >
                                                                            <FaCheck
                                                                                color="#fff"
                                                                                size={
                                                                                    10
                                                                                }
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        <div
                                                                            className={`w-4 h-4 rounded bg-gray-100 ${
                                                                                option.webhook &&
                                                                                'cursor-pointer'
                                                                            }`}
                                                                            onClick={() =>
                                                                                option.webhook &&
                                                                                toggleOption(
                                                                                    option
                                                                                )
                                                                            }
                                                                        ></div>
                                                                    )}
                                                                    <p>
                                                                        {
                                                                            option.name
                                                                        }
                                                                    </p>
                                                                </li>
                                                            );
                                                        } else {
                                                            return;
                                                        }
                                                    })}
                                                {groupOptions
                                                    .filter(
                                                        (option) =>
                                                            !option.webhook
                                                    )
                                                    .map((option) => {
                                                        if (
                                                            option.name
                                                                .toLowerCase()
                                                                .includes(
                                                                    search.toLowerCase()
                                                                )
                                                        ) {
                                                            return (
                                                                <li
                                                                    className={`flex gap-x-2 py-1.5 relative`}
                                                                    key={
                                                                        option.url
                                                                    }
                                                                    onMouseEnter={() =>
                                                                        setHover(
                                                                            option.url
                                                                        )
                                                                    }
                                                                    onMouseLeave={() =>
                                                                        setHover(
                                                                            null
                                                                        )
                                                                    }
                                                                >
                                                                    {option.isSelected ? (
                                                                        <div
                                                                            className={`w-4 h-4 rounded bg-theme-button ${
                                                                                option.webhook &&
                                                                                'cursor-pointer'
                                                                            } flex items-center justify-center`}
                                                                            onClick={() =>
                                                                                option.webhook &&
                                                                                toggleOption(
                                                                                    option
                                                                                )
                                                                            }
                                                                        >
                                                                            <FaCheck
                                                                                color="#fff"
                                                                                size={
                                                                                    10
                                                                                }
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        <div
                                                                            className={`w-4 h-4 rounded bg-gray-100 ${
                                                                                option.webhook &&
                                                                                'cursor-pointer'
                                                                            }`}
                                                                            onClick={() =>
                                                                                option.webhook &&
                                                                                toggleOption(
                                                                                    option
                                                                                )
                                                                            }
                                                                        ></div>
                                                                    )}
                                                                    <p>
                                                                        {
                                                                            option.name
                                                                        }
                                                                    </p>
                                                                    <div>
                                                                        <BiInfoCircle
                                                                            size={
                                                                                16
                                                                            }
                                                                        />
                                                                    </div>
                                                                    {hoverStatus[
                                                                        option
                                                                            .url
                                                                    ] && (
                                                                        <div className="absolute top-2 left-8 text-xs  w-4/5  py-1 px-3  z-50 shadow-md bg-gray-100">
                                                                            To
                                                                            include
                                                                            this
                                                                            repository
                                                                            in
                                                                            metrics
                                                                            you
                                                                            need
                                                                            to
                                                                            create
                                                                            webhook
                                                                            for
                                                                            it
                                                                            first{' '}
                                                                        </div>
                                                                    )}
                                                                </li>
                                                            );
                                                        } else {
                                                            return;
                                                        }
                                                    })}
                                            </ul>
                                        </li>
                                    );
                                } else {
                                    return;
                                }
                            } else {
                                if (
                                    option.name
                                        .toLowerCase()
                                        .includes(search.toLowerCase())
                                ) {
                                    return (
                                        <li
                                            className={`flex gap-x-2 py-1.5 ${
                                                !option.webhook &&
                                                'filter grayscalse'
                                            }`}
                                            key={option.url}
                                        >
                                            {option.isSelected ? (
                                                <div
                                                    className="w-4 h-4 rounded bg-theme-button cursor-pointer flex items-center justify-center"
                                                    onClick={() =>
                                                        toggleOption(option)
                                                    }
                                                >
                                                    <FaCheck
                                                        color="#fff"
                                                        size={10}
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    className="w-4 h-4 rounded bg-gray-100 cursor-pointer"
                                                    onClick={() =>
                                                        toggleOption(option)
                                                    }
                                                ></div>
                                            )}
                                            {/* TODO: add webhooks updates */}
                                            <p>{option.name}</p>
                                        </li>
                                    );
                                } else {
                                    return;
                                }
                            }
                        })}
                    </ul>
                </div>
            ) : (
                <p className="mt-4 text-gray-400 font-display">
                    No repositories added.
                </p>
            )}
        </div>
    );
};

export default DoraMetricsFilter;
