import React, { useState, useCallback, useContext } from 'react';
import Select from 'react-select';
import {
    addSelectedRepositories,
    getRepositories,
    getSelectedRepositories,
} from '../../api/swarm/SwarmAPI';
import Loader from '../loader';
import ErrorIndicator from '../error-indicator';
import { Check } from '../../assets/svg';
import showNotification from '../../helpers/showNotification';
import { createRepositoryWebhook } from '../../api/settings/GitProviderAPI';
import { NAVIGATION_PATHS } from '../../constants/navigationPaths';
import { useFetchDataAndSetState } from '../../helpers/useFetchDataAndSetState';
import { useTranslation } from 'react-i18next';
import { fetchRepoGroups } from '../../helpers/fetchRepoGroups';
import { RepoGroupsContext } from '../../context/RepoGroupsContext';

function AddRepositoryModalContent({
    gitProviderState,
    selectedRepositoriesState,
    selectedRepositoriesDispatch,
    setAddRepositoryLoading,
    noProvider,
    setNoProvider,
    setModalState,
}) {
    const { repoGroupsState, repoGroupsDispatch } =
        useContext(RepoGroupsContext);
    const { t } = useTranslation();
    const [step, setStep] = useState(1);
    const [provider, setProvider] = useState(undefined);
    const request = useCallback(
        () => provider?.value && getRepositories(provider?.value),
        [provider?.value]
    );
    const [providerRepositoriesState, providerRepositoriesDispatch] =
        useFetchDataAndSetState(request);

    const [localLoading, setLocalLoading] = useState(false);
    const [creatingWebhook, setCreatingWebhook] = useState(false);

    async function addRepositories() {
        setLocalLoading(true);
        const data = { repos: {} };
        providerRepositoriesState.data
            .filter((repository) => repository.selected)
            ?.forEach((repo) => {
                data.repos[repo.name] = repo.url;
            });

        setAddRepositoryLoading(true);

        await addSelectedRepositories(data, provider.value)
            .then(async (response) => {
                if (response.status === 200) {
                    await createWebhooksForAddedRepos(response.data);

                    const updatedData = await getSelectedRepositories();
                    setAddRepositoryLoading(false);

                    if (updatedData.status === 200) {
                        selectedRepositoriesDispatch({
                            type: 'SET_DATA',
                            payload: updatedData.data,
                        });
                    }

                    if (updatedData.status === 204) {
                        selectedRepositoriesDispatch({
                            type: 'SET_DATA',
                            payload: [],
                        });
                    }
                }
                if (response.status === 204) {
                    selectedRepositoriesDispatch({
                        type: 'SET_DATA',
                        payload: [],
                    });
                }
                setLocalLoading(false);
                setModalState(false);
                setStep(1);
                setProvider(undefined);
                setNoProvider(false);
            })
            .catch((error) => {
                setAddRepositoryLoading(false);
                setLocalLoading(false);

                selectedRepositoriesDispatch({
                    type: 'SET_ERROR',
                    payload: error,
                });
            });

        setAddRepositoryLoading(false);

        try {
            await fetchRepoGroups(repoGroupsDispatch);
        } catch (err) {
            console.log(err);
        }
    }

    const createWebhooksForAddedRepos = async (responseData) => {
        const newRepos = responseData.reduce((acc, provider) => {
            const addedReposPerProvider = provider.repositories.filter(
                (repo) => {
                    const currentProviderData =
                        selectedRepositoriesState.data?.find(
                            (item) => item.provider === provider.provider
                        );

                    if (
                        currentProviderData?.repositories.find(
                            (currentPoviderRepo) =>
                                currentPoviderRepo.url === repo.url
                        )
                    ) {
                        return;
                    } else {
                        return repo;
                    }
                }
            );

            const formattedRepos = addedReposPerProvider.map((newRepo) => {
                return {
                    ...newRepo,
                    provider: provider.provider,
                };
            });

            return [...acc, ...formattedRepos];
        }, []);

        if (newRepos.length) {
            setCreatingWebhook(true);
            try {
                const res = await Promise.allSettled(
                    newRepos.map(async (repo) => {
                        const params = {
                            provider_id: provider.value,
                            repository_url: repo.url,
                        };
                        const res = await createRepositoryWebhook(
                            repo.provider,
                            'some data',
                            {
                                params: params,
                            }
                        );
                        return res;
                    })
                );
                res.find((reqResult) => reqResult.status === 'rejected') &&
                    showNotification(
                        'Failed to create webhooks for one or several repositories'
                    );
                setCreatingWebhook(false);
                return newRepos;
            } catch (error) {
                setCreatingWebhook(false);
                showNotification(
                    'Failed to create webhooks for one or several repositories'
                );
                return error;
            }
        }
    };

    const handleSubmitButtonClick = async () => {
        if (step === 1) {
            if (provider && provider.value && gitProviderState.data) {
                setStep(2);
            } else {
                setNoProvider(true);
            }
        } else if (
            step === 2 &&
            !providerRepositoriesState.error &&
            !providerRepositoriesState.loading &&
            !localLoading
        ) {
            try {
                await addRepositories();
            } catch (error) {
                console.log(error);
            }
        }
    };

    function toggleAll() {
        const areUnselected = providerRepositoriesState.data?.find(
            (repo) => !repo?.selected
        );
        let formattedData = providerRepositoriesState.data.map((repo) => {
            return {
                name: repo.name,
                selected: areUnselected ? true : false,
                url: repo.url,
            };
        });

        providerRepositoriesDispatch({
            type: 'SET_DATA',
            payload: formattedData,
        });
    }

    const providerOptions = [];

    if (gitProviderState.data) {
        gitProviderState.data.map((provider) => {
            providerOptions.push({
                value: provider.provider_id,
                label: provider.provider_id,
            });
        });
    }

    return (
        <>
            <div className="flex justify-between mt-4 text-theme-tertiary">
                <span className="ml-1">SELECT KEY</span>

                <span className="mr-1">SELECT REPOSITORIES</span>
            </div>

            <div className="flex justify-between mt-1">
                <span
                    className={`w-7 h-7 ml-16 rounded-full flex justify-center items-center text-center ${
                        step === 1
                            ? 'border border-solid border-theme-tertiary text-theme-tertiary'
                            : 'text-gray-500 bg-gray-300'
                    }`}
                >
                    1
                </span>

                <span className="border-t-2 border-solid border-gray-300 mt-3 mx-2 w-96"></span>

                <span
                    className={`w-7 h-7 mr-24 rounded-full flex justify-center items-center text-center ${
                        step === 2
                            ? 'border border-solid border-theme-tertiary text-theme-tertiary'
                            : 'text-gray-500 bg-gray-300'
                    }`}
                >
                    2
                </span>
            </div>

            {step === 1 ? (
                <div className="px-5 mt-8">
                    {gitProviderState.error && (
                        <ErrorIndicator error={gitProviderState.error} />
                    )}

                    {!gitProviderState.error && gitProviderState.loading && (
                        <div className="flex justify-center">
                            <Loader
                                color={'#C2C7D7'}
                                size={35}
                                speedMultiplier={0.8}
                            />
                        </div>
                    )}

                    {!gitProviderState.data && (
                        <p className="text-gray-500 italic">
                            There are no git providers yet, add a git provider{' '}
                            <a
                                href={NAVIGATION_PATHS.settingsGitProviders}
                                className="text-blue-400 underline"
                            >
                                here
                            </a>
                            .
                        </p>
                    )}

                    {!gitProviderState.error &&
                        !gitProviderState.loading &&
                        gitProviderState?.data?.length && (
                            <>
                                <p className="mb-2 text-gray-500">
                                    Select provider
                                </p>

                                <Select
                                    className="w-1/2 text-sm"
                                    isSearchable={true}
                                    value={provider}
                                    placeholder="Choose an option"
                                    options={providerOptions}
                                    onChange={(event) => {
                                        if (event) {
                                            setProvider({
                                                value: event.value,
                                                label: event.label,
                                            });
                                            setNoProvider(false);
                                        }
                                    }}
                                    styles={setCustomStyle()}
                                />
                            </>
                        )}

                    {noProvider && (
                        <p className="text-red-700 mt-1 italic">
                            Please select a provider
                        </p>
                    )}
                </div>
            ) : (
                <div className="px-5 mt-8">
                    <div className="flex justify-between gap-8 mb-2 items-center">
                        <p className=" text-gray-500">Select repositories</p>
                        {providerRepositoriesState?.data?.length &&
                        !providerRepositoriesState?.error &&
                        !providerRepositoriesState.loading &&
                        !localLoading ? (
                            <button
                                type="button"
                                className={`bg-theme-tertiary text-white border-0 rounded h-7 w-24 ${
                                    providerRepositoriesState?.data?.length ||
                                    step === 1
                                        ? ''
                                        : 'opacity-50'
                                }`}
                                onClick={() => toggleAll()}
                                disabled={
                                    providerRepositoriesState?.data?.length ||
                                    step === 1
                                        ? false
                                        : true
                                }
                            >
                                {providerRepositoriesState.data?.find(
                                    (repo) => !repo?.selected
                                )
                                    ? 'Select All'
                                    : 'Remove All'}
                            </button>
                        ) : null}
                    </div>

                    {providerRepositoriesState.error && (
                        <ErrorIndicator
                            error={providerRepositoriesState.error}
                        />
                    )}

                    {!providerRepositoriesState.error &&
                        (providerRepositoriesState.loading || localLoading) && (
                            <div className="flex flex-col gap-4 justify-center items-center">
                                {creatingWebhook && (
                                    <p className="text-gray-400">
                                        Creating webhooks...
                                    </p>
                                )}
                                <Loader
                                    color={'#C2C7D7'}
                                    size={35}
                                    speedMultiplier={0.8}
                                />
                            </div>
                        )}

                    {!providerRepositoriesState.error &&
                        !providerRepositoriesState.loading &&
                        !localLoading &&
                        (providerRepositoriesState?.data?.length ? (
                            <div className="space-y-2 h-80 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded-full">
                                {providerRepositoriesState.data.map(
                                    (repository) => {
                                        return (
                                            <div key={repository.url}>
                                                <Repo
                                                    repository={repository}
                                                    providerRepositoriesState={
                                                        providerRepositoriesState
                                                    }
                                                    providerRepositoriesDispatch={
                                                        providerRepositoriesDispatch
                                                    }
                                                ></Repo>
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        ) : (
                            <p className="py-4 text-gray-500">
                                No repositories found
                            </p>
                        ))}
                </div>
            )}

            <div className="absolute bottom-0 right-0 mb-5 mr-5">
                <button
                    type="button"
                    className="bg-gray-300 text-gray-500 border-0 rounded h-7 w-24 mr-4 disabled:cursor-not-allowed"
                    onClick={() => {
                        if (step === 1) {
                            setModalState(false);
                            setNoProvider(false);
                        } else {
                            setStep(1);
                        }
                    }}
                    disabled={
                        step === 2 &&
                        (providerRepositoriesState.loading || localLoading)
                    }
                >
                    {step === 1 ? 'Cancel' : 'Back'}
                </button>

                <button
                    type="button"
                    className={`bg-theme-tertiary text-white border-0 rounded h-7 w-24 ${
                        providerRepositoriesState?.data?.length || step === 1
                            ? ''
                            : 'opacity-50'
                    }`}
                    onClick={() => handleSubmitButtonClick()}
                    disabled={
                        providerRepositoriesState?.data?.length || step === 1
                            ? false
                            : true
                    }
                >
                    {step === 1 ? 'Next' : 'Finish'}
                </button>
            </div>
        </>
    );
}

function Repo({
    repository,
    providerRepositoriesState,
    providerRepositoriesDispatch,
}) {
    return (
        <button
            className="w-full text-left items-center p-4 border-2 border-solid border-theme-tertiary rounded-lg cursor-pointer hover:bg-gray-100"
            onClick={() => {
                let formattedData = providerRepositoriesState.data.map(
                    (repo) => {
                        if (repo.url === repository.url) {
                            return {
                                name: repository.name,
                                selected: !repository.selected,
                                url: repository.url,
                            };
                        } else {
                            return repo;
                        }
                    }
                );

                providerRepositoriesDispatch({
                    type: 'SET_DATA',
                    payload: formattedData,
                });
            }}
        >
            <div className="w-full inline-flex justify-between items-center">
                <div className="w-11/12">
                    <p className="text-gray-700 ">{repository.name}</p>
                    <p className="text-blue-400 mt-1">{repository.url}</p>
                </div>

                {repository.selected && (
                    <div className="w-5 h-5 rounded-full flex justify-center items-center bg-theme-tertiary">
                        <Check height="12px" fill="white"></Check>
                    </div>
                )}
            </div>
        </button>
    );
}

const setCustomStyle = () => ({
    control: (provided) => ({
        ...provided,
        padding: '2px 0',
        outline: 'none',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        opacity: '1',
        height: '42px',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#484a53',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        opacity: '1',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        opacity: '1',
        cursor: 'pointer',
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        opacity: '1',
    }),
    menu: (provided) => ({
        ...provided,
        marginTop: '0',
        padding: '0',
    }),
    valueContainer: (provided) => ({
        ...provided,
        height: '100%',
        flexWrap: 'no-wrap',
    }),
    option: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
        color: '#484a53',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: state.isSelected
                ? '#4C72BD'
                : 'rgba(76, 114, 189, 0.5)',
        },
    }),
});

export default AddRepositoryModalContent;
