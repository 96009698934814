import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getTeamTasks } from '../../api/si/si';
import TasksTable from '../../pages/sprint-insights-page/tasks-table';
import { useFetchDataAndSetState } from '../../helpers/useFetchDataAndSetState';
import { capitalizeFirstLetter } from '../../helpers/textFormatters';
import Loader from '../../components/loader';
import showNotification from '../../helpers/showNotification';
import PanelTitle from '../panel-title';
import SprintInsightChart from '../../pages/sprint-insights-page/si-chart';

function TeamSprintInsights({
    teamHash,
    dateTimeStart,
    dateTimeEnd,
    selectedOrganisationHash,
    sprintsArray,
}) {
    const { t } = useTranslation();
    const [filterParams, setFilterParams] = useState({
        issue_key: '^.*$',
        label: '^.*$',
        transition_from: '^.*$',
        transition_to: '^Done$',
    });

    const [allTickets, setAllTickets] = useState({});
    const [displayedTickets, setDisplayedTickets] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentOffset, setCurrentOffset] = useState(0);
    const [isMoreTicketsLoading, setIsMoreTicketsLoading] = useState(false);
    const [moreTicketsError, setMoreTicketsError] = useState(false);
    const [isNoMoreTickets, setIsNoMoreTickets] = useState(false);
    const [isChartDataLoading, setIsChartDataLoading] = useState(false);
    const [isChartDataUpdating, setIsChartDataUpdating] = useState(false);
    const [isChartUpdateAvaliable, setIsChartUpdateAvaliable] = useState(false);
    const [chartDataError, setChartDataError] = useState(null);
    const [isTableSettings, setIsTableSettings] = useState(false);

    const limit = 10;
    let latestTicketDate = dateTimeEnd;

    const teamTasksRequest = useCallback(
        () =>
            getTeamTasks(
                teamHash,
                dateTimeStart,
                dateTimeEnd,
                0,
                filterParams,
                limit
            ),
        [dateTimeStart, dateTimeEnd, filterParams]
    );

    const [teamTasksState] = useFetchDataAndSetState(teamTasksRequest, [
        dateTimeStart,
        dateTimeEnd,
        selectedOrganisationHash,
    ]);

    useEffect(() => {
        teamTasksState?.data?.tickets &&
            setDisplayedTickets(teamTasksState.data.tickets);
        setIsNoMoreTickets(false);
        setIsChartUpdateAvaliable(false);
        setCurrentPage(1);
        setCurrentOffset(0);
        setMoreTicketsError(false);
        fetchAllTickets(filterParams, selectedOrganisationHash, dateTimeStart);
    }, [teamTasksState.data]);

    useEffect(() => {
        latestTicketDate = dateTimeEnd;
    }, [dateTimeEnd]);

    useEffect(() => {
        setAllTickets({});
        setCurrentOffset(0);
        setDisplayedTickets([]);
    }, [selectedOrganisationHash, dateTimeStart, dateTimeEnd]);

    useEffect(() => {
        setIsMoreTicketsLoading(false);
    }, [displayedTickets]);

    useEffect(() => {
        isNoMoreTickets &&
            showNotification('No more tickets to add', 'success');
    }, [isNoMoreTickets]);

    let pagesCount = 1;
    let offset = 0;

    async function fetchAllTickets() {
        pagesCount === 1 && setIsChartDataLoading(true);
        pagesCount > 1 && setIsChartDataUpdating(true);

        try {
            const res = await getTeamTasks(
                teamHash,
                dateTimeStart,
                latestTicketDate,
                offset,
                filterParams,
                limit
            );

            if (res.status === 204 && pagesCount === 1) {
                setAllTickets({});
            }

            if (res.status === 200) {
                const pageCountToSet = pagesCount;
                setAllTickets((prevState) => {
                    return {
                        ...prevState,
                        [pageCountToSet]: {
                            stringifiedParams: JSON.stringify(filterParams),
                            tickets: res.data.tickets,
                            features: res.data.features,
                            'not features': res.data.not_features,
                            hours: res.data.inferred_hours,
                            time_spent: res.data.time_spent,
                        },
                    };
                });
                pagesCount === 1 && setIsChartDataLoading(false);
                pagesCount > 1 && setIsChartDataUpdating(false);

                pagesCount += 1;
                offset += limit;
                await fetchAllTickets(
                    null,
                    selectedOrganisationHash,
                    dateTimeStart
                );
            } else {
                pagesCount > 1 && setIsChartUpdateAvaliable(false);
                setIsChartDataLoading(false);
                setIsChartDataUpdating(false);
                return;
            }
        } catch (error) {
            setChartDataError(error);
            setIsChartDataLoading(false);
            setIsChartDataUpdating(false);
        }
    }

    async function fetchNextPage() {
        const res = await getTeamTasks(
            teamHash,
            dateTimeStart,
            dateTimeEnd,
            currentOffset + limit,
            filterParams,
            limit
        );
        return res;
    }

    function loadMoreTickets() {
        setIsMoreTicketsLoading(true);
        setMoreTicketsError(false);
        let ticketsToAdd = allTickets[currentPage + 1];
        if (
            ticketsToAdd &&
            ticketsToAdd?.stringifiedParams === JSON.stringify(filterParams)
        ) {
            setDisplayedTickets([...displayedTickets, ...ticketsToAdd.tickets]);
            setCurrentPage(currentPage + 1);
            setCurrentOffset(currentOffset + limit);
        } else {
            fetchNextPage()
                .then((response) => {
                    if (response.status === 200) {
                        setDisplayedTickets([
                            ...displayedTickets,
                            ...response.data.tickets,
                        ]);
                        setCurrentPage(currentPage + 1);
                        setCurrentOffset(currentOffset + limit);
                    }

                    response.status === 204 && setIsNoMoreTickets(true);
                    setIsMoreTicketsLoading(false);
                })
                .catch((error) => {
                    console.log('fetching page error', error);
                    setMoreTicketsError(true);
                    setIsMoreTicketsLoading(false);
                });
        }
    }

    // filtering tickets data for  charts
    let filteredTickets = {};
    for (let key in allTickets) {
        if (
            allTickets[key].stringifiedParams === JSON.stringify(filterParams)
        ) {
            filteredTickets = {
                ...filteredTickets,
                [key]: allTickets[key],
            };
        }
    }

    // extracting data for features chart
    let featuresAmount = 0;
    let notFeaturesAmount = 0;
    let featuresTime = 0;
    let notFeaturesTime = 0;

    for (let key in filteredTickets) {
        featuresAmount += filteredTickets[key].features;
        notFeaturesAmount += filteredTickets[key]['not features'];
        featuresTime += filteredTickets[key].time_spent.feature;
        notFeaturesTime += filteredTickets[key].time_spent['not feature'];
    }

    const featuresChartData = {
        [`${t('si_page.features')}`]: featuresAmount,
        [`${t('common.not')} ${t('si_page.features')}`]: notFeaturesAmount,
    };

    const hoursChartData = {
        [`${t('si_page.features')}`]: featuresTime,
        [`${t('common.not')} ${t('si_page.features')}`]: notFeaturesTime,
    };

    return (
        <div className="grid grid-cols-2 gap-4 border border-solid border-gray-300 p-4 mt-4 rounded">
            <p className="font-display col-span-2 inline-block text-28 text-gray-700 font-light">
                Team's Sprint Insights
            </p>
            {/* Tickets table */}
            <div className="relative col-span-2 ">
                <TasksTable
                    setSelectedTasks={() => {}}
                    data={displayedTickets}
                    error={teamTasksState.error}
                    loading={teamTasksState.loading}
                    isTableSettings={isTableSettings}
                    setIsTableSettings={setIsTableSettings}
                    type="team"
                    sprintsArray={sprintsArray}
                    selectedOrganisationHash={selectedOrganisationHash}
                />
                {moreTicketsError &&
                    !teamTasksState.loading &&
                    !teamTasksState.error && (
                        <p className="mx-auto mt-2 text-center text-red-text text-sm">
                            Failed to fetch more tickets
                        </p>
                    )}
                <div className="mt-2 flex justify-center items-center">
                    {isMoreTicketsLoading ? (
                        <Loader
                            color={'#C2C7D7'}
                            size={35}
                            speedMultiplier={0.8}
                        />
                    ) : displayedTickets.length &&
                      !isNoMoreTickets &&
                      !teamTasksState.loading &&
                      !teamTasksState.error ? (
                        <button
                            className="px-4 py-2  btn bg-theme-tertiary rounded text-white font-display leading-6 font-medium"
                            onClick={() => {
                                loadMoreTickets();
                            }}
                        >
                            {capitalizeFirstLetter(t('button.show_more'))}
                        </button>
                    ) : (
                        ''
                    )}
                </div>
            </div>
            {/* Charts */}

            <div className="border border-solid border-gray-300 p-4 mt-4 rounded">
                {isChartDataUpdating && (
                    <p className="text-gray-500 leading-6 text-sm absolute top-0 right-0 p-4 z-20">
                        Updating data...
                    </p>
                )}
                <p className="font-display col-span-2 inline-block text-28 text-gray-700 font-light">
                    {capitalizeFirstLetter(t('si_page.feature')) +
                        ' - ' +
                        t('common.not') +
                        ' ' +
                        t('si_page.feature')}
                </p>

                <SprintInsightChart
                    data={featuresChartData}
                    error={chartDataError}
                    loading={isChartDataLoading}
                    updateAvailable={isChartUpdateAvaliable}
                    setUpdateAvailable={setIsChartUpdateAvaliable}
                />
            </div>
            <div className="border border-solid border-gray-300 p-4 mt-4 rounded">
                {isChartDataUpdating && (
                    <p className="text-gray-500 leading-6 text-sm absolute top-0 right-0 p-4 z-20">
                        Updating data...
                    </p>
                )}
                <p className="font-display col-span-2 inline-block text-28 text-gray-700 font-light">
                    {capitalizeFirstLetter(t('si_page.time_spent')) + ', hours'}
                </p>
                <SprintInsightChart
                    data={hoursChartData}
                    error={chartDataError}
                    loading={isChartDataLoading}
                    updateAvailable={isChartUpdateAvaliable}
                    setUpdateAvailable={setIsChartUpdateAvaliable}
                />
            </div>
        </div>
    );
}

export default TeamSprintInsights;
