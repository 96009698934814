import React, { Component } from 'react';
import Select from 'react-select';
import moment from 'moment';
import Loader from '../../loader';
import './styles.scss';

export default class GenericTableRow extends Component {
    state = {
        row: null,
        actionLoading: true,
    };

    selectOptions = () => {
        let options = [];
        this.props.selectOptions.map((option) => {
            options.push({ value: option.value, label: option.label });
        });
        return options;
    };

    getOption = (key) => {
        return this.props.selectOptions.filter((opt) => opt.value === key);
    };

    getRowValue = (index) => {
        return this.state.row[this.props.columns[index].toLowerCase().replace(' ', '_')]
    }

    optionStyles = (state) => {
        const colors = [
            '#e6194B',
            '#3cb44b',
            '#ffe119',
            '#4363d8',
            '#f58231',
            '#911eb4',
            '#42d4f4',
            '#f032e6',
        ];
        const value = (option) =>
            option.value === state.selectProps.value.value;
        const index = this.props.selectOptions.findIndex(value);
        return colors[index];
    };

    customStylesSelect = {
        placeholder: (provided, state) => ({
            ...provided,
            color: '#fff',
            opacity: '1',
        }),
        container: (provided, state) => ({
            ...provided,
            background: this.optionStyles(state),

            width: '100px',
            borderRadius: '4px',
            display: 'inline-block',
        }),
        control: (provided, state) => ({
            ...provided,
            border: 'none',
            borderColor: 'transparent',
            boxShadow: 'transparent',
            background: 'transparent',
            minHeight: '24px',
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            display: 'none',
        }),

        dropdownIndicator: (provided, state) => ({
            ...provided,
            padding: '0px 2px',
            opacity: '1',
            cursor: 'pointer',
            color: '#fff',
            '&:hover': { color: '#fff' },
        }),

        singleValue: (provided, state) => ({
            ...provided,
            margin: '0',
            padding: '1px',
            color: '#fff',
            fontSize: '12px',
            lineHeight: '24px',
            maxWidth: '100%',
            textTransform: 'uppercase',
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            padding: '0',
            display: 'flex',
            justifyContent: 'center',
        }),
        menuList: (provided, state) => ({
            padding: ' 0',
        }),
        menu: (provided, state) => ({
            ...provided,
            margin: '0 0 0 -1px',
            width: 'calc(100%)',
            borderRadius: '4px',
            border: `1px solid ${this.optionStyles(state)}`,
        }),
        option: (provided, state) => {
            return {
                ...provided,
                background: '#fff',
                '&:hover': {
                    background: this.optionStyles(state),
                    color: '#fff',
                },
                margin: '0',
                padding: ' 0',
                cursor: 'pointer',
                minHeight: '24px',
                color: this.optionStyles(state),

                fontSize: '12px',
                lineHeight: '24px',
                maxWidth: '100%',
                textTransform: 'uppercase',
            };
        },
    };

    selectOnChange = (value) => {
        const key = this.state.row.selectKey;
        key.value !== value.value
            ? this.setState(
                  {
                      actionLoading: false,
                  },
                  () => this.props.onSelectChange(value, this.state.row)
              )
            : this.setState({
                  ...this.state,
                  actionLoading: false,
              });
    };

    static getDerivedStateFromProps(props, state) {
        if (state.row !== null) {
            if (state.row.key !== props.row.key) {
                return {
                    row: {
                        ...state.row,
                        key: props.row.key,
                        marked_date: props.row.marked_date,
                    },
                    actionLoading: false,
                };
            } else {
                return state;
            }
        } else {
            return {
                row: props.row,
                actionLoading: false,
            };
        }
    }
    render() {
        return (
            <div
                className={`grid grid-cols-${this.props.colsSpan} text-gray-500`}
            >
                {Object.entries(this.state.row).map(([key, value], index) => {
                    switch (this.props.cellTypes[index]) {
                        case 'string':
                            return (
                                <div
                                    className={`col-span-${this.props.cellSpans[index]} flex  items-center`}
                                    key={`${key}_${value}_${index}`}
                                >
                                    <p
                                        className="truncate px-4 py-2.5"
                                        title={`${value}`}
                                        id={`${this.props.cellTypes[index]}_${index}_${value}`}
                                    >
                                        {this.getRowValue(index)}
                                    </p>
                                </div>
                            );

                        case 'date':
                            return (
                                <div
                                    className={`col-span-${this.props.cellSpans[index]} date flex  items-center`}
                                    key={`${key}_${value}_${index}`}
                                >
                                    <p
                                        className="truncate px-4 py-2"
                                        title={moment(this.getRowValue(index)).format('YYYY MMM DD')}
                                    >
                                        {moment(this.getRowValue(index)).format('YYYY MMM DD')}
                                    </p>
                                </div>
                            );

                        case 'select':
                            return (
                                <div
                                    className={`col-span-${this.props.cellSpans[index]} text-center select-cell p-0 flex  items-center`}
                                    key={`${key}_${value}_${index}`}
                                >
                                    {!this.state.actionLoading ? (
                                        <Select
                                            options={this.props.selectOptions}
                                            isSearchable={false}
                                            placeholder="Action"
                                            styles={this.customStylesSelect}
                                            currentValueForStyles={this.getOption(
                                                this.state.row.selectKey
                                            )}
                                            defaultValue={
                                                this.props.selectOptions[0]
                                            }
                                            onChange={(value) =>
                                                this.selectOnChange(value)
                                            }
                                        />
                                    ) : (
                                        <div className="m-auto">
                                            <Loader
                                                color={'#C2C7D7'}
                                                size={35}
                                                speedMultiplier={0.8}
                                            />
                                        </div>
                                    )}
                                </div>
                            );

                        case 'level':
                            return (
                                <div
                                    className={`level level-${this.props.levelColorScheme}-${value}`}
                                    key={`${key}_${value}_${index}`}
                                >
                                    <span className="level-division"></span>
                                    <span className="level-division"></span>
                                    <span className="level-division"></span>
                                    <span className="level-division"></span>
                                    <span className="level-division"></span>
                                </div>
                            );

                        default:
                            return (
                                <div
                                    className={`col-span-${this.props.cellSpans[index]} flex  items-center`}
                                    key={`${key}_${value}_${index}`}
                                >
                                    <p
                                        className="truncate px-4 py-2.5"
                                        title={'Error'}
                                    >
                                        Unknown data type
                                    </p>
                                </div>
                            );
                    }
                })}
            </div>
        );
    }
}
