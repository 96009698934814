import React, {
    useEffect,
    useState,
    useContext,
    useCallback,
    useRef,
} from 'react';
import { useTour } from '@reactour/tour';
import { withUserAndOragnisations } from '../../helpers/withUser';
import 'react-toastify/dist/ReactToastify.css';
import TasksTable from './tasks-table';
import SISelect from './si-select';
import { VscSettings } from 'react-icons/vsc';
import { getTasks, trainTasks } from '../../api/si/si';
import SprintInsightChart from './si-chart';
import { useFetchDataAndSetState } from '../../helpers/useFetchDataAndSetState';
import { OrganisationsContext } from '../../context/OrganisationsContext';
import { TimePeriodContext } from '../../context/TimePeriodContext';
import WhitePanelContentWrapper from '../../components/white-panel-content-wrapper';
import PanelTitle from '../../components/panel-title';
import SiPrevFilter from './filter';
import Loader from '../../components/loader';
import useParamsQuery from '../../hooks/useParamsQuery';
import showNotification from '../../helpers/showNotification';
import { getJiraWorkspaces } from '../../api/settings/sprintInsightsAPI';
import SettingsMessage from '../../components/settings-message';
import { NAVIGATION_PATHS } from '../../constants/navigationPaths';
import { useTranslation } from 'react-i18next';
import {
    capitalizeEveryWordFirstLetter,
    capitalizeFirstLetter,
} from '../../helpers/textFormatters';
import ModalWrapper from '../../components/modal-wrapper';
import ConfirmationModalContent from '../../components/confirmation-modal-content';

const SprintInsightsPage = () => {
    const { t } = useTranslation();
    const [timePeriodState] = useContext(TimePeriodContext);
    const { date_start: dateTimeStart, date_end: dateTimeEnd } =
        timePeriodState;
    const { setIsOpen } = useTour();
    const query = useParamsQuery();

    const { organisationsState } = useContext(OrganisationsContext);
    const selectedOrganisationHash = organisationsState.data
        ? organisationsState.data.find((org) => org.active).org_hash
        : null;

    const demoOrgHash = process.env.REACT_APP_DEMO_ORG_HASH;

    const [filterParams, setFilterParams] = useState({
        issue_key: '^.*$',
        label: '^.*$',
        transition_from: '^.*$',
        transition_to: '^Done$',
    });

    const limit = 50;
    let latestTicketDate = dateTimeEnd;

    const [allTickets, setAllTickets] = useState({});
    const [displayedTickets, setDisplayedTickets] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentOffset, setCurrentOffset] = useState(0);
    const [isMoreTicketsLoading, setIsMoreTicketsLoading] = useState(false);
    const [moreTicketsError, setMoreTicketsError] = useState(false);
    const [isNoMoreTickets, setIsNoMoreTickets] = useState(false);
    const [isChartDataLoading, setIsChartDataLoading] = useState(false);
    const [isChartDataUpdating, setIsChartDataUpdating] = useState(false);
    const [isChartUpdateAvaliable, setIsChartUpdateAvaliable] = useState(false);
    const [chartDataError, setChartDataError] = useState(null);
    const [wasDemoTriggered, setWasDemoTriggered] = useState(false);
    const [isTableSettings, setIsTableSettings] = useState(false);

    const tasksRequest = useCallback(
        () => getTasks(dateTimeStart, dateTimeEnd, currentOffset, filterParams),
        [dateTimeStart, dateTimeEnd, filterParams]
    );

    const [tasksState] = useFetchDataAndSetState(tasksRequest, [
        dateTimeStart,
        dateTimeEnd,
        selectedOrganisationHash,
        filterParams,
    ]);

    const workspacesRequest = useCallback(() => getJiraWorkspaces(), []);

    const [workspacesState] = useFetchDataAndSetState(workspacesRequest, [
        selectedOrganisationHash,
    ]);

    useEffect(() => {
        localStorage.removeItem('SiPrevFilter');
        localStorage.setItem('SiPrevOrg', selectedOrganisationHash);
        localStorage.setItem('SiPrevDatestart', dateTimeStart);

        return function cleanup() {
            localStorage.removeItem('SiPrevFilter');
            localStorage.removeItem('SiPrevOrg');
            localStorage.removeItem('SiPrevDatestart');
        };
    }, []);

    useEffect(() => {
        if (chartDataError) {
            localStorage.removeItem('SiPrevFilter');
            localStorage.removeItem('SiPrevOrg');
            localStorage.removeItem('SiPrevDatestart');
        }
    }, [chartDataError]);

    useEffect(() => {
        tasksState?.data?.tickets &&
            setDisplayedTickets(tasksState.data.tickets);
        setCurrentPage(1);
        setCurrentOffset(0);
        setMoreTicketsError(false);
    }, [tasksState.data]);

    useEffect(() => {
        latestTicketDate = dateTimeEnd;
    }, [dateTimeEnd]);

    useEffect(() => {
        setDisplayedTickets([]);
        setChartDataError(null);
    }, [filterParams, selectedOrganisationHash, dateTimeStart]);

    useEffect(() => {
        setAllTickets({});
    }, [selectedOrganisationHash, dateTimeStart]);

    useEffect(() => {
        setIsNoMoreTickets(false);
        setIsChartUpdateAvaliable(false);

        const prevOrgHash =
            localStorage.getItem('SiPrevOrg') === selectedOrganisationHash
                ? selectedOrganisationHash
                : localStorage.getItem('SiPrevOrg');
        const prevFilter =
            localStorage.getItem('SiPrevFilter') ===
            JSON.stringify(filterParams)
                ? JSON.stringify(filterParams)
                : localStorage.getItem('SiPrevFilter');
        const predDateStart =
            localStorage.getItem('SiPrevStart') ===
            JSON.stringify(dateTimeStart)
                ? JSON.stringify(dateTimeStart)
                : localStorage.getItem('SiPrevStart');

        void fetchAllTickets(prevFilter, prevOrgHash, predDateStart);

        localStorage.setItem(
            'SiPrevOrg',
            JSON.stringify(selectedOrganisationHash)
        );
        localStorage.setItem('SiPrevDatestart', JSON.stringify(dateTimeStart));
        localStorage.setItem('SiPrevFilter', JSON.stringify(filterParams));
    }, [selectedOrganisationHash, dateTimeStart, filterParams]);

    useEffect(() => {
        setIsMoreTicketsLoading(false);
    }, [displayedTickets]);

    useEffect(() => {
        isNoMoreTickets &&
            showNotification('No more tickets to add', 'success');
    }, [isNoMoreTickets]);

    let pagesCount = 1;
    let offset = 0;

    async function fetchAllTickets(prevParams, prevOrg, prevDatestart) {
        if (
            prevParams === JSON.stringify(filterParams) &&
            prevOrg === selectedOrganisationHash &&
            prevDatestart === dateTimeStart
        ) {
            return;
        }

        pagesCount === 1 && setIsChartDataLoading(true);
        pagesCount > 1 && setIsChartDataUpdating(true);

        try {
            const res = await getTasks(
                dateTimeStart,
                latestTicketDate,
                offset,
                filterParams
            );

            if (
                res.status === 204 &&
                (dateTimeStart !== prevDatestart ||
                    selectedOrganisationHash !== prevOrg)
            ) {
                console.log('204 but different org or timeperiod');
                setAllTickets({});
            }

            if (res.status === 204 && pagesCount === 1) {
                setAllTickets({});
            }

            if (res.status === 200) {
                const pageCountToSet = pagesCount;
                setAllTickets((prevState) => {
                    return {
                        ...prevState,
                        [pageCountToSet]: {
                            stringifiedParams: JSON.stringify(filterParams),
                            tickets: res.data.tickets,
                            features: res.data.features,
                            'not features': res.data.not_features,
                            hours: res.data.inferred_hours,
                            time_spent: res.data.time_spent,
                        },
                    };
                });
                pagesCount === 1 && setIsChartDataLoading(false);
                pagesCount > 1 && setIsChartDataUpdating(false);

                pagesCount += 1;
                offset += limit;
                await fetchAllTickets(
                    null,
                    selectedOrganisationHash,
                    dateTimeStart
                );
            } else {
                pagesCount > 1 && setIsChartUpdateAvaliable(false);
                setIsChartDataLoading(false);
                setIsChartDataUpdating(false);
                return;
            }
        } catch (error) {
            setChartDataError(error);
            setIsChartDataLoading(false);
            setIsChartDataUpdating(false);
        }
    }

    async function fetchNextPage() {
        const res = await getTasks(
            dateTimeStart,
            dateTimeEnd,
            currentOffset + limit,
            filterParams
        );
        return res;
    }

    function loadMoreTickets() {
        setIsMoreTicketsLoading(true);
        setMoreTicketsError(false);
        let ticketsToAdd = allTickets[currentPage + 1];
        if (
            ticketsToAdd &&
            ticketsToAdd?.stringifiedParams === JSON.stringify(filterParams)
        ) {
            setDisplayedTickets([...displayedTickets, ...ticketsToAdd.tickets]);
            setCurrentPage(currentPage + 1);
            setCurrentOffset(currentOffset + limit);
        } else {
            fetchNextPage()
                .then((response) => {
                    if (response.status === 200) {
                        setDisplayedTickets([
                            ...displayedTickets,
                            ...response.data.tickets,
                        ]);
                        setCurrentPage(currentPage + 1);
                        setCurrentOffset(currentOffset + limit);
                    }

                    response.status === 204 && setIsNoMoreTickets(true);
                    setIsMoreTicketsLoading(false);
                })
                .catch((error) => {
                    console.log('fetching page error', error);
                    setMoreTicketsError(true);
                    setIsMoreTicketsLoading(false);
                });
        }
    }

    const [modalState, setModalState] = useState(false);
    const [isDefaultOption, setIsDefaultOption] = useState(true);

    const [selectedTasks, setSelectedTasks] = useState({
        issueKeys: [],
        action: '',
    });

    const [selectedLabel, setSelectedLabel] = useState({
        value: 'default',
        label: 'Select label',
    });

    const [selectedAction, setSelectedAction] = useState({
        value: 'default',
        label: 'Select action',
    });

    useEffect(() => {
        if (selectedLabel.value !== 'default') {
            setIsDefaultOption(false);
        }
    }, [selectedLabel]);

    const filterIconRef = useRef(null);

    const handleClickOutside = (event) => {
        if (
            filterIconRef.current &&
            !filterIconRef.current.contains(event.target) &&
            event?.target?.offsetParent?.id !== 'columns-filter-popup'
        ) {
            setIsTableSettings(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    // filtering tickets data for  charts
    let filteredTickets = {};
    for (let key in allTickets) {
        if (
            allTickets[key].stringifiedParams === JSON.stringify(filterParams)
        ) {
            filteredTickets = {
                ...filteredTickets,
                [key]: allTickets[key],
            };
        }
    }

    // extracting data for features chart
    let featuresAmount = 0;
    let notFeaturesAmount = 0;
    let featuresTime = 0;
    let notFeaturesTime = 0;

    for (let key in filteredTickets) {
        featuresAmount += filteredTickets[key].features;
        notFeaturesAmount += filteredTickets[key]['not features'];
        featuresTime += filteredTickets[key].time_spent.feature;
        notFeaturesTime += filteredTickets[key].time_spent['not feature'];
    }

    const featuresChartData = {
        [`${t('si_page.features')}`]: featuresAmount,
        [`${t('common.not')} ${t('si_page.features')}`]: notFeaturesAmount,
    };

    const hoursChartData = {
        [`${t('si_page.features')}`]: featuresTime,
        [`${t('common.not')} ${t('si_page.features')}`]: notFeaturesTime,
    };

    // TODO: make a condition or charts
    useEffect(() => {
        if (selectedOrganisationHash !== demoOrgHash) return;
        if (isChartDataLoading) return;
        if (wasDemoTriggered) return;
        if (query.get('tour') !== 'true') return;

        setTimeout(() => {
            setIsOpen(true);
            setWasDemoTriggered(true);
        }, 2000);

        return () => setIsOpen(false);
    }, [isChartDataLoading]);

    const labelsType = [
        { type: 'Feature', name: 'Feature' },
        { type: 'Not feature', name: 'Not Feature' },
    ];

    const handleLabelChange = (option) => {
        setSelectedLabel(option);
    };

    const actionsType = [{ type: 'train', name: 'Train' }];

    const handleActionChange = (option) => {
        setSelectedAction(option);
        setSelectedTasks((prevState) => {
            return { ...prevState, action: option.value };
        });
    };

    useEffect(() => {
        if (selectedTasks.issueKeys.length && selectedTasks.action) {
            setModalState(true);
        }
    }, [selectedTasks]);

    useEffect(() => {
        if (selectedLabel.value !== 'default') {
            setIsDefaultOption(false);
        }
    }, [selectedLabel]);

    return (
        <>
            <div className="container  mx-auto ">
                {!workspacesState?.data?.length &&
                !workspacesState.loading &&
                selectedOrganisationHash !== demoOrgHash ? (
                    <SettingsMessage
                        messageText={t('settingsMessages.no_ticket_feed')}
                        link={NAVIGATION_PATHS.settingsSprintInsights}
                        linkText={capitalizeFirstLetter(
                            t('settingsMessages.go_to_si_settings')
                        )}
                    />
                ) : null}

                <div className="">
                    <SiPrevFilter
                        filterOptions={filterParams}
                        onChangeFilter={setFilterParams}
                    />
                </div>
                <div
                    className="flex gap-x-8 mt-8 mb-8 h-72"
                    data-tour="r-step-page-5"
                >
                    <WhitePanelContentWrapper className="w-5/12 relative">
                        {isChartDataUpdating && (
                            <p className="text-gray-500 leading-6 text-sm absolute top-0 right-0 p-4 z-20">
                                Updating data...
                            </p>
                        )}
                        <PanelTitle
                            title={capitalizeEveryWordFirstLetter(
                                t('si_page.count_chart')
                            )}
                        />
                        <SprintInsightChart
                            data={featuresChartData}
                            error={chartDataError}
                            loading={isChartDataLoading}
                            updateAvailable={isChartUpdateAvaliable}
                            setUpdateAvailable={setIsChartUpdateAvaliable}
                        />
                    </WhitePanelContentWrapper>
                    <WhitePanelContentWrapper className="w-5/12 relative">
                        {isChartDataUpdating && (
                            <p className="text-gray-500 leading-6 text-sm absolute top-0 right-0 p-4 z-20">
                                Updating data...
                            </p>
                        )}
                        <PanelTitle
                            title={capitalizeEveryWordFirstLetter(
                                t('si_page.time_chart')
                            )}
                            underlineColorType="tertiary"
                        />
                        <SprintInsightChart
                            data={hoursChartData}
                            error={chartDataError}
                            loading={isChartDataLoading}
                            updateAvailable={isChartUpdateAvaliable}
                            setUpdateAvailable={setIsChartUpdateAvaliable}
                        />
                    </WhitePanelContentWrapper>
                </div>

                <WhitePanelContentWrapper className="mb-20 fit-content w-full">
                    <div data-tour="">
                        <div className="flex items-center mb-4">
                            <PanelTitle
                                title={capitalizeFirstLetter(
                                    t('si_page.title')
                                )}
                                underlineColorType="tertiary"
                                className="mb-0"
                            />

                            <div className="ml-auto flex gap-x-2 items-center">
                                <button
                                    ref={filterIconRef}
                                    type="button"
                                    className="p-1 h-full"
                                    onClick={() =>
                                        setIsTableSettings(!isTableSettings)
                                    }
                                >
                                    <VscSettings
                                        size={24}
                                        className={`${
                                            isTableSettings
                                                ? 'text-gray-600'
                                                : 'text-gray-500'
                                        } hover:text-gray-600 focus:text-gray-600`}
                                    />
                                </button>
                                <SISelect
                                    selectedOption={selectedAction}
                                    options={actionsType}
                                    handleOptionChange={handleActionChange}
                                />
                            </div>
                        </div>
                        <div className="relative">
                            <TasksTable
                                setSelectedTasks={setSelectedTasks}
                                data={displayedTickets}
                                error={tasksState.error}
                                loading={tasksState.loading}
                                isTableSettings={isTableSettings}
                                setIsTableSettings={setIsTableSettings}
                                selectedOrganisationHash={
                                    selectedOrganisationHash
                                }
                            />
                            {moreTicketsError &&
                                !tasksState.loading &&
                                !tasksState.error && (
                                    <p className="mx-auto mt-2 text-center text-red-text text-sm">
                                        Failed to fetch more tickets
                                    </p>
                                )}
                            <div className="mt-2 flex justify-center items-center">
                                {isMoreTicketsLoading ? (
                                    <Loader
                                        color={'#C2C7D7'}
                                        size={35}
                                        speedMultiplier={0.8}
                                    />
                                ) : displayedTickets.length &&
                                  !isNoMoreTickets &&
                                  !tasksState.loading &&
                                  !tasksState.error ? (
                                    <button
                                        className="px-4 py-2  btn bg-theme-tertiary rounded text-white font-display leading-6 font-medium"
                                        onClick={() => {
                                            loadMoreTickets();
                                        }}
                                    >
                                        {capitalizeFirstLetter(
                                            t('button.show_more')
                                        )}
                                    </button>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </div>
                </WhitePanelContentWrapper>

                <ModalWrapper
                    modalState={modalState}
                    setModalState={setModalState}
                    title={'Train ticket'}
                >
                    <ConfirmationModalContent
                        modalTextBody={`Do you want to train ${selectedTasks.issueKeys
                            .slice(0, 5)
                            .map((key) => `${key}`)} ${
                            selectedTasks.issueKeys.length > 5 ? 'etc.' : ''
                        } ticket(s)?`}
                        successNotification={`Tickets with label ${selectedLabel.value} has been sent`}
                        mainColorClass={'custom-blue'}
                        actionTextBtn={'Train'}
                        request={() =>
                            Promise.all(
                                selectedTasks.issueKeys.map((key) =>
                                    trainTasks(key, selectedLabel.value)
                                )
                            )
                        }
                        isDisabledActionBtn={isDefaultOption}
                        ModalExtraBody={() => {
                            return (
                                <div className="mt-4">
                                    <SISelect
                                        selectedOption={selectedLabel}
                                        options={labelsType}
                                        handleOptionChange={handleLabelChange}
                                    />
                                </div>
                            );
                        }}
                        setModalState={setModalState}
                    />
                </ModalWrapper>
            </div>
        </>
    );
};

export default withUserAndOragnisations(SprintInsightsPage);
