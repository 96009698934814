import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CrossInCircle, ArrowLeft } from '../../assets/svg.js';
import _ from 'lodash';
import Loader from '../loader';
import '../settings/settings-tabs/styles.scss';
import ErrorIndicator from '../error-indicator/index.js';
import { capitalizeFirstLetter } from '../../helpers/textFormatters.js';
import defaultUser from '../../assets/user.jpg';
import { AiOutlinePlus } from 'react-icons/ai';

const TeamUsersForm = ({
    users,
    usersState,
    setFieldTouched,
    setFieldValue,
}) => {
    const { t } = useTranslation();

    const [usersData, setUsersData] = useState({
        data: [],
    });
    const [filter, setFilter] = useState('');
    const [sortedUsersData, setSortedUsersData] = useState([]);
    const [filteredUsersData, setFilteredUsersData] = useState([]);

    useEffect(() => {
        let canceled = false;
        if (usersState.data && usersState.data.length !== 0) {
            if (!canceled)
                setUsersData({
                    data: usersState.data.map((user) => {
                        let userHash = user.hash.split(' ').join('');
                        let isOnTeam =
                            users.findIndex(
                                (userInTeam) =>
                                    userInTeam.split(' ').join('') === userHash
                            ) + 1;

                        if (isOnTeam) {
                            return {
                                ...user,
                                teamUser: true,
                                checked: false,
                            };
                        } else {
                            return {
                                ...user,
                                teamUser: false,
                                checked: false,
                            };
                        }
                    }),
                });
        }

        return () => (canceled = true);
    }, [usersState.data]);

    useEffect(() => {
        const sortedChecked = usersData.data
            .filter((user) => user.checked)
            .sort((firstUser, secondUser) => {
                if (
                    firstUser.user_name.toLowerCase() <
                    secondUser.user_name.toLowerCase()
                ) {
                    return -1;
                }
                if (
                    firstUser.user_name.toLowerCase() >
                    secondUser.user_name.toLowerCase()
                ) {
                    return 1;
                }

                // names must be equal
                return 0;
            });

        const sortedUnchecked = usersData.data
            .filter((user) => !user.checked)
            .sort((firstUser, secondUser) => {
                if (
                    firstUser.user_name.toLowerCase() <
                    secondUser.user_name.toLowerCase()
                ) {
                    return -1;
                }
                if (
                    firstUser.user_name.toLowerCase() >
                    secondUser.user_name.toLowerCase()
                ) {
                    return 1;
                }

                // names must be equal
                return 0;
            });

        const sorted = [...sortedChecked, ...sortedUnchecked];
        setSortedUsersData(sorted);
    }, [usersData]);

    useEffect(() => {
        if (!filter || !filter.trim().length) {
            setFilteredUsersData(sortedUsersData);
        } else {
            const formattedFilter = filter.trim().toLocaleLowerCase();
            const filtered = sortedUsersData.filter(
                (user) =>
                    user.first_name?.toLowerCase()?.includes(formattedFilter) ||
                    user.last_name?.toLowerCase()?.includes(formattedFilter) ||
                    user.email?.toLowerCase()?.includes(formattedFilter) ||
                    user.user_name?.toLowerCase()?.includes(formattedFilter)
            );
            setFilteredUsersData(filtered);
        }
    }, [sortedUsersData, filter]);

    const setCheck = (userHash) => {
        setUsersData({
            data: usersData.data.map((user) => {
                if (user.hash === userHash) {
                    if (user.checked) {
                        setFieldValue(
                            'users',
                            users.filter((hash) => hash !== userHash)
                        );
                    } else {
                        setFieldValue('users', [...users, userHash]);
                    }
                    user.checked = !user.checked;
                    user.teamUser = !user.teamUser;
                }

                return user;
            }),
        });
    };

    const removeUserFromTeam = (userHash) => {
        setUsersData({
            data: usersData.data.map((user) => {
                if (user.hash === userHash) {
                    user.teamUser = false;
                    user.checked = false;
                }
                return user;
            }),
        });

        setFieldValue(
            'users',
            users.filter((hash) => hash !== userHash)
        );
    };

    const createUsersList = () => {
        if (usersState.error) {
            return <ErrorIndicator error={usersState.error} />;
        }
        if (usersState.loading) {
            return <Loader />;
        }
        if (usersData.data.length === 0) {
            return (
                <li className="user">
                    <p className="user-name">This organisation has no users</p>
                </li>
            );
        }

        return filteredUsersData
            .filter((item) => !item.teamUser)
            .map((user) => {
                return (
                    <li
                        className="flex justify-between p-2 items-center cursor-pointer hover:bg-gray-400 hover:bg-opacity-10"
                        key={`${user.hash}`}
                        onClick={() => setCheck(user.hash)}
                    >
                        <div className="flex gap-4 items-center">
                            <div
                                className={`w-10 h-10 rounded-full overflow-hidden 
                                `}
                            >
                                <img
                                    src={user?.photo ? user.photo : defaultUser}
                                    alt="User"
                                />
                            </div>
                            <p className="">
                                {user.user_name ? user.user_name : user.email}
                            </p>
                        </div>
                        <div className="w-6 h-6 rounded-full border border-solid border-gray-400 flex justify-center items-center">
                            <AiOutlinePlus color="#C2C7D7" />
                        </div>
                    </li>
                );
            });
    };

    const createTeamUsersList = () => {
        if (usersState.loading) return <Loader />;

        if (usersState.error) return <ErrorIndicator />;

        const usersInTeam = usersData.data
            .filter((user) => user.teamUser)
            .sort((firstUser, secondUser) => {
                if (
                    firstUser.user_name.toLowerCase() <
                    secondUser.user_name.toLowerCase()
                ) {
                    return -1;
                }
                if (
                    firstUser.user_name.toLowerCase() >
                    secondUser.user_name.toLowerCase()
                ) {
                    return 1;
                }

                // names must be equal
                return 0;
            });

        if (usersInTeam.length === 0) {
            return (
                <li
                    className="h-full w-full flex items-center justify-center"
                    key={`no-user`}
                >
                    <p className="text-center text-gray-400 p-8">
                        No users in team
                    </p>
                </li>
            );
        } else {
            return usersInTeam.map((user) => {
                return (
                    <li
                        className="p-2 flex gap-4 items-center justify-between"
                        key={`${user.hash}`}
                    >
                        <div className="flex gap-4 items-center">
                            <div
                                className={`w-10 h-10 rounded-full overflow-hidden 
                                `}
                            >
                                <img
                                    src={user?.photo ? user.photo : defaultUser}
                                    alt="User"
                                />
                            </div>
                            <p className="">
                                {user.user_name ? user.user_name : user.email}
                            </p>
                        </div>
                        <div className="cursor-pointer">
                            <button
                                className="remove-user"
                                onClick={() => removeUserFromTeam(user.hash)}
                            >
                                <CrossInCircle
                                    fill="#EE8282"
                                    transform=""
                                    width={24}
                                />
                            </button>
                        </div>
                    </li>
                );
            });
        }
    };

    return (
        <>
            <div className="flex gap-4 justify-between items-stretch">
                <div className={'w-5/12 flex flex-col'}>
                    <p className="mb-4 font-medium text-xl ">
                        {capitalizeFirstLetter('Users in team')}
                    </p>
                    <ul className="max-h-[460px] rounded border border-solid flex flex-col border-gray-400 divide-y divide-solid divide-gray-400  overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded-full hover:scrollbar-thumb-gray-400 ">
                        {createTeamUsersList()}
                    </ul>
                </div>
                <ArrowLeft fill={'#484a53'} />
                <div className="w-5/12 flex flex-col">
                    <p className="mb-4 font-medium text-xl ">
                        {capitalizeFirstLetter(
                            t('settings_page.teams_tab.all')
                        )}
                    </p>
                    <input
                        className="p-2 placeholder-gray-300 text-gray-700 relative bg-white
             rounded text-md border border-gray-300 outline-none focus:outline-none focus:ring w-full disabled:bg-gray-300 mb-4"
                        id={filter}
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                        placeholder="Filter..."
                    />
                    <ul className="max-h-[460px] rounded border border-solid flex flex-col border-gray-400 divide-y divide-solid divide-gray-400  overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded-full hover:scrollbar-thumb-gray-400">
                        {createUsersList()}
                    </ul>
                </div>
            </div>
        </>
    );
};

export default TeamUsersForm;
