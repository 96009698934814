import React, { useState, useContext, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useTour } from '@reactour/tour';
import Header from '../header';
import {
    AdminPage,
    HomePage,
    SettingsPage,
    OverviewPage,
    KudosPage,
    LeaksPage,
    StockPage,
    RepositoriesPage,
    UserSettingsPage,
    SprintInsightsPage,
    SREPage,
} from '../../pages';
import { UserContext } from '../../context/UserContext';
import { OrganisationsContext } from '../../context/OrganisationsContext';
import { useTranslation } from 'react-i18next';
import { getUserData } from '../../api/user/UserAPI';
import HoursPage from '../../pages/hours-page';
import SidebarNavigation from '../sidebar-navigation';
import Loader from '../loader';
import {
    TourGuideSteps,
    overviewFirstStep,
    // overviewSecondStep,
    kudosFirstStep,
    kudosSecondStep,
    kudosThirdStep,
    sreFirstStep,
    sreSecondStep,
    sprintInsightsFirstStep,
    leaksFirstStep,
    leaksSecondStep,
    stockFirstStep,
    stockSecondStep,
    stockThirdStep,
    teamsFirstStep,
    doraFirstStep,
    doraSecondStep,
} from '../../helpers/tourGuideSteps';
import CreateOrganisationPage from '../../pages/create-organisation-page';
import { NAVIGATION_PATHS } from '../../constants';
import RestoreOrgScreen from '../restore-org-screen';
import { momentLocaleSwitcher } from '../../helpers/momentLocaleSwitcher';
import SettingsMessage from '../settings-message';
import TeamsPage from '../../pages/teams-page';
import TeamsSprintReportPage from '../../pages/teams-sprint-report-page';

export const Content = ({ orgHashFromUrl }) => {
    const location = useLocation();
    const { setSteps, setCurrentStep, isOpen } = useTour();
    const [isLoading, setIsLoading] = useState(true);
    const { userState, userDispatch } = useContext(UserContext);
    const { organisationsState, organisationsDispatch } =
        useContext(OrganisationsContext);
    const { i18n } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(true);
    const [startTourGuide, setStartTourGuide] = useState(false);
    const [showLicenseWarning, setShowLicenseWarning] = useState(true);
    const demoOrgHash = process.env.REACT_APP_DEMO_ORG_HASH;

    const selectedOrgData = organisationsState?.data
        ? organisationsState.data.find((org) => org.active)
        : null;

    const selectedOrganisationHash = selectedOrgData
        ? selectedOrgData.org_hash
        : null;

    const selectedOrganisationStatus = selectedOrgData
        ? selectedOrgData?.status
        : null;

    const licenseStatusWarnings = {
        pending: {
            text: "Your payment has failed. Don't worry, we will try again over the next few days.",
            isLink: false,
            type: 'yellow',
        },
        failed: {
            text: 'Unfortunately, your payment has failed a number of times. Contact support or resume payment manually to keep using Agile Analytics.',
            isLink: true,
            type: 'red',
        },
        cancelled: {
            text: 'Unfortunately, your payment has failed a number of times. Contact support or resume payment manually to keep using Agile Analytics.',
            isLink: true,
            type: 'red',
        },
    };

    useEffect(() => {
        setCurrentStep(0);
        if (location.pathname === NAVIGATION_PATHS.overview) {
            setSteps([
                {
                    selector: '[data-tour="r-step-page"]',
                    content: overviewFirstStep,
                },
                // {
                //     selector: '[data-tour="r-step-page"]',
                //     content: overviewSecondStep,
                // },
            ]);
        } else if (location.pathname === NAVIGATION_PATHS.teams) {
            setSteps([
                {
                    selector: '[data-tour="r-step-page-1-1"]',
                    content: teamsFirstStep,
                },
            ]);
        } else if (location.pathname === NAVIGATION_PATHS.kudos) {
            setSteps([
                {
                    selector: '[data-tour="r-step-page-2"]',
                    content: kudosFirstStep,
                },
                {
                    selector: '[data-tour="r-step-page-3"]',
                    content: kudosSecondStep,
                },
                {
                    selector: '[data-tour="r-step-page-3-1"]',
                    content: kudosThirdStep,
                },
            ]);
        } else if (location.pathname === NAVIGATION_PATHS.sre) {
            setSteps([
                {
                    selector: '[data-tour="r-step-page-4"]',
                    content: sreFirstStep,
                },
                {
                    selector: '[data-tour-2="r-step-page-5"]',
                    content: sreSecondStep,
                },
            ]);
        } else if (location.pathname === NAVIGATION_PATHS.sprintInsights) {
            setSteps([
                {
                    selector: '[data-tour="r-step-page-5"]',
                    content: sprintInsightsFirstStep,
                },
            ]);
        } else if (location.pathname === NAVIGATION_PATHS.leaks) {
            setSteps([
                {
                    selector: '[data-tour="r-step-page-8"]',
                    content: leaksFirstStep,
                },
                {
                    selector: '[data-tour="r-step-page-9"]',
                    content: leaksSecondStep,
                },
            ]);
        } else if (location.pathname === NAVIGATION_PATHS.stock) {
            setSteps([
                {
                    selector: '[data-tour="r-step-page-10"]',
                    content: stockFirstStep,
                },
                {
                    selector: '[data-tour="r-step-page-10"]',
                    content: stockSecondStep,
                },
                {
                    selector: '[data-tour="r-step-page-11"]',
                    content: stockThirdStep,
                },
            ]);
        } else if (location.pathname === NAVIGATION_PATHS.dora) {
            setSteps([
                {
                    selector: '[data-tour="r-step-page-12"]',
                    content: doraFirstStep,
                },
                {
                    selector: '[data-tour="r-step-page-13"]',
                    content: doraSecondStep,
                },
            ]);
        } else {
            setSteps(TourGuideSteps);
        }
    }, [location.pathname, setCurrentStep, setSteps]);

    useEffect(() => {
        let canceled = false;

        getUserData(orgHashFromUrl ? orgHashFromUrl : null)
            .then((response) => {
                const {
                    user_hash = '',
                    user_org_hash = '',
                    email = '',
                    first_name = '',
                    last_name = '',
                    lang = 'en',
                    photo = null,
                    superadmin = false,
                    organisations = null,
                } = response.data;

                let orgList = null;
                if (
                    organisations.find(
                        (org) =>
                            org.org_hash === localStorage.getItem('orgHash')
                    )
                ) {
                    // if there is valid orgHash in local storage, set it as active
                    orgList = organisations.map((org) => {
                        const currentOrg = localStorage.getItem('orgHash');
                        if (currentOrg) {
                            return org.org_hash === currentOrg
                                ? { ...org, active: true }
                                : { ...org, active: false };
                        }
                        return org.org_hash === user_org_hash
                            ? { ...org, active: true }
                            : { ...org, active: false };
                    });
                } else if (
                    organisations.find(
                        (org) =>
                            org.org_hash === localStorage.getItem('baseOrgHash')
                    )
                ) {
                    // if there is no valid orgHash in local storage, but there is valid baseOrgHash, set it as active
                    localStorage.setItem(
                        'orgHash',
                        localStorage.getItem('baseOrgHash')
                    );
                    orgList = organisations.map((org) => {
                        const currentOrg = localStorage.getItem('baseOrgHash');
                        if (currentOrg) {
                            return org.org_hash === currentOrg
                                ? { ...org, active: true }
                                : { ...org, active: false };
                        }
                        return org.org_hash === user_org_hash
                            ? { ...org, active: true }
                            : { ...org, active: false };
                    });
                } else {
                    // if there is no valid orgor baseOrg hash in storage, try to set demo org as active

                    if (
                        organisations.find(
                            (org) => org.org_hash === demoOrgHash
                        )
                    ) {
                        localStorage.setItem('orgHash', demoOrgHash);
                        orgList = organisations.map((org) =>
                            org.org_hash === demoOrgHash
                                ? { ...org, active: true }
                                : { ...org, active: false }
                        );
                    } else if (organisations?.length) {
                        // if there are any organisations, set first one as active
                        orgList = organisations.map((org, i) =>
                            i === 0
                                ? { ...org, active: true }
                                : { ...org, active: false }
                        );
                    }
                }

                const isLocalOrgHashValid = organisations.find(
                    (org) => org.org_hash === localStorage.getItem('orgHash')
                );

                if (
                    !localStorage.getItem('orgHash') ||
                    localStorage.getItem('orgHash') === 'null' ||
                    !isLocalOrgHashValid
                ) {
                    localStorage.setItem('orgHash', user_org_hash);
                }
                localStorage.setItem('baseOrgHash', user_org_hash);
                localStorage.setItem('email', email);

                if (!canceled) {
                    userDispatch({
                        type: 'SET_DATA',
                        payload: {
                            user_hash,
                            user_org_hash,
                            email,
                            photo: `data:image/jpeg;base64,${photo}`,
                            first_name,
                            last_name,
                            lang,
                            superadmin,
                        },
                    });
                    organisationsDispatch({
                        type: 'SET_DATA',
                        payload: orgList,
                    });
                }

                i18n.changeLanguage(lang);

                return lang;
            })
            .then((lang) => {
                momentLocaleSwitcher(lang);
                setIsLoading(false);
            })
            .catch((error) => {
                if (!canceled) {
                    userDispatch({
                        type: 'SET_ERROR',
                        payload: error.response || error,
                    });
                    organisationsDispatch({
                        type: 'SET_ERROR',
                        payload: error.response || error,
                    });
                }
                setIsLoading(false);
            });

        return () => (canceled = true);
    }, [orgHashFromUrl]);

    useEffect(() => {
        setStartTourGuide(isOpen);
    }, [isOpen]);

    useEffect(() => {
        if (selectedOrgData?.license?.status !== 'active') {
            setShowLicenseWarning(true);
        }
    }, [selectedOrgData]);

    if (isLoading)
        return (
            <div className="w-screen h-screen flex items-center justify-center">
                <Loader size={48} />
            </div>
        );
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className="flex min-h-518 h-screen">
            <SidebarNavigation isOpen={isMenuOpen} />
            <div className="flex-grow page-wrapper">
                <Header
                    onToggleMenu={toggleMenu}
                    onTourGuideStart={setStartTourGuide}
                    startTourGuide={startTourGuide}
                />

                {selectedOrganisationHash ? (
                    selectedOrganisationStatus === 'removed' ? (
                        <RestoreOrgScreen />
                    ) : (
                        <div className="page-content-wrapper bg-gray-bg relative">
                            <div className="container px-4 pt-10 pb-6 mx-auto ">
                                {showLicenseWarning &&
                                licenseStatusWarnings[
                                    selectedOrgData?.license?.status
                                ] ? (
                                    <SettingsMessage
                                        messageText={
                                            licenseStatusWarnings[
                                                selectedOrgData?.license?.status
                                            ].text
                                        }
                                        link={
                                            licenseStatusWarnings[
                                                selectedOrgData?.license?.status
                                            ].isLink
                                                ? `${NAVIGATION_PATHS.settingsOrganisation}#manage-plan`
                                                : null
                                        }
                                        linkText="Go to payment"
                                        type={
                                            licenseStatusWarnings[
                                                selectedOrgData?.license?.status
                                            ].type
                                        }
                                    />
                                ) : null}
                                <Routes>
                                    <Route
                                        path={`${NAVIGATION_PATHS.settings}/*`}
                                        element={<SettingsPage />}
                                    />
                                    <Route
                                        path={NAVIGATION_PATHS.admin}
                                        exact
                                        element={<AdminPage />}
                                    />
                                    <Route
                                        path={NAVIGATION_PATHS.overview}
                                        exact
                                        element={
                                            <OverviewPage
                                                startTourGuide={startTourGuide}
                                                setStartTourGuide={
                                                    setStartTourGuide
                                                }
                                            />
                                        }
                                    />
                                    <Route
                                        path={
                                            NAVIGATION_PATHS.createOrganisation
                                        }
                                        exact
                                        element={<CreateOrganisationPage />}
                                    />
                                    <Route
                                        path={NAVIGATION_PATHS.kudos}
                                        exact
                                        element={<KudosPage />}
                                    />
                                    <Route
                                        path={`${NAVIGATION_PATHS.hours}/*`}
                                        element={<HoursPage />}
                                    />
                                    <Route
                                        path={NAVIGATION_PATHS.stock}
                                        exact
                                        element={<StockPage />}
                                    />
                                    <Route
                                        path={NAVIGATION_PATHS.sre}
                                        exact
                                        element={<SREPage />}
                                    />
                                    <Route
                                        path={NAVIGATION_PATHS.leaks}
                                        exact
                                        element={<LeaksPage />}
                                    />
                                    <Route
                                        path={NAVIGATION_PATHS.sprintInsights}
                                        exact
                                        element={<SprintInsightsPage />}
                                    />
                                    {/* <Route
                                    path={NAVIGATION_PATHS.repositories}
                                    exact
                                    element={<RepositoriesPage />}
                                /> */}
                                    <Route
                                        path={NAVIGATION_PATHS.dora}
                                        exact
                                        element={<RepositoriesPage />}
                                    />
                                    <Route
                                        path={NAVIGATION_PATHS.teams}
                                        exact
                                        element={<TeamsPage />}
                                    />
                                    <Route
                                        path="/teams/:orgId/:teamId/:sprintStart/:sprintEnd"
                                        // the matching param will be available to the loader
                                        // loader={({ params }) => {
                                        //     console.log(params.teamId); // "hotspur"
                                        // }}
                                        // and the action
                                        // action={({ params }) => {}}
                                        element={<TeamsSprintReportPage />}
                                    />
                                    ;
                                    <Route
                                        path={NAVIGATION_PATHS.userSettings}
                                        exact
                                        element={<UserSettingsPage />}
                                    />
                                    {/* HAS TO BE THE LAST ONE */}
                                    <Route
                                        path={NAVIGATION_PATHS.home}
                                        element={<HomePage />}
                                    />
                                </Routes>
                            </div>
                        </div>
                    )
                ) : (
                    <div className=" px-8 py-4 text-center text-2xl text-gray-500 font-display">
                        Organisation is not found. Please contact your admin for
                        more details.
                    </div>
                )}
            </div>
            {/* <Footer /> */}
        </div>
    );
};
