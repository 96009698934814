import React from 'react';
import user from '../../../../../assets/user.jpg';
import data from '@emoji-mart/data';
import { init } from 'emoji-mart';

init({ data });

const LeaderbordBodyRow = ({
    person,
    rank,
    usersList,
    emoji,
    emojiQuantity,
    index,
}) => {
    const getRank = (title) => {
        if (index !== 0) {
            const prevRank = usersList[index - 1].rank;

            if (rank === prevRank || emojiQuantity === 0) return '';

            if (title) {
                return rank;
            } else {
                return `${rank}.`;
            }
        } else {
            return rank;
        }
    };

    const styles = {
        1: 'green-500',
        2: 'green-400',
        3: 'red-300',
        4: 'gray-600',
        5: 'gray-600',
    };
    return (
        <li
            className={`rank-${
                rank ? getRank('title') : index + 1
            } flex justify-between items-center mb-1.5 mr-4 text-gray-600`}
        >
            <div className="flex justify-between gap-x-8 items-center">
                <p
                    className={`font-display w-2 ${
                        styles[getRank('title')] &&
                        `text-${styles[getRank('title')]}`
                    }`}
                >
                    {rank ? getRank() : `${index + 1}.`}
                </p>
                <div className="flex items-center gap-x-2">
                    <div
                        className={`w-10 h-10 rounded-full overflow-hidden border border-solid ${
                            styles[getRank('title')]
                                ? `border-${styles[getRank('title')]}`
                                : 'border-gray-600'
                        }`}
                    >
                        <img
                            src={person?.photo ? person.photo : user}
                            alt="User"
                        />
                    </div>
                    <p
                        className={`font-medium leading-6 text-${
                            styles[getRank('title')]
                        }`}
                    >
                        {person?.user_name ? person.user_name : person?.email}
                    </p>
                </div>
            </div>
            <div className="flex gap-x-4 items-center txt-gray-600">
                <p
                    className={`font-display text-lg ${
                        styles[getRank('title')] &&
                        `text-${styles[getRank('title')]}`
                    }`}
                >
                    {emojiQuantity}
                </p>
                <div className="flex items-center">
                    {emoji.map((emoji) => (
                        <em-emoji
                            id={emoji}
                            key={emoji}
                            size={16}
                            set="google"
                        ></em-emoji>
                    ))}
                </div>
            </div>
        </li>
    );
};

export default LeaderbordBodyRow;
