import React from 'react';
import ButtonSecondary from './ButtonSecondary';

function ButtonTertiary({ children, extraClasses, onClick, title }) {
    return (
        <button
            className={`text-left text-gray-500 hover:underline ${extraClasses}`}
            onClick={onClick}
            type={'button'}
            title={title}
        >
            {children}
        </button>
    );
}

ButtonTertiary.defaultProps = {
    title: '',
};

export default ButtonTertiary;
