import React from 'react';
import { useField } from 'formik';

function InputTextarea({ autoComplete, className, ...props }) {
    const [field] = useField(props);

    return (
        <textarea
            className={`p-2 placeholder-gray-300 text-gray-700 relative bg-white
             rounded text-md border border-gray-300 outline-none focus:outline-none focus:ring w-full disabled:bg-gray-300 h-24 ${className}`}
            id={props.id || props.name}
            {...field}
            {...props}
        />
    );
}

InputTextarea.defaultProps = {
    autoComplete: 'off',
};

export default InputTextarea;
