import React, { useState, useContext, useCallback, useEffect } from 'react';
import { OrganisationsContext } from '../context/OrganisationsContext';
import SelectedRepositoriesListWrapper from '../components/selected-repositories-list';
import { withUserAndOragnisations } from '../helpers/withUser';
import { useDefaultStateWithReducer } from '../helpers/useDefaultStateWithReducer';
import { useFetchDataAndSetState } from '../helpers/useFetchDataAndSetState';
import {
    createRepositoryWebhook,
    getGitProviders,
} from '../api/settings/GitProviderAPI';
import { getSelectedRepositories } from '../api/swarm/SwarmAPI';
import WhitePanelContentWrapper from '../components/white-panel-content-wrapper';
import PanelTitle from '../components/panel-title';
import DoraMetricsPanel from '../components/dora-metrics-panel';
import SettingsMessage from '../components/settings-message';
import { NAVIGATION_PATHS } from '../constants/navigationPaths';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../helpers/textFormatters';
import ModalWrapper from '../components/modal-wrapper';
import AddRepositoryModalContent from '../components/add-repository-modal-content/index';
import showNotification from '../helpers/showNotification';
import { useTour } from '@reactour/tour';
import useParamsQuery from '../hooks/useParamsQuery';

const RepositoriesPage = () => {
    const { t } = useTranslation();
    const { setIsOpen } = useTour();
    const query = useParamsQuery();

    const { organisationsState } = useContext(OrganisationsContext);

    const selectedOrganisationHash = organisationsState.data
        ? organisationsState.data.find((org) => org.active).org_hash
        : null;

    const [gitProviderState] = useFetchDataAndSetState(getGitProviders, [
        selectedOrganisationHash,
    ]);

    const [reposUpdateCounter, setReposUpdateCounter] = useState(0);
    const [selectedRepositoriesState, selectedRepositoriesDispatch] =
        useFetchDataAndSetState(getSelectedRepositories, [
            selectedOrganisationHash,
            reposUpdateCounter,
        ]);

    const [providerRepositoriesState, providerRepositoriesDispatch] =
        useDefaultStateWithReducer();

    const providersRequest = useCallback(() => getGitProviders(), []);
    const [gitProvidersState] = useFetchDataAndSetState(providersRequest, [
        selectedOrganisationHash,
    ]);

    const [modalState, setModalState] = useState(false);
    const [addRepositoryLoading, setAddRepositoryLoading] = useState(false);
    const [noProvider, setNoProvider] = useState(false);

    useEffect(() => {
        query.get('tour') === 'true' &&
            setTimeout(() => {
                setIsOpen(true);
            }, 1000);
        return () => setIsOpen(false);
    }, []);

    const demoOrgHash = process.env.REACT_APP_DEMO_ORG_HASH;

    async function createAllWebhooks() {
        if (
            selectedRepositoriesState?.loading ||
            !selectedRepositoriesState?.data?.length
        ) {
            return;
        }
        const formattedReposArray = selectedRepositoriesState?.data?.reduce(
            (acc, provider) => {
                const providerRepos = provider?.repositories?.map((repo) => {
                    return {
                        provider_id: provider.provider_id,
                        repository_url: repo.url,
                    };
                });
                return [...acc, ...providerRepos];
            },
            []
        );

        try {
            selectedRepositoriesDispatch({
                type: 'SET_LOADING',
            });
            const res = await Promise.allSettled(
                formattedReposArray.map(async (repo) => {
                    const res = await createRepositoryWebhook(
                        repo.provider_id,
                        'some data',
                        {
                            params: repo,
                        }
                    );
                    return res;
                })
            );
            res.find((reqResult) => reqResult.status === 'rejected') &&
                showNotification(
                    'Failed to create webhooks for one or several repositories'
                );
            setReposUpdateCounter(reposUpdateCounter + 1);
            return formattedReposArray;
        } catch (error) {
            showNotification(
                'Failed to create webhooks for one or several repositories'
            );
            selectedRepositoriesDispatch({
                type: 'SET_DATA',
                payload: selectedRepositoriesState?.data,
            });

            return error;
        }
    }

    return (
        <div className="container mx-auto repositories-page">
            {!gitProvidersState?.data?.length &&
            !selectedRepositoriesState.loading &&
            !gitProvidersState.loading ? (
                <SettingsMessage
                    messageText="Seems like you haven't added your Git provider yet. Add your Git provider to start tracking Leaks, Stock & measuring DORA metrics."
                    link={NAVIGATION_PATHS.settingsGitProviders}
                    linkText="Go to Git Providers settings"
                />
            ) : null}
            {gitProvidersState?.data?.length &&
            !selectedRepositoriesState?.data?.length &&
            !selectedRepositoriesState.loading &&
            !gitProvidersState.loading ? (
                <SettingsMessage messageText="Click 'Add Repository' button to select some repositories and start measuring DORA metrics" />
            ) : null}
            <DoraMetricsPanel
                allRepos={selectedRepositoriesState.data}
                reposLoading={selectedRepositoriesState.loading}
                orgHash={selectedOrganisationHash}
            />
            <section className="" id="repositories-list">
                <WhitePanelContentWrapper className="selected-repositories-list grid grid-cols-12 my-5">
                    <div className="selected-repositories-list__header col-span-12 grid grid-cols-12 mt-2">
                        <div className="col-span-12 flex justify-between items-center w-full">
                            <PanelTitle
                                title={capitalizeFirstLetter(
                                    t('repo_page.title')
                                )}
                                underlineColorType="tertiary"
                                className="col-span-3 m-0"
                            />

                            {selectedOrganisationHash === demoOrgHash ? null : (
                                <div className="add-repositories selected-repositories-list__header m-0 items-center  flex gap-2 w-fit">
                                    <button
                                        type="button"
                                        className="  text-theme-tertiary border-solid rounded border-theme-tertiary font-display px-2 py-0"
                                        onClick={() => createAllWebhooks()}
                                    >
                                        Create webhooks for all
                                    </button>
                                    <button
                                        type="button"
                                        className="add-repository-toggler bg-theme-tertiary text-white border-0 font-display px-2 py-0 h-6"
                                        onClick={() => {
                                            setModalState(true);
                                            providerRepositoriesDispatch({
                                                type: 'SET_LOADING',
                                            });
                                        }}
                                    >
                                        {capitalizeFirstLetter(
                                            t('repo_page.add_repo')
                                        )}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="selected-repositories-list__body col-span-12">
                        <ModalWrapper
                            modalState={modalState}
                            setModalState={setModalState}
                            height={600}
                            title={capitalizeFirstLetter(
                                t('repo_page.add_repo')
                            )}
                            additionalOnCloseFunc={() => setNoProvider(false)}
                        >
                            <AddRepositoryModalContent
                                gitProviderState={gitProviderState}
                                selectedRepositoriesState={
                                    selectedRepositoriesState
                                }
                                selectedRepositoriesDispatch={
                                    selectedRepositoriesDispatch
                                }
                                setAddRepositoryLoading={
                                    setAddRepositoryLoading
                                }
                                noProvider={noProvider}
                                setNoProvider={setNoProvider}
                                setModalState={setModalState}
                            />
                        </ModalWrapper>

                        <SelectedRepositoriesListWrapper
                            selectedRepositoriesState={
                                selectedRepositoriesState
                            }
                            selectedRepositoriesDispatch={
                                selectedRepositoriesDispatch
                            }
                            addRepositoryLoading={addRepositoryLoading}
                            setAddRepositoryLoading={setAddRepositoryLoading}
                        />
                    </div>
                </WhitePanelContentWrapper>
            </section>
        </div>
    );
};

export default withUserAndOragnisations(RepositoriesPage);
