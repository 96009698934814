import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import Loader from '../../components/loader';
import useParamsQuery from '../../hooks/useParamsQuery';
import CreateOrganisationUserForm from '../../components/create-organisation-user-form';
import { ArrowRight } from '../../assets/svg';
import AuthCreateOrgForm from '../auth-create-org-form';
import renderLoginOptions from '../../helpers/auth/renderLoginOptions';
import ButtonTertiary from '../button/ButtonTertiary';
import { signupWithPassword } from '../../api/auth/AuthAPI';

function AuthSignUpForm() {
    const { t } = useTranslation();
    const query = useParamsQuery();
    const [isAuth, setIsAuth] = useState(false);
    const [loader, setLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [step, setStep] = useState(0);
    const [initialValues, setInitialValues] = useState({
        contactName: '',
        contactLastName: '',
        contactEmail: '',
        contactJobTitle: '',
        contactGoal: '',
        companyName: '',
        monitoringSystem: '',
        gitSystem: '',
        pmSystem: '',
        communicationSystem: '',
        eula: false,
        newsLetter: true,
    });

    useEffect(() => {
        const orgData = JSON.parse(localStorage.getItem('orgDataDraft'));
        orgData && setInitialValues(orgData);
    }, [step]);

    useEffect(() => {
        if (query.get('error')) {
            setIsAuth(true);
            setErrorMessage('User already exists, please login');
        }
        if (query.get('state') || query.get('id_token') || query.get('code')) {
            setIsAuth(true);
        }
    }, [query]);

    function handleBackClick() {
        setIsAuth(false);
        setStep(2);
    }

    function handleGoToAuth(data) {
        setInitialValues(data);
        localStorage.setItem('orgDataDraft', JSON.stringify(data));
        localStorage.setItem('newOrg', JSON.stringify(true));
        setIsAuth(true);
    }

    function handlePasswordSignup(values, setSubmittingFinished) {
        setLoader(true);
        const params = {
            ...values,
            endpoint: 'signup',
        };
        signupWithPassword(params)
            .then((response) => {
                localStorage.setItem('token', response.data.idToken);
                localStorage.setItem(
                    'refreshToken',
                    response.data.refreshToken
                );
                setSubmittingFinished(true);
                window.location.replace(response.data?.reset_password_link);
            })
            .catch((error) => {
                console.log(error);
                if (error?.response?.status == 400) {
                    setErrorMessage(
                        error?.response?.data
                            ? error?.response?.data
                            : 'Failed to signup'
                    );
                } else {
                    setErrorMessage('Oops! Something went wrong!');
                }

                setSubmittingFinished(true);
            });
        setLoader(false);
    }

    if (window.localStorage.getItem('token')) {
        return <Navigate to="/" />;
    }

    if (loader) {
        <div className="w-full h-full min-h-screen flex flex-col gap-8 justify-center items-center bg-gray-50">
            <div className="loader-wrapper pt-8 flex flex-col items-center justify-center gap-4">
                <p className="text-gray-400">Creating organisation...</p>
                <Loader color={'#C2C7D7'} size={35} speedMultiplier={0.8} />
            </div>
        </div>;
    }

    return (
        <div>
            <p className="text-center mb-20 font-display text-6xl text-gray-700 font-bold ">
                Sign up
            </p>
            {errorMessage && (
                <div className="rounded bg-red-200 text-red-600 px-2 py-2 mb-8">
                    {errorMessage}
                </div>
            )}
            {isAuth ? (
                <div className={``}>
                    <ButtonTertiary
                        onClick={() => handleBackClick()}
                        extraClasses={
                            'px-2 py-2 text-gray-500 text-lg flex gap-x-2 mb-4 hover:bg-gray-100 w-full rounded '
                        }
                    >
                        <ArrowRight
                            fill={'#7F8992'}
                            className="transform rotate-180 mt-1.5"
                        />
                        {t('login_page.button.back')}
                    </ButtonTertiary>
                    {renderLoginOptions(
                        'signup',
                        'all',
                        null,
                        null,
                        null,
                        setErrorMessage,
                        handlePasswordSignup
                    )}
                </div>
            ) : (
                <>
                    {query.get('t') === 'f' ? (
                        <CreateOrganisationUserForm
                            isInTrial={true}
                            onGoToAuth={handleGoToAuth}
                            stepToStart={step}
                            initialValues={initialValues}
                        />
                    ) : (
                        <AuthCreateOrgForm
                            onGoToAuth={handleGoToAuth}
                            initialValues={initialValues}
                        />
                    )}
                </>
            )}
        </div>
    );
}

export default AuthSignUpForm;
