import React, { useContext, useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { UploadIcon, CrossInCircle } from '../../../assets/svg.js';
import Loader from '../../loader';
import ErrorIndicator from '../../error-indicator/index.js';
import filterFile from '../../../helpers/fileValidation';
import { OrganisationsContext } from '../../../context/OrganisationsContext';
import {
    getCurrentOrganisation,
    setOrganisationLogo,
    updateCurrentOrganisation,
} from '../../../api/organisation/OrganisationAPI';
import WhitePanelContentWrapper from '../../white-panel-content-wrapper/index.js';
import UserUpdateOrganisationForm from '../../user-update-organisation-form/index.js';
import showNotification from '../../../helpers/showNotification.js';
import BillingPlanItem from '../../billing-plan-item/index.js';
import billingPlansData from '../../../helpers/billingPlansData.js';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../../helpers/textFormatters';
import ToggleCheckbox from '../../toggle/index.js';
import { useFetchDataAndSetState } from '../../../helpers/useFetchDataAndSetState.js';
import { getOrgUsers } from '../../../api/settings/UserAPI.js';
import { useLocation, useNavigate } from 'react-router';

const OrganisationTab = ({ permissionForWrite }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { organisationsState } = useContext(OrganisationsContext);
    const selectedOrganisationHash = organisationsState?.data
        ? organisationsState.data.find((org) => org.active)?.org_hash
        : null;
    const [currentOrganisationData, setCurrentOrganisationData] =
        useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const selectedOrganisationData = organisationsState.data
        ? organisationsState.data.find((org) => org.active)
        : null;

    const request = useCallback(() => getOrgUsers(), []);
    const [usersState] = useFetchDataAndSetState(request, [
        selectedOrganisationHash,
    ]);

    useEffect(() => {
        selectedOrganisationHash && void getCurrentOrganisationData();
    }, [selectedOrganisationHash]);

    useEffect(() => {
        if (location?.hash?.length && !organisationsState?.loading) {
            navigate(location.hash);
        }
    }, [location?.hash, organisationsState?.loading]);

    if (error) {
        return (
            <div className="settings-tab-content organisation-tab">
                <ErrorIndicator error={error} />
            </div>
        );
    }

    async function getCurrentOrganisationData() {
        setLoading(true);
        try {
            const res = await getCurrentOrganisation();
            setLoading(false);
            if (res.status === 200) {
                setCurrentOrganisationData(res.data);
            }
        } catch (error) {
            setLoading(false);
            setError(error);
        }
    }

    if (loading && !currentOrganisationData) {
        return (
            <div className="settings-tab-content organisation-tab flex justify-center">
                <Loader />
            </div>
        );
    }

    if (currentOrganisationData) {
        return (
            <OrganisationTabContent
                orgData={currentOrganisationData}
                permissionForWrite={permissionForWrite}
            />
        );
    }

    return (
        <div className="settings-tab-content organisation-tab">
            <p>No organisations</p>
        </div>
    );
};

const OrganisationTabContent = ({ orgData, permissionForWrite }) => {
    const { t } = useTranslation();
    const { organisationsState, organisationsDispatch } =
        useContext(OrganisationsContext);
    const [isUpdating, setIsUpdating] = useState(false);
    const [orgLogo, setOrgLogo] = useState({
        logoUrl: orgData.logo_url,
        newLogo: null,
        error: false,
        haveChange: false,
        loading: false,
    });
    const [billingPlan, setBillingPlan] = useState(orgData?.subscription_level);
    const [configuredPlan, setConfiguredPlan] = useState(null);
    const [isYearly, setIsYearly] = useState(true);
    const [adminsAmount, setAdminsAmount] = useState(1);

    const request = useCallback(() => getOrgUsers(), []);
    const [usersState] = useFetchDataAndSetState(request, [orgData?.org_hash]);
    useEffect(() => {
        if (!usersState.loading && usersState.data.length) {
            const admins = usersState.data.filter(
                (user) => user.role === 'admin'
            );
            setAdminsAmount(admins.length);
        }
    }, [usersState.data]);

    useEffect(() => {
        setBillingPlan(orgData.subscription_level);
    }, [orgData.subscription_level]);

    useEffect(() => {
        // TODO: update orgData
        if (billingPlan !== orgData.subscription_level) {
            const newOrgList = organisationsState.data.map((organisation) => {
                if (organisation.org_hash === orgData.org_hash) {
                    organisation.subscription_level = billingPlan;
                }
                return organisation;
            });
            organisationsDispatch({
                type: 'SET_DATA',
                payload: newOrgList,
            });
        }
    }, [billingPlan]);

    useEffect(() => {
        let canceled = false;
        if (!canceled) {
            setOrgLogo({
                logoUrl: orgData.logo_url,
                newLogo: null,
                error: false,
                haveChange: false,
                loading: false,
            });
        }
        return () => {
            canceled = true;
        };
    }, [orgData]);

    async function handleUpdateOrganisationData(values) {
        setIsUpdating(true);
        const updatedOrgData = {
            ...values,
            subscription_level: orgData.subscription_level,
        };

        try {
            await updateCurrentOrganisation(updatedOrgData);
            showNotification(`Successfully updated`, 'success');
        } catch (error) {
            showNotification(
                `${error.response?.data || error.message || error.data.message}`
            );
        }
        setIsUpdating(false);
    }

    const saveLogoChanges = () => {
        if (orgLogo.haveChange) {
            let data = new FormData();
            data.append('org_logo', orgLogo.newLogo);
            setOrgLogo((prevState) => ({
                ...prevState,
                loading: true,
            }));
            setOrganisationLogo(data)
                .then((response) => {
                    if (response.status === 200 || response.status === 204) {
                        const newOrgList = organisationsState.data.map(
                            (organisation) => {
                                if (
                                    organisation.org_hash === orgData.org_hash
                                ) {
                                    organisation.logo_url = response.data;
                                }
                                return organisation;
                            }
                        );
                        organisationsDispatch({
                            type: 'SET_DATA',
                            payload: newOrgList,
                        });
                        setOrgLogo((prevState) => ({
                            ...prevState,
                            haveChange: false,
                            loading: false,
                        }));
                        showNotification('Logo has been updated', 'success');
                    }
                })
                .catch((error) => {
                    setOrgLogo((prevState) => ({
                        ...prevState,
                        error: error,
                        loading: false,
                    }));
                    showNotification(
                        error.response?.data ||
                            error.data?.message ||
                            error.data ||
                            'Oops! Something went wrong'
                    );
                });
        }
    };

    const handleFileDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        void setLogoFile(event.dataTransfer.files[0]);
    };

    const handleFileDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const setLogoFile = async (file) => {
        const logo = await filterFile(file);

        if (typeof logo === 'string') {
            setOrgLogo({
                logoUrl: orgData.logo_url,
                newLogo: null,
                error: logo,
                haveChange: false,
                loading: false,
            });
        } else {
            setOrgLogo({
                logoUrl: orgData.logo_url,
                newLogo: logo,
                error: false,
                haveChange: true,
                loading: false,
            });
        }
    };

    const removeLogo = () => {
        if (orgLogo.newLogo) {
            if (orgData.logo_url === 'Undefined') {
                setOrgLogo({
                    logoUrl: 'Undefined',
                    newLogo: null,
                    error: false,
                    haveChange: false,
                    loading: false,
                });
            } else {
                setOrgLogo({
                    logoUrl: 'Undefined',
                    newLogo: null,
                    error: false,
                    haveChange: true,
                    loading: false,
                });
            }
        } else {
            setOrgLogo({
                logoUrl: 'Undefined',
                newLogo: null,
                error: false,
                haveChange: true,
                loading: false,
            });
        }
    };

    const renderLogo = () => {
        let logoBlock;

        if (orgLogo.newLogo) {
            logoBlock = (
                <div className="organisation-logo">
                    <div className="logo-wrapper border border-solid border-gray-300 rounded">
                        <img
                            src={window.URL.createObjectURL(orgLogo.newLogo)}
                            alt="Logo"
                        />
                        {permissionForWrite ? (
                            <button
                                className="remove-logo"
                                onClick={removeLogo}
                            >
                                <CrossInCircle fill="#EE8282" />
                            </button>
                        ) : null}
                    </div>
                </div>
            );
        } else if (orgLogo.logoUrl !== 'Undefined' && !orgLogo.error) {
            logoBlock = (
                <div className="organisation-logo">
                    <div className="logo-wrapper border border-solid border-gray-300 rounded">
                        <img src={orgLogo.logoUrl} alt="Organisation Logo" />
                        {permissionForWrite ? (
                            <button
                                className="remove-logo"
                                onClick={removeLogo}
                            >
                                <CrossInCircle fill="#EE8282" />
                            </button>
                        ) : null}
                    </div>
                </div>
            );
        } else {
            if (permissionForWrite) {
                logoBlock = (
                    <div
                        className="relative drag-and-drop border border-dashed border-gray-400 rounded"
                        onDrop={handleFileDrop}
                        onDragOver={handleFileDragOver}
                    >
                        <span className="dnd-placeholder text-gray-500 text-m font-light">
                            Drag and Drop logo here
                        </span>
                        <UploadIcon fill="#DADDE8" />
                        <span className="dnd-placeholder text-gray-500 text-m font-light">
                            Or
                        </span>
                        <label className="upload-input text-gray-300 text-m  border border-solid border-gray-300  hover:bg-gray-300 hover:text-gray-500">
                            <input
                                type="file"
                                onChange={(event) =>
                                    setLogoFile(event.target.files[0])
                                }
                            />
                            Select File
                        </label>
                        <button
                            className="absolute top-2 right-2"
                            onClick={handleBackToLogo}
                        >
                            <CrossInCircle
                                fill="#DADDE8"
                                width={18}
                                height={18}
                            />
                        </button>
                    </div>
                );
            } else {
                logoBlock = <div>No Logo</div>;
            }
        }

        return logoBlock;
    };

    const handleBackToLogo = () => {
        setOrgLogo({
            logoUrl: orgData.logo_url,
            newLogo: null,
            error: false,
            haveChange: false,
            loading: false,
        });
    };

    return (
        <div className="mx-auto  organisation-tab">
            <div className="mb-4 flex gap-4 w-full inline-block">
                <WhitePanelContentWrapper className="">
                    <div className="flex justify-between ">
                        <div>
                            <h3 className="font-display text-32 font-light mb-0	">
                                {capitalizeFirstLetter(
                                    t('settings_page.org_tab.title')
                                )}
                            </h3>
                            <p className="text-gray-500 mb-8">
                                {capitalizeFirstLetter(
                                    t('settings_page.org_tab.manage_org')
                                )}
                            </p>
                        </div>
                        <p className="text-gray-500 mb-8 text-sm">
                            {capitalizeFirstLetter(
                                t('settings_page.org_tab.created_at')
                            )}{' '}
                            {moment
                                .unix(orgData?.created_date)
                                .format('DD MMMM YYYY')}
                        </p>
                    </div>
                    <div className="relative">
                        <div className={`  ${isUpdating ? 'filter blur' : ''}`}>
                            <UserUpdateOrganisationForm
                                onSubmit={(values) =>
                                    handleUpdateOrganisationData(values)
                                }
                                organisationData={orgData}
                                permissionForWrite={permissionForWrite}
                            />
                        </div>
                        {isUpdating ? (
                            <div className="absolute top-0 left-0 w-full h-full">
                                <div className="flex w-full h-full justify-center items-center">
                                    <Loader />
                                </div>
                            </div>
                        ) : null}
                    </div>
                </WhitePanelContentWrapper>

                <WhitePanelContentWrapper className="flex flex-col justify-between py-2.5 ml-5">
                    <div className="organisation-logo-wrapper">
                        <p className="settings-sub-title">{`${capitalizeFirstLetter(
                            t('settings_page.org_tab.title')
                        )} ${t('settings_page.org_tab.logo')}`}</p>
                        <div className="organisation-logo">
                            {renderLogo()}
                            {orgLogo?.error ? (
                                <p className="font-light py-1 leading-5 text-sm false  text-red-text">
                                    {orgLogo?.error || 'Failed to update'}
                                </p>
                            ) : null}

                            {orgLogo.loading ? (
                                <div className="status-indicator-wraper">
                                    <div className="loading-wrapper">
                                        <Loader
                                            color={'#C2C7D7'}
                                            size={35}
                                            speedMultiplier={0.8}
                                        />
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="flex-1 flex justify-end items-end pb-4">
                        <button
                            className="mt-4 px-8 py-1 bg-theme-tertiary rounded text-white font-display leading-6 font-medium disabled:bg-gray-500 disabled:cursor-default"
                            onClick={saveLogoChanges}
                            disabled={!orgLogo.haveChange}
                        >
                            {capitalizeFirstLetter(t('button.save'))}
                        </button>
                    </div>
                </WhitePanelContentWrapper>
            </div>

            <WhitePanelContentWrapper
                className=" mt-4 inline-block pb-6 w-full lg:max-w-screen-xl"
                id="manage-plan"
            >
                {/* <h3 className="font-display text-32 font-light mb-0	">
                    {capitalizeFirstLetter(
                        t('settings_page.org_tab.manage_plan')
                    )}
                </h3>
                <p className="text-gray-500 mb-8">
                    {capitalizeFirstLetter(
                        t('settings_page.org_tab.choose_plan')
                    )}
                </p> */}

                <h3 className="font-display text-32 font-light mb-0	">
                    {' '}
                    Current Plan:{' '}
                    <span className="font-display text-32 font-extrabold mb-0	">
                        {billingPlansData.find(
                            (planData) => planData.value === billingPlan
                        ).name ?? 'Not Found'}
                    </span>{' '}
                </h3>
                <p className="text-gray-500 mb-2">
                    {billingPlan === 'trial'
                        ? `${capitalizeFirstLetter(
                              t('settings_page.org_tab.expires')
                          )} ${moment
                              .unix(orgData.created_date + 5184000)
                              .format('DD.MM.YY')}`
                        : `Next payment on ${moment
                              .unix(orgData.created_date + 5184000)
                              .format(
                                  'DD.MM.YY'
                              )} (plaseholder, to be fetched)`}
                </p>
                {/* <p className="text-gray-500 mb-8">Admins: {adminsAmount}</p> */}

                <ToggleCheckbox
                    handleChange={(status) => setIsYearly(status)}
                    labelOff="Pay monthly"
                    labelOn="Pay annually"
                    additionalText="Save up to 22%"
                />

                <div className="flex gap-x-5 mt-2 min-h-140 overflow-y-hidden">
                    {billingPlansData
                        .filter((plan) => plan.value !== 'trial')
                        .map((planData) => (
                            <BillingPlanItem
                                planData={planData}
                                selectedPlan={billingPlan}
                                setSelectedPlan={setBillingPlan}
                                key={planData.value}
                                permissionForWrite={permissionForWrite}
                                orgData={orgData}
                                isYearly={isYearly}
                                configuredPlan={configuredPlan}
                                setConfiguredPlan={setConfiguredPlan}
                                users={usersState.data}
                            />
                        ))}
                </div>
            </WhitePanelContentWrapper>
        </div>
    );
};

export default OrganisationTab;
