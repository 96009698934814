import React, { useContext } from 'react';
import classnames from 'classnames';
import { OrganisationsContext } from '../../../context/OrganisationsContext';

import './styles.scss';

const InputGroup = ({
    label,
    input,
    error = { status: false, text: '' },
    style = {},
}) => {
    const { organisationsState } = useContext(OrganisationsContext);

    const selectedOrganisationHash = organisationsState.data
        ? organisationsState.data.find((org) => org.active).org_hash
        : null;

    const localOnChange = (e) => {
        input.onChange(e);
    };

    const userPermission = selectedOrganisationHash
        ? organisationsState.data.find(
              (org) => org.org_hash === selectedOrganisationHash
          ).permissions
        : 'read';

    const getReadonlyValue = () => {
        switch (userPermission) {
            case 'superadmin':
            case 'admin':
            case 'write':
                return false;
            case 'read':
            default:
                return true;
        }
    };

    if (label) {
        return (
            <div
                className={classnames('input-wrapper', 'name-input', {
                    'has-error': error.status,
                })}
                style={style}
            >
                <label htmlFor={label.for} style={label.style ?? null}>
                    {label.text}
                </label>
                <input
                    type={input.type}
                    placeholder={input.placeholder}
                    id={input.id}
                    name={input.name}
                    value={input.value}
                    onChange={localOnChange}
                    required={input.required ?? false}
                    readOnly={
                        input.readOnly ? input.readOnly : getReadonlyValue()
                    }
                    disabled={input.disabled ?? false}
                    style={input.style ?? null}
                />

                <span className="input-error">{error.text}</span>
            </div>
        );
    }

    return (
        <div className="input-wrapper" style={style}>
            <input
                type={input.type}
                placeholder={input.placeholder}
                id={input.id}
                name={input.name}
                value={input.value}
                onChange={input.onChange}
                readOnly={input.readOnly ? input.readOnly : getReadonlyValue()}
                disabled={input.disabled ?? false}
                style={input.style ?? null}
            />
            <span className="validation-error"></span>
        </div>
    );
};

export default InputGroup;
